<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
       <!--  <h1>Application for CNG Retail Outlets
        </h1> -->
         <h1>Application form MGL CNG Retail Outlet
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class="active" aria-current="page"><a href="#">Application form MGL CNG Retail Outlet

                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <!-- <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    List of specified stretches for setting up CNG Retail Outlet
                                </div>
                                 <a href="assets/images/pdf/Rev. List of Open specified stretches_15.10.2024.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                               <!--  <a href="assets/images/pdf/Rev. List of Open specified stretches_27.08.2024.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a> -->
                            </div>
                        </div>
                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="applyoutletForm">
                                <div class="row">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="ContactNumber"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" onKeyPress="if(this.value.length==10) return false;" />
                                            <div *ngIf="submitted && applyoutletForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors?.pattern">
                                                    invalid Contact Number </div>
                                            </div>
                                            <label for="ContactNumber">Contact Number<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Email'].errors.required">Required
                                                    Field
                                                </div>
                                                 <div *ngIf="applyoutletForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                            <!-- <label for="Email">Email<span style="color: crimson;">*</span></label> -->
                                            <label for="Email">Email</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Correspondence_Address"
                                                placeholder="Correspondence_Address"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Correspondence_Address'].errors }"
                                                id="Correspondence_Address" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Correspondence_Address'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Correspondence_Address'].errors.required">Required
                                                    Field
                                                </div>
                                                 
                                            </div>
                                            <label for="Correspondence_Address">Correspondence Address<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofPlotOwner"
                                                placeholder="NameofPlotOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofPlotOwner'].errors }"
                                                id="NameofPlotOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofPlotOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['NameofPlotOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofPlotOwner">Name of the Plot Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control"
                                                formControlName="RelationshipofAppOwner"
                                                placeholder="RelationshipofAppOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors }"
                                                id="RelationshipofAppOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['RelationshipofAppOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="RelationshipofAppOwner">Relationship of Applicant Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Addressofplot"
                                                placeholder="Addressofplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Addressofplot'].errors }"
                                                id="Addressofplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Addressofplot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Addressofplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                           <!--  <label for="Addressofplot">Address of plot<span
                                                    style="color: crimson;">*</span></label> -->
                                                     <label for="Addressofplot">Address of plot</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="SurveyNo"
                                                placeholder="SurveyNo"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['SurveyNo'].errors }"
                                                id="SurveyNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['SurveyNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['SurveyNo'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="SurveyNo">Survey No/ CTS No.<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="District"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['District'].errors}">
                                                <option value="" selected>Select District </option>
                                                <option *ngFor="let DistrictItem of district "
                                                    value={{DistrictItem.District}}>{{DistrictItem.District}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['District'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['District'].errors">Required Field</div>
                                            </div>
                                            <label for="District">District<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="PinCode"
                                                placeholder="PinCode"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['PinCode'].errors }"
                                                id="PinCode" />
                                            <div *ngIf="submitted && applyoutletForm.controls['PinCode'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors?.pattern">invalid
                                                    pincode
                                                </div>
                                            </div>
                                            <label for="PinCode">PinCode<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="AreaofthePlot"
                                                placeholder="AreaofthePlot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['AreaofthePlot'].errors }"
                                                id="AreaofthePlot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['AreaofthePlot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['AreaofthePlot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="AreaofthePlot">Area of plot for CNG (in Sq. M.)<span
                                                    style="color: crimson;">*</span></label>

                                        </div>
                                         <label class="font-size11 mt-1">If the plot is on NH/SH Min. 35m Frontage and 1225 sq.m. area required</label>
                                    </div>
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Frontageoftheplot"
                                                placeholder="Frontageoftheplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Frontageoftheplot'].errors }"
                                                id="Frontageoftheplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Frontageoftheplot'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['Frontageoftheplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Frontageoftheplot">Frontage of the plot<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                  <!--   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Depth"
                                                placeholder="Depth"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Depth'].errors }"
                                                id="Depth" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Depth'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Depth'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Depth">Depth<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Latitude"
                                                placeholder="Latitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Latitude'].errors }"
                                                id="Latitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Latitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Latitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Latitude">Latitude</label>
                                            <!-- <label for="Latitude">Latitude<span style="color: crimson;">*</span></label> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Longitude"
                                                placeholder="Longitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Longitude'].errors }"
                                                id="Longitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Longitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Longitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Longitude">Longitude</label>
                                            <!-- <label for="Longitude">Longitude<span
                                                    style="color: crimson;">*</span></label> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Ownership_of_Land"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Ownership_of_Land'].errors}">
                                                <option value="" selected>Select Ownership of Land </option>
                                                <option value="Owned">Owned</option>
                                                <option value="Leased">Leased</option>
                                                <option value="Will be Leased/Purchased">Will be Leased/Purchased</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Ownership_of_Land'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Ownership_of_Land'].errors">Required Field</div>
                                            </div>
                                            <label for="Ownership_of_Land">Ownership of Land<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofRoad"
                                                placeholder="NameofRoad"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofRoad'].errors }"
                                                id="NameofRoad" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofRoad'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['NameofRoad'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofRoad">Name of Road<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Plot"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Plot'].errors}">
                                                <option value="" selected>Select Plot</option>
                                                <option *ngFor="let PlotItem of plot " value={{PlotItem.Plot}}>
                                                    {{PlotItem.Plot}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Plot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Plot'].errors"> Required Field
                                                </div>
                                            </div>
                                            <label for="Plot">Plot<span style="color: crimson;">*</span></label>
                                        </div>

                                    </div> -->
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Additionalremarks"
                                                placeholder="Additionalremarks" id="Additionalremarks" />
                                            <label for="Plot">Additional remarks</label>
                                        </div>

                                    </div>
                                    <p><b><i> You can upload only .doc,.docx,.pdf file format and file size within 5
                                                MB</i></b></p>
                                    <div class="col-sm-6 form-group">
                                         <label class="font-size111">Property card or Latest
                                            7/12 extract<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event)"
                                                    class="from-control" type="file"
                                                    formControlName="Doc1_Propertycard"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Doc1_Propertycard'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['Doc1_Propertycard'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Doc1_Propertycard'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="Doc1_Propertycard">Property card or Latest
                                            7/12 extract<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 


                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                                <input accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip1($event)"

                                                    class="from-control" type="file"
                                                    formControlName="Doc2_subleases"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Doc2_subleases'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['Doc2_subleases'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Doc2_subleases'].errors"> Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11  mt-1" for="Doc2_subleases">Details of all leases,
                                            sub-leases, tenancy agreements, assignment, or any other mode of
                                            transfer<span style="color: crimson;">*</span></label>
                                    </div> -->


                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip2($event)"

                                                    class="from-control" type="file"
                                                    formControlName="Doc3_Photographsofland"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Doc3_Photographsofland'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['Doc3_Photographsofland'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Doc3_Photographsofland'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11  mt-1" for="Doc3_Photographsofland">Clear Photographs
                                            of land from Front (from road), Rear of plot (facing the road) and other
                                            angles (4-5 photographs)<span style="color: crimson;">*</span></label>
                                    </div>
 -->



                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip3($event)"
                                                    class="from-control" type="file"
                                                    formControlName="Doc4_AgeProof"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Doc4_AgeProof'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['Doc4_AgeProof'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Doc4_AgeProof'].errors"> Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11  mt-1" for="Doc4_AgeProof">Age Proof (Any educational
                                            certificate/ Birth certificate/ PAN Card)<span
                                                style="color: crimson;">*</span></label>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                                <input accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip4($event)"
                                                      class="from-control" type="file"
                                                    formControlName="Doc5_IDproof"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Doc5_IDproof'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['Doc5_IDproof'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Doc5_IDproof'].errors"> Required Field</div>
                                            </div>
                                        </div>
                                        <label class="font-size11" for="Doc5_IDproof">ID proof (Any Govt. approved ID
                                            Proof)<span style="color: crimson;">*</span></label>
                                    </div> -->


                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div (copy)="false" class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && applyoutletForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="applyoutletForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                    <div *ngIf="alertType == 'submit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>