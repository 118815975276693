import { Component } from '@angular/core';
import { MenuService } from 'src/app/Service/menu.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-regulation-of-sebi',
  templateUrl: './regulation-of-sebi.component.html',
  styleUrls: ['./regulation-of-sebi.component.css']
})
export class RegulationOfSebiComponent {
 allData:any[]=[]
  baseUrl=environment.baseUrl
  constructor(private Service:MenuService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.allData = [
{name:"Memorandum of Association and Articles of Association",url:"https://www.mahanagargas.com:3000/MOA%20and%20AoA_MGL.PDF"},
{name:"Brief profile of board of directors",url:"https://www.mahanagargas.com/MGL-corporate/leadership"},
{name:"Details of Business",url:"https://www.mahanagargas.com/MGL-corporate/about-MGL"},
{name:"Terms & conditions of appointment of Independent Directors",url:"https://www.mahanagargas.com:3000/Terms%20and%20Conditions%20for%20Appointment%20of%20Independent%20Directors.pdf"},
{name:"Composition of various committees of Board of Directors",url:"https://www.mahanagargas.com/MGL-corporate/investors/corporate-governance"},
{name:"Code of conduct of Board of Directors and Senior Management Personnel",url:"https://www.mahanagargas.com:3000/_MGL-Code_of_Conduct_27fc7b77f1.pdf"},
{name:"Details of establishment of Vigil Mechanism/ Whistle Blower policy",url:"https://www.mahanagargas.com:3000/_whistle-blower-and-vigil-mechanism_80f19ef31b.pdf"},
{name:"Criteria of making payments to Non-Executive Directors",url:"https://www.mahanagargas.com:3000/_MGL-Nomination_and_Remuneration_Policy_329e2e5b7e.pdf"},
{name:"Policy on dealing with Related Party Transactions",url:"https://www.mahanagargas.com:3000/_MGL_Policy_on_Related_Party_Transactions_7e65246e63.pdf"},
{name:"Policy for determining ‘Material’ subsidiaries",url:"https://www.mahanagargas.com/MGL-corporate/investors/policies"},
{name:"Details of Familiarization Programmes imparted to Independent Directors",url:"https://www.mahanagargas.com:3000/Familiarization%20Programme.pdf"},
{name:"Email address for grievance redressal and other relevant details",url:"https://www.mahanagargas.com/MGL-corporate/investors/investor-guide"},
{name:"Contact  information  of  the  designated  officials  of  the  listed  entity  who  are responsible for assisting and handling investor grievances",url:"https://www.mahanagargas.com/MGL-corporate/investors/investor-guide"},
//{name:"",url:""},
{name:"Notice of Board Meeting, where Financial results are discussed",url:"https://www.mahanagargas.com/MGL-corporate/investors/other-filings-with-stock-exchanges"},
{name:"Financial results",url:"https://www.mahanagargas.com/MGL-corporate/investors/financial-results/quarterly-result"},
//{name:"",url:""},
{name:"Financial results",url:"https://www.mahanagargas.com/MGL-corporate/investors/financial-results/audited-result"},
{name:"Annual  Report  and related documents",url:"https://www.mahanagargas.com/MGL-corporate/investors/annual-report"},
//{name:"",url:""},
{name:"Shareholding Pattern",url:"https://www.mahanagargas.com/MGL-corporate/investors/shareholding-pattern"},
{name:"details of agreements entered into with the media companies",url:""},
{name:"Schedule of analysts or institutional investors",url:"https://www.mahanagargas.com/MGL-corporate/investors/investor-update-and-presentation"},
{name:"Presentations made by the listed entity to analysts or institutional investors.",url:"https://www.mahanagargas.com/MGL-corporate/investors/investor-update-and-presentation"},
{name:"Audio recordings of post earnings/quarterly calls",url:"https://www.mahanagargas.com/MGL-corporate/investors/investor-update-and-presentation"},
{name:"Video recordings of post earnings/quarterly calls",url:"Not Appliacble"},
{name:"Transcripts of post earnings/quarterly calls",url:"https://www.mahanagargas.com/MGL-corporate/investors/investor-update-and-presentation"},
{name:"Advertisements as per regulation 47 (1)",url:"https://www.mahanagargas.com/MGL-corporate/investors/other-filings-with-stock-exchanges"},
{name:"New Name & Old Name of Listed Entity, in case of name change",url:""},
{name:"Credit Ratings or revision in credit rating obtained for outstanding instruments",url:""},
{name:"Audited  Financial  Statements of subsidiary (Unison Enviro Private Limited)",url:"https://www.mahanagargas.com/MGL-corporate/investors/financial-results/subsidiary-financials/unison-enviro-private-limited"},
//{name:"",url:""},
{name:"Audited  Financial  Statements of subsidiary (Mahanagar LNG Private Limited)",url:"https://www.mahanagargas.com/MGL-corporate/investors/financial-results/subsidiary-financials/mahanagar-lng-private-limited"},
//{name:"",url:""},
{name:"Secretarial Compliance Report",url:"https://www.mahanagargas.com/MGL-corporate/investors/other-filings-with-stock-exchanges"},
{name:"Policy for determination of Materiality of Events or Information under Regulation 30(4)",url:"https://www.mahanagargas.com:3000/_MGL-Policy_on_determination_of_materiality_29f93c6150.pdf"},
//{name:"",url:""},
{name:"Contact details of Key Managerial Personnel who are authorized for the purpose of determining materiality of an event or information",url:"https://www.mahanagargas.com:3000/_MGL-Policy_on_determination_of_materiality_29f93c6150.pdf"},
{name:"Disclosure under Regulation 30(8)",url:"https://www.mahanagargas.com/MGL-corporate/investors/other-filings-with-stock-exchanges"},
{name:"Statements  of  Deviation or Variation",url:""},
{name:"Dividend Distribution Policy",url:"https://www.mahanagargas.com:3000/_DividendDistributionPolicy_f4049de0be.pdf"},
{name:"Annual Return",url:"https://www.mahanagargas.com/MGL-corporate/investors/annual-general-meeting"},
]

 
    //this.getPdf()
  }

  // getPdf(){
  //   this.Service.getPdfByCategory({PdfCategory:'Quarterly Result'}).subscribe(response=>{
  //     let data = JSON.parse(JSON.stringify(response)).details
  //     this.allData=data
  //   })
  // }
}

