import { Component, OnInit } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-cng-service-section',
  templateUrl: './cng-service-section.component.html',
  styleUrls: ['./cng-service-section.component.css']
})
export class CngServiceSectionComponent {
  data:any={
    CaNo:''
  }

  stock:any={
    CurrentPrice:'',
    Changes:'',
    BseCurrentPrice:'',
    BseChange:''
  }
  NseColors:any
  BseColors:any
  

  constructor(private Service : UserRegistrationService){}

  ngOnInit(): void {
    //this.getStockData()
    //console.log(this.stock)

    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    // if (data) {
    //   this.data.CaNo = JSON.parse(data).CaNo
    //   //console.log(this.data.CaNo)
      
    // }

    let newData = sessionStorage.getItem('UserCredential_New');

if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    this.data=JSON.parse(decryptedString).CaNo
   

  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    
  }
  
  getStockData(){
    this.Service.getStockData().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      this.stock=data
     // console.log(data)
      if(data.Changes>0){
        
        this.NseColors = 'green'

      }else{
        this.NseColors = 'red'
      }

      if((data.BseChange).replace(/[(),%]/g, "")>0){
        
        this.BseColors = 'green'

      }else{
        this.BseColors = 'red'
      }

      //console.log((data.BseChange).replace(/[(),%]/g, "")>0)
      
    })
  }

}

