import { Component } from '@angular/core';
import { MenuService } from 'src/app/Service/menu.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';
import { PdfdownloadService } from 'src/app/Service/pdfdownload.service';
//import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-annual-report',
  templateUrl: './annual-report.component.html',
  styleUrls: ['./annual-report.component.css']
})
export class AnnualReportComponent {
  allData:any[]=[]
  baseUrl=environment.baseUrl;
  loading = false;
  constructor(private Service:MenuService, private pageLoaderService: PageLoaderService,
    private pdfService:PdfdownloadService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getPdf()
  }

  getPdf(){
    this.Service.getPdfByCategory({PdfCategory:'Annual Report'}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.allData=data

      for (let i = 0; i < this.allData.length; i++) {
           this.allData[i].isLoading = false
        }
    })
  }

  downloadPdf(item:any) {
     // this.loading = true;
   // console.log(item)
     item.isLoading = true;
    const pdfUrl = this.baseUrl+"/"+item.PdfFile; // URL of the PDF file
    const pdfName = item.PdfFile; // Name of the downloaded file

    this.pdfService.downloadPdf(pdfUrl).subscribe((response) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfName;
      a.click();
      window.URL.revokeObjectURL(url); // Free up memory
       // this.loading = false;
       item.isLoading = false; // Reset loading state
    });
  }

//   downloadPdf(item:any) {
//       console.log(item)
//     const pdfUrl = this.baseUrl+"/"+item.PdfFile; // URL of the PDF file
//     const pdfName = item.PdfFile; // Name of the downloaded file
//   this.pdfService.downloadPdf(pdfUrl).subscribe((event) => {
//     if (event.type === HttpEventType.DownloadProgress) {
//       const progress = Math.round((event.loaded / (event.total || 1)) * 100);
//       console.log(`Download Progress: ${progress}%`);
//     } else if (event.type === HttpEventType.Response) {
//       const blob = new Blob([event.body!], { type: 'application/pdf' });
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement('a');
//       a.href = url;
//       a.download = pdfName;
//       a.click();
//       window.URL.revokeObjectURL(url); // Free up memory
//     }
//   });
// }
}
