import { Component, OnInit } from '@angular/core';
import { FaqServiceService } from '../Service/FAQ/faq-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 year:any;
  constructor(public FaqService:FaqServiceService) { }

  ngOnInit(): void {
    this.year = (new Date()).getFullYear()
    // console.log(this.year)
  }

}
