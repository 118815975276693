  import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BoardofdirectorsService {
  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

   getAll_board_directors(){
    return this.http.get(this.baseUrl+'boardofdirectors/getAll_board_directors')
  }

   getAll_smsmembers_directors(){
    return this.http.get(this.baseUrl+'smsmembersandcosecretary/getAll_smsmembers_directors')
  }
}
