import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { PayuService } from 'src/app/Service/payu.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
declare var $: any;
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-payuwalletbalance',
  templateUrl: './payuwalletbalance.component.html',
  styleUrls: ['./payuwalletbalance.component.css']
})
export class PayuwalletbalanceComponent {
   data:any;
   walletData:any;
   walletBalanceData:any;
   availableBalance:any;
   firstName:any;
   lastName:any;
   isActive:boolean = false;
   isActive1:boolean = false;
   isActive2:boolean = false;
   isActive3:boolean = false;
   isActive4:boolean = false;
   isActive5:boolean = false;

  display: string = 'none'
  Popup: any = '';
  addmoneyForm!: FormGroup;
  submitted = false;
   deductmoneyForm!: FormGroup;
   deductsubmitted = false;
   bloackcardForm!: FormGroup;
  blocksubmitted = false;
  backendData:any;
  walletbackendData:any;
  walletPaymentOrderNo:any;
  statusDescription:any;
  constructor(private pageLoaderService: PageLoaderService, 
    public payuService: PayuService,public UserService: UserRegistrationService,public router: Router,
    private FormBuilder: FormBuilder) { 
  }

    ngOnInit(): void {
        this.addmoneyForm = this.FormBuilder.group({
      amount: ['', Validators.required]
    })
         this.deductmoneyForm = this.FormBuilder.group({
      deduct_amount: ['', Validators.required]
    })

      this.bloackcardForm = this.FormBuilder.group({
      blockType: ['', Validators.required]
    })

       // let data = sessionStorage.getItem('UserCredential')
       let data = ''
           let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
     //console.log(data)
     // this.addBalance();
    if(data){
       this.data=JSON.parse(data)
      // this.addBalance();
         this.findpayUcreatewalletdata();
     
    }
     //this.UserService.checkLogin()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);



  

   }


     openPopup() {
    this.Popup = 'show'
    this.display = 'block'
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
    // this.addmoneyForm.get('amount')?.setValue('')
   
  }



   gotoTransaction(){
     this.router.navigate(['/view-payu-transaction']);
   }


//***********Block Card Start*******//

   checkActive(val:any){
    if(val=="View Transaction"){
      this.isActive = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      

    }else if(val=="Add Balance"){
      this.isActive = false;
      this.isActive1 = true;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      if(this.statusDescription=="Active"){
        this.openPopup();
      }else{
        alert("Your Card is Blocked")
      }
      
      // this.addBalance();
  
    }else if(val=="Card Limit"){
      this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
     // this.addBalance()
      
    }else if(val=="Block Card"){
       this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = true;
      this.isActive4 = false;
      this.isActive5 = false;
       this.openBlockModel();
       
      
      
    }else if(val=="Pay Wallet"){
       this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = true;
      this.isActive5 = false;
     
        if(this.statusDescription=="Active"){
          this.openDeductModel();
      }else{
        alert("Your Card is Blocked")
      }
      // if(this.availableBalance=="0"){
      //   alert("UNLOAD AMOUNT SHOULD BE LESS THAN AVAILABLE BALANCE")
      // }else{
      //   this.openDeductModel();
      // }
      
    }else if(val=="UnBlock Card"){
        this.isActive = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = true;
   
     this.openUnBlockModel()
    }
    

   }

     openBlockModel(){
     $("#exampleModal-Block").modal("show");
  }

    closeBlockModel(){
    $("#exampleModal-Block").modal("hide");
    this.bloackcardForm.get('blockType')?.setValue('');
  }

    openUnBlockModel(){
     $("#exampleModal-UnBlock").modal("show");
  }

    closeUnBlockModel(){
    $("#exampleModal-UnBlock").modal("hide");
   
  }


   openDeductModel(){
     $("#exampleModal-Deduct").modal("show");
  }

    closeDeductModel(){
    $("#exampleModal-Deduct").modal("hide");
   
  }

  UnblockCard(){

     var cardLastnumber = this.walletData[0].cardNumber;

    var cardLastnumbersave = cardLastnumber.slice(-4);

       var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
    // console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
 //console.log(text3)
 //console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;
   
   let obj={"clientTxnId":this.walletData[0].clientTxnId,"requestDateTime":savetime,
    "urn":this.walletData[0].urn,"last4Digits":cardLastnumbersave,
    "customerId":this.walletData[0].customerId}
     this.payuService.wallet_card_Unblock_encrypt(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.wallet_Uncard_block(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })  

  }


  wallet_Uncard_block(data:any) {
    let obj={"token":data}
     this.payuService.wallet_Uncard_block(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      this.wallet_card_Unblock__decrypt(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


  wallet_card_Unblock__decrypt(data:any) {
    let obj={"token":data}
     this.payuService.wallet_card_Unblock__decrypt(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
       //console.log(res.details);
       if(res.details.responseMessage=="SUCCESS"){
        this.findpayUcreatewalletdata()
        this.closeUnBlockModel();
        alert("Your Card is UnBlock")
       }else{
       alert(res.details.responseMessage)
       }
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


   blockCard() {
    this.blocksubmitted = true
   if (this.bloackcardForm.invalid) {
      return
    }
    //console.log(this.bloackcardForm.value) 

     var cardLastnumber = this.walletData[0].cardNumber;

    var cardLastnumbersave = cardLastnumber.slice(-4);

       var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
     //console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
 //console.log(text3)
 //console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;
   
   let obj={"clientTxnId":this.walletData[0].clientTxnId,"requestDateTime":savetime,
    "urn":this.walletData[0].urn,"last4Digits":cardLastnumbersave,
    "customerId":this.walletData[0].customerId,"blockType":this.bloackcardForm.get('blockType')?.value}
     this.payuService.wallet_card_block_encrypt(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.wallet_card_block(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })  
  }


  wallet_card_block(data:any) {
    let obj={"token":data}
     this.payuService.wallet_card_block(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      this.wallet_card_block__decrypt(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


  wallet_card_block__decrypt(data:any) {
    let obj={"token":data}
     this.payuService.wallet_card_block__decrypt(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      // console.log(res.details);
       if(res.details.responseMessage=="SUCCESS"){
        this.findpayUcreatewalletdata()
        this.closeBlockModel();
        alert("Your Card is Block")
       }else{
       alert(res.details.responseMessage)
       }
      } else {
        
      }
     }, err => {
     // console.log(err)
    })

  }


  //***********Block Card End*******//

    addMoney() {
    this.submitted = true
   if (this.addmoneyForm.invalid) {
      return
    }

    // this.gotoPayusdk();

    this.initpaymentorder_wallet();
   
  }


  initpaymentorder_wallet() {
   let obj={"customerId":this.walletData[0].customerId,"mobile":this.walletData[0].mobile,
   "urn":this.walletData[0].urn}
     this.payuService.initpaymentorder_wallet(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         this.walletPaymentOrderNo = res.details.LstPayuInitTrans_Response[0].OrderNo;
        // console.log(this.walletPaymentOrderNo)
         sessionStorage.setItem('walletPaymentOrderNo', this.walletPaymentOrderNo)
         //console.log(sessionStorage.getItem('walletPaymentOrderNo'))
        // this.backendData = res.details;
         this.closeModel();
         this.gotoPayusdk();
        // setTimeout(() => {
        //  }, 5000);

      } else {
          alert("Error ocured")
      }

    }, err => {
      //console.log(err)
    })

  }



  gotoPayusdk() {
   let obj={"payfirstname":this.data.FirstName,"payemail":this.data.Email,
   "payphone":this.data.Mobile,"payamount":this.addmoneyForm.get('amount')?.value}
     this.payuService.getPayUdata(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
     
         this.backendData = res.details;
         // this.closeModel();
         this.payUpsyment();
        // setTimeout(() => {
        //  }, 5000);

      } else {
          alert("Error ocured")
      }

    }, err => {
    //  console.log(err)
    })

  }


  payUpsyment() {
    //console.log('pay');



      let paymentString = `
      <html>
        <body>
          <form action="${ this.backendData.payu_url}" method="post" id="payu_form">
          <input type="hidden" name="txnid" value="${ this.backendData.txnid}"/>
          <input type="hidden" name="amount" value="${ this.backendData.amount}"/>
            <input type="hidden" name="firstname" value="${ this.backendData.firstname}"/>
            <input type="hidden" name="productinfo" value="${ this.backendData.productinfo}"/>
            <input type="hidden" name="email" value="${ this.backendData.email}"/>
            <input type="hidden" name="phone" value="${ this.backendData.phone}"/>
            <input type="hidden" name="surl" value="${ this.backendData.surl}"/>
            <input type="hidden" name="furl" value="${ this.backendData.furl}"/>
          
            <input hidden type='text' id='udf1' name='udf1' value='${ this.backendData.udf1}'/>
            <input type="hidden" name="hash" value="${ this.backendData.hash}"/>
            <input type="hidden" name="key" value="${ this.backendData.key}"/>
            <button type="submit" value="submit" #submitBtn></button>
          </form>
          <script type="text/javascript">document.getElementById("payu_form").submit();</script>
        </body>
      </html>`;
  
  const winUrl = URL.createObjectURL(
      new Blob([paymentString], { type: "text/html" })
  );
  
  window.location.href = winUrl;

    // <input hidden type='text' id='pg' name='pg' value='${ this.backendData.pg}'/>
            // <input hidden type='text' id='bankcode' name='bankcode' value='${ this.backendData.bankcode}'/>
      
 }

 //***********goto deduct start*******//

 deductFromwallet() {
    this.deductsubmitted = true
   if (this.deductmoneyForm.invalid) {
      return
    }

     if(this.availableBalance < this.deductmoneyForm.get('deduct_amount')?.value){
        return alert("UNLOAD AMOUNT SHOULD BE LESS THAN AVAILABLE BALANCE")
      }
   let obj={"payfirstname":this.data.FirstName,"payemail":this.data.Email,
   "payphone":this.data.Mobile,"payamount":this.deductmoneyForm.get('deduct_amount')?.value}
     this.payuService.getPayU_From_wallet_data(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
     
         this.walletbackendData = res.details;
         // this.closeModel();
         this.payUpayFromwallet();
        // setTimeout(() => {
        //  }, 5000);

      } else {
          alert("Error ocured")
      }

    }, err => {
      //console.log(err)
    })

  }

    payUpayFromwallet() {
    //console.log('pay');



      let paymentString = `
      <html>
        <body>
          <form action="${ this.walletbackendData.payu_url}" method="post" id="payu_form">
          <input type="hidden" name="txnid" value="${ this.walletbackendData.txnid}"/>
          <input type="hidden" name="amount" value="${ this.walletbackendData.amount}"/>
            <input type="hidden" name="firstname" value="${ this.walletbackendData.firstname}"/>
            <input type="hidden" name="productinfo" value="${ this.walletbackendData.productinfo}"/>
            <input type="hidden" name="email" value="${ this.walletbackendData.email}"/>
            <input type="hidden" name="phone" value="${ this.walletbackendData.phone}"/>
            <input type="hidden" name="surl" value="${ this.walletbackendData.surl}"/>
            <input type="hidden" name="furl" value="${ this.walletbackendData.furl}"/>
           
            <input hidden type='text' id='udf1' name='udf1' value='${ this.walletbackendData.udf1}'/>
            <input type="hidden" name="hash" value="${ this.walletbackendData.hash}"/>
            <input type="hidden" name="key" value="${ this.walletbackendData.key}"/>
            <button type="submit" value="submit" #submitBtn></button>
          </form>
          <script type="text/javascript">document.getElementById("payu_form").submit();</script>
        </body>
      </html>`;
  
  const winUrl = URL.createObjectURL(
      new Blob([paymentString], { type: "text/html" })
  );
  
  window.location.href = winUrl;

   // <input hidden type='text' id='pg' name='pg' value='${ this.walletbackendData.pg}'/>
   //          <input hidden type='text' id='bankcode' name='bankcode' value='${ this.walletbackendData.bankcode}'/>
      
 }

  //***********goto deduct end*******//

  //  findpayUcreatewalletdata() {
  //   let obj={"CANo":this.data.CaNo,"MobileNo":this.data.Mobile,
  //   "Email":this.data.Email}
  //    this.payuService.findpayUcreatewalletdata(obj).subscribe(response => {
  //     console.log(response);
  //     let res = JSON.parse(JSON.stringify(response))
  //     if (res.response == true) {
  //       this.walletData = res.details;
  //       console.log(this.walletData[0].urn)
  //       this.get_wallet_balance_encrypt()
      
  //     } else {
        
  //     }
  //    }, err => {
  //     console.log(err)
  //   })

  // }


   findpayUcreatewalletdata() {
    let obj={"MobileNo":this.data.Mobile}
     this.payuService.checkwallet(obj).subscribe(response => {
   //   console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.walletData = res.details.LstWallet_Response;
      //  console.log(this.walletData[0].urn)
        this.get_wallet_balance_encrypt()
      
      } else {
        
      }
     }, err => {
     // console.log(err)
    })

  }


   get_wallet_balance_encrypt() {

      var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
  //   console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
 //console.log(text3)
 //console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;

 

    let obj={"clientTxnId":this.walletData[0].clientTxnId,"requestDateTime":savetime,
    "urn":this.walletData[0].urn}
     this.payuService.get_wallet_balance_encrypt(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.get_retrieveCustRecord(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


   get_retrieveCustRecord(data:any) {
    let obj={"token":data}
     this.payuService.get_retrieveCustRecord(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      this.get_wallet_balance_decrypt(res.details.token)
      
      } else {
        
      }
     }, err => {
     // console.log(err)
    })

  }


   get_wallet_balance_decrypt(data:any) {
    let obj={"token":data}
     this.payuService.get_wallet_balance_decrypt(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
       //console.log(res.details);
        this.walletBalanceData = res.details;
        if(this.walletBalanceData.responseCode=="00"){
          var availableBalance1 = this.walletBalanceData.cardList[0].availableBalance
          var finalbalance = availableBalance1/100;

           this.availableBalance = finalbalance;
            this.firstName = this.walletBalanceData.cardList[0].firstName;
            this.lastName = this.walletBalanceData.cardList[0].lastName;
            this.statusDescription = this.walletBalanceData.cardList[0].statusDescription;
           //console.log(this.statusDescription)

        }
      
      } else {
        
      }
     }, err => {
     // console.log(err)
    })

  }




}
