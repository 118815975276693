import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TenderResp } from 'src/app/models/tender';
import { appliconttenderResp } from 'src/app/models/tender';


@Injectable({
  providedIn: 'root'
})
export class TenderService {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  
  getNewTenders(): Observable<TenderResp[]> {
    return this.http.get<TenderResp[]>(this.baseUrl + 'getNewTenders');
  }
  
  getOldTenders(): Observable<TenderResp[]> {
    return this.http.get<TenderResp[]>(this.baseUrl + 'getOldTenders');
  }
  
  insert(data:any) {
  return this.http.post<appliconttenderResp>(`${this.baseUrl}insert_applicontracttender`,data)
} 

get_tender_list_using_category(data:any) {
  return this.http.post<any>(`${this.baseUrl}get_tender_list_using_category`,data)
} 

get_tender_cat_list() {
  return this.http.post<any>(`${this.baseUrl}get_tender_cat_list`,{})
} 
  
}