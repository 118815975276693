<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Complaint log</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential Png</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
                <li class="active" aria-current="page">Log a complaint</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <div class="customer-login-page log-meter-reading">
                                <form [formGroup]="complaintForm">
                                    <div class="row">


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <select
                                                    [ngClass]="{'is-invalid':submitted && complaintForm.controls['Type_of_complaint'].errors}"
                                                    (change)="complaintChange($event)" required class="form-select">
                                                    <option value="">Select Complaint type</option>
                                                    <option *ngFor="let CategoryItem  of complaint"
                                                        [value]="CategoryItem">{{CategoryItem}}</option>
                                                </select>
                                                <div *ngIf="submitted && complaintForm.controls['Type_of_complaint'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="complaintForm.controls['Type_of_complaint'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Type_of_complaint">Type of complaint<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <select
                                                    [ngClass]="{'is-invalid':submitted && complaintForm.controls['category'].errors}"
                                                    class="form-select" (change)="categoryChange($event)" required>
                                                    <option value="">Select Category</option>
                                                    <option *ngFor="let CategoryItem  of compdata"
                                                        value={{CategoryItem.category}}>{{CategoryItem.category}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && complaintForm.controls['category'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="complaintForm.controls['category'].errors">Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label for="category">Category<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control"
                                                    formControlName="complaint_message" placeholder="complaint_message"
                                                    [ngClass]="{ 'is-invalid': submitted && complaintForm.controls['complaint_message'].errors }"
                                                    id="complaint_message" />
                                                <div *ngIf="submitted && complaintForm.controls['complaint_message'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="complaintForm.controls['complaint_message'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="complaint_message">Message<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                                    <div class="col-sm-6 form-group">
                                                        <div class="form-floating">
                                                            <div class="cu-login-input2">
                                                                <label class="font-size14" for="Attacment">Attachment</label>
                                                            <input accept=".jpeg, .png, .pdf"
                                                            (change)="filechange($event)"
                                                            class="from-control" type="file" formControlName="Attacment">
                                                            </div>
                                                                
                                                        </div>
                                                    </div>
                
                                                 
                                                 





                                        

                                        <div class="col-sm-6 form-group">
                                             <div class="row">
                                                <div class="col-5 col-lg-5 col-md-5 col-sm-5">
                                            <div class="mt-20">
                                            <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                            <div class="">
                                                <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                <div class="captcha-text">{{this.captcha}} <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i> </div>
                                            </div>
                                            </div>
                                            </div>
                                             <div class="col-7 col-lg-7 col-md-7 col-sm-7">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Captcha"
                                                    placeholder="Captcha"
                                                    [ngClass]="{ 'is-invalid': submitted && complaintForm.controls['Captcha'].errors }"
                                                    id="Captcha" />
                                                <div *ngIf="submitted && complaintForm.controls['Captcha'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="complaintForm.controls['Captcha'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Captcha">Enter Captcha<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                            </div>

                                             </div>
                                        </div>
                                        

                                    </div>
                                    <div class="mt-20">
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="onSubmit()"  class="greenbtn2">Submit<i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>

                                        <div *ngIf="alertType == 'submit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                                        
                                    </div>

                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>