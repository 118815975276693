<!-- Inner Page Content Section Start -->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Quick Bill <span>Pay</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page">Quick Bill Pay</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">

                <app-recent-post></app-recent-post>

            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                        <div class="light-blue-bg mb-lg-0 mb-30">
                                <div class="row">
                                    <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
            
                                        <div class="customer-login-page">
            
                                            <div class="log-meter-text">
                                              <h6 class="text-center">Pay Your Bill</h6>
                                            </div>
            
                                            <div  class="row">
                                                <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <a href="https://paytm.com/gas-bill-payment/Mahanagar-Gas--Mumbai" target="_blank">
                                                        <img src="assets/images/paytm image.jpg"  />
                                                    </a>
                                                </div> -->
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-12 mx-auto" *ngIf="logincheckFlag==false">
                                                    <a href="https://payments.billdesk.com/MercOnline/mglBillPay/mgl_billpayment.html" target="_blank" class="pay-bill-deskbtn">
                                                        <img src="assets/images/billdesk image.jpg"/>
                                                    </a>
                                                </div>
                                                 <div class="col-lg-6 col-md-6 col-sm-12 mx-auto" *ngIf="logincheckFlag==true">
                                                    <a  href="" onclick="return false;" target="_blank" (click)="getbillDesk()" class="pay-bill-deskbtn">
                                                        <img src="assets/images/billdesk image.jpg"/>
                                                    </a>
                                                </div>
                                            </div>
            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>