import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validator, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-loginwithotp',
  templateUrl: './loginwithotp.component.html',
  styleUrls: ['./loginwithotp.component.css']
})
export class LoginwithotpComponent {
dialog: any = false

  status: any = 'collapse'

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'
  loading = false;

  UserForm !: FormGroup
  checkOtpForm!: FormGroup
  

  allFaq: any[] = []

  Otp: any

  submitted: boolean = false
  OtpSubmitted = false
  stopTimer: boolean = false

  submitStatus: boolean = false
  display: string = 'none'
  Popup: any = ''

 

  ShowOtp: boolean = false
  OtpButtonActive: boolean = false
  Minute: any = 1
  Sec: any = 10

  captcha:any;
  alertMsgNotfoundFlag = false;

  constructor(public translate: TranslateService, private FormBuilder: FormBuilder, public FaqService: FaqServiceService, private service: UserRegistrationService, private router: Router, private pageLoaderService: PageLoaderService) {
    translate.addLangs(['en', 'hi']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.captchaGenerate()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.checkLogin()

    this.UserForm = this.FormBuilder.group({
      BPnoCAno: ['', Validators.required],
      Captcha:['',Validators.required],
      Otp: [''],
      mobileNo: ['', Validators.required],
    });

     this.checkOtpForm = this.FormBuilder.group({
      Otp: ['', Validators.required]
    })


    this.getAllFaq()
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  checkLogin() {
    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    let data = '';
     let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    if (data) {
    this.router.navigateByUrl('/residential-png/customer-zone/customer-login', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/user-dashboard']);
    }); 
    }
  }

  getAllFaq() {
    this.FaqService.getAllFaq().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      //console.log(data)
      this.allFaq = data
    })
  }


 openPopup() {
    this.Popup = 'show'
    this.display = 'block'
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
    this.stopTimer = true
  }

  OtpGenerate(){

      this.service.loginwithOTP(this.UserForm.value).subscribe(response => {
      //console.log(response)
      let res = JSON.parse(JSON.stringify(response))
      if (res.response ==false) {

         this.alertType = 'secSubmit'
     this.alertMsg = "Something went wrong."
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
       

      } else {
         this.alertType = 'secSubmit'
     this.alertMsg = "OTP send on registered mobile number."
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
      setTimeout(() => {
        this.clearAlert()
          this.otpTimer();
          this.OtpButtonActive = true
       }, 1000);
      }

    }, err => {
  
      //console.log(err)
    })



  }

    otpTimer() {
    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {
    sec--;
    if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0
        clearInterval(count)
      }
     if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);
     var test = () => {
      if (this.stopTimer) {
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp = ''
       // this.submitStatus = true
        clearInterval(count)
      }
      this.Minute = minute
      this.Sec = sec
    }
  }

  

verifyOtp(){

  this.OtpSubmitted = true
   if (this.checkOtpForm.invalid) {
      return
    }

    let obj = {"BPnoCAno":this.UserForm.get('BPnoCAno')?.value,"mobileNo":this.UserForm.get('mobileNo')?.value,
    "Otp":this.checkOtpForm.get('Otp')?.value}

      this.service.checkLoginOtp(obj).subscribe(response => {
    //  console.log(response)
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == false) {
      this.alertType = 'secSubmit'
     this.alertMsg = "OTP Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);

      } else {
         this.closeModel()
         
        sessionStorage.setItem('UserCredential', JSON.stringify(response))
         var  convert_session = crypto.AES.encrypt(JSON.stringify(response), 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
        sessionStorage.setItem('UserCredential_New',convert_session )
        
        this.service.loginStatus = true
        this.alertType='login'
        this.alertMsg = "Welcome To MGL"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {//lumiq
      this.loading = false;
          this.clearAlert()
          this.router.navigate(['/user-dashboard'])
          this.service.loggedIn = true
        }, 1000);


      }

    }, err => {
  this.loading = false;
     // console.log(err)
    })

}


  

  clearAlert() {
    this.alertType = ''
  }



  submit() {
    
    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
    this.captchaGenerate()

    this.submitted = true
    this.loading = true;
    if (this.UserForm.invalid) {
     this.loading = false;
      return;
    }
    
    this.service.loginwithOTP(this.UserForm.value).subscribe(response => {
       this.alertMsgNotfoundFlag = false;
      //console.log(response)
      let res = JSON.parse(JSON.stringify(response)).message
      if (res === 'Invalid Crediential' || res === 'Your Account Is Blocked' || res ==='BP/CA not registered in MGL website') {
        this.alertType='login'
    if (res === 'Invalid Crediential') {
      this.alertMsg = "BP Number OR CA Number OR Mobile Number is invalid.";
    } else if (res === 'Your Account Is Blocked') {
      this.alertMsg = "Your Account Is Blocked/Inactive. Please contact MGL";
    }else if (res === 'User is not Register') {
      this.alertMsg = "You are not register on our website. Please Sign Up";
    }else if (res === 'BP/CA not registered in MGL website') {
      this.alertType='login1'
      this.alertMsgNotfoundFlag = true;
    //  this.alertMsg = "BP/CA not registered in MGL website. Please click here"+"<a href='https://www.mahanagargas.com/user-registration' target='_self'>Register Now</a>";
    } else {
      this.alertMsg = "You are not register on our website. Please Sign Up";
    }
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.clearAlert()
          this.loading = false;
        }, 5000);

      } else {
         this.loading = false;
         this.openPopup();
         this.OtpButtonActive = true
         this.submitStatus = false
         this.otpTimer();
         //console.log('check Otp')
      //   sessionStorage.setItem('UserCredential', JSON.stringify(response))
        
      //   this.service.loginStatus = true
      //   this.alertType='login'
      //   this.alertMsg = "Welcome To MGL"
      //   this.alertColor = 'rgba(4, 183, 107, 0.2)'
      //   this.alertTextColor = '#038b51'
      //   this.alertBorder = '1px solid #04a862;'

      //   setTimeout(() => {//lumiq
      // this.loading = false;
      //     this.clearAlert()
      //     this.router.navigate(['/user-dashboard'])
      //     this.service.loggedIn = true
      //   }, 1000);


      }

    }, err => {
  this.loading = false;
     // console.log(err)
    })

  }

}

