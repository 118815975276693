import { Component, OnInit } from '@angular/core';
import { ManageClientService } from '../Service/manage-client.service';
import { SliderService } from '../Service/slider.service';
import { environment } from 'src/environments/environment';
import { UserRegistrationService } from '../Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlogItem } from '../models/blogs';
import { BlogarticleService } from '../Service/blogarticle.service';
import { AwardsService } from 'src/app/Service/awards.service';


@Component({
  selector: 'app-home-page-content',
  templateUrl: './home-page-content.component.html',
  styleUrls: ['./home-page-content.component.css']
})
export class HomePageContentComponent implements OnInit {
  CsrData:any = {
    Title:'',
    Description:'',
    Url:'',
    ImageName:''
  };


  awarData:any = {
    Title:'',
    Discription:'',
    Image:''
  };

  public base = environment.baseUrl;
  stock:any={
    CurrentPrice:'',
    Changes:'',
    BseCurrentPrice:'',
    BseChange:''
  }
  BseColors = "";
  NseColors = "";
  myDate: any;
  customOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    navText: ["<i class='demo-icon uil-angle-left'></i>","<i class='demo-icon uil-angle-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      }
    },
    nav: true
  }

  articalOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    navText: ["<i class='demo-icon uil-angle-left'></i>","<i class='demo-icon uil-angle-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      250: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
    },
    nav: true
  }

  networkOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      250: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      }
    }
  }

BlogOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      250: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      }
    }
  }
    
  newsOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      333: {
        items: 2
      },
      666: {
        items: 3
      }
    }
  }

  leaderOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    navText: ["<i class='demo-icon uil-angle-left'></i>","<i class='demo-icon uil-angle-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      // 200: {
      //   items: 2
      // }
    },
    nav: true
  }

  allSlide:any[]=[]
  allHighlight:any[]=[]
  allClient:any[]=[]
  allNews:any[]=[]
  Popup:any=''
  safeSrc: SafeResourceUrl = '';
  display:string='none'
  blogdata:BlogItem []=[]
  count:any=0
  prev(){
   // console.log("running")
    return "http://localhost:3000/h3-sl1.jpg"
  }
  currentImg:any='';

   networkData:any[]=[];
   pngRate:any;
    Industrialestablishments:any;
    commercialestablishments:any;
      Fillingstation:any;
    Dispensingpoints:any;
    NMMTBuses:any;
    PrivateBuses:any;
    Vehicles:any;
    Taxis:any;
    Rickshaws:any;
    Cars:any;
    Steelpipeline:any;
    PEpipeline:any;


  constructor(private slideService : SliderService,private clientService: ManageClientService, private service: UserRegistrationService, private datePipe: DatePipe, public sanitizer:DomSanitizer,private blogService:BlogarticleService,
    private AwardsService:AwardsService
    ) { 
    this.myDate = this.datePipe.transform(new Date(), 'dd-MMM-yyyy');
  }

  ngOnInit(): void {
   

    this.getAllSlide()
    this.getAllHighlight()
    this.getAllNews()
    this.getAllClient()
    this.getStockData()
    this.get()	
    this.getAllCsr();
    this.mglNetworklist();
//this.getAllAward();
  }

  getAllCsr(){

    this.service.getAllCsr().subscribe((res: any) => {
      let data = JSON.parse(JSON.stringify(res))

      this.CsrData = data.details[0]
      //console.log(this.CsrData)
  })

  }

  //   getAllAward(){

  //   this.AwardsService.getAll().subscribe((res: any) => {
  //     let data = JSON.parse(JSON.stringify(res))

  //     this.awarData = data.details[0]
  //     console.log(this.awarData)
  // })

  // }

   mglNetworklist(){

    this.service.mglNetworklist().subscribe((res: any) => {
      let data = JSON.parse(JSON.stringify(res))

     // console.log(data)

       this.networkData = data.details;
       //console.log('this.networkData',this.networkData)

            for (var i = 0; i < this.networkData.length; i++) {
              if(this.networkData[i].titles=='PNG'){
                 this.pngRate=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Industrial establishments'){
                 this.Industrialestablishments=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Small commercial establishments'){
                 this.commercialestablishments=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Filling station'){
                 this.Fillingstation=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Dispensing points'){
                 this.Dispensingpoints=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='BEST / TMT / MSRTC / NMMT Buses'){
                 this.NMMTBuses=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='LCV / Tempos / Trucks / Private Buses'){
                 this.PrivateBuses=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Vehicles'){
                 this.Vehicles=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Taxis'){
                 this.Taxis=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Rickshaws'){
                 this.Rickshaws=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Cars'){
                 this.Cars=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Steel pipeline'){
                 this.Steelpipeline=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='PE pipeline'){
                 this.PEpipeline=this.networkData[i].rates;
              }

           

            }

       // if(this.networkData.titles=='PNG'){
       //      this.pngRate = this.networkData.rates;
       //      console.log(this.pngRate)

       // }
      
  })

  }

  // getAllSlide(){
  //   this.slideService.getAllSlide().subscribe(response=>{
  //     let data = JSON.parse(JSON.stringify(response)).details

  //     this.allSlide=data

  //     this.currentImg=this.allSlide[0]
  //     var tt = $('<div id="slider" class="slides ">');
  //     var count = 1;
  //     $.each(data, function(key, val) {
	 //  if (count == 2){
  //         var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
  //           '<div class="content-part">'+
  //             '<div class="slider-des">'+
  //               '<h1 class="sl-title">'+val.Name+'</h1>'+
  //                 //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
  //               '<div class="slider-bottom">'+
  //                 '<a class="readon consultant white-slide" target="_blank" href="https://www.mahanagargas.com/residential-png/domestic-connection-request/domestic-png-registration/png-overview">Read More <i class="demo-icon uil-arrow-right"></i></a>'+
  //               '</div>'+
  //             '</div>'+
  //           '</div>'+
  //         '</div>')
  //         $(".slider-container-data").append(slideData);
  //       }else if (count == 1){
  //          var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
  //           '<div class="content-part">'+
  //             '<div class="slider-des">'+
  //               '<h1 class="sl-title">'+val.Name+'</h1>'+
  //                 //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
  //               '<div class="slider-bottom">'+
  //                 '<a class="readon consultant white-slide" target="_blank" href="https://www.mahanagargas.com/cng/cng-offers-schemes/savings-ki-shubh-shuruaat">Apply Now <i class="demo-icon uil-arrow-right"></i></a>'+
  //               '</div>'+
  //             '</div>'+
  //           '</div>'+
  //         '</div>')
  //         $(".slider-container-data").append(slideData);

  //       } else {
  //         var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
  //           '<div class="content-part">'+
  //             '<div class="slider-des">'+
  //               '<h1 class="sl-title">'+val.Name+'</h1>'+
  //                 //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
  //             '</div>'+
  //           '</div>'+
  //         '</div>')
  //         $(".slider-container-data").append(slideData);
  //       }
  //       tt.append('<img src="https://www.mahanagargas.com:3000/' + val.SlideImg + '" alt="" title="#slide-'+count+'" />');
  //       count++;
  //     });
  //     tt.append('</div>')
  //     $(".slider-container").append(tt);
  //     $('#slider').nivoSlider({
  //       effect: 'random',
  //       slices: 15,
  //       boxCols: 8,
  //       boxRows: 4,
  //       animSpeed: 1000,
  //       pauseTime: 5000,
  //       startSlide: 0,
  //       directionNav: true,
  //       controlNav: true,
  //       controlNavThumbs: false,
  //       pauseOnHover: false,
  //       manualAdvance: false,
  //       // prevText: this.prev(),
  //       // nextText: 'Next',
  //       randomStart: false,
  //       beforeChange: function () { },
  //       afterChange: function () { },
  //       slideshowEnd: function () { },
  //       lastSlide: function () { },
  //       afterLoad: function () { }
  //     });
  //   })
  // }


   getAllSlide(){
    this.slideService.getAllSlide().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.allSlide=data

      this.currentImg=this.allSlide[0]
      var tt = $('<div id="slider" class="slides ">');
      var count = 1;
      $.each(data, function(key, val) {

		if(val.SlideUrl != '' && val.SlideUrl == "https://www.mahanagargas.com/assets/images/pdf/List of CNG stations to provide CNG_Ambernath Gas Stoppage.pdf")
{
 var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
            '<div class="content-part">'+
              '<div class="slider-des">'+
                '<h1 class="sl-title">'+val.Name+'</h1>'+
                  //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
                '<div class="slider-bottom">'+
                  `<a class="readon consultant white-slide" target="_blank" href="${val.SlideUrl}"> Download the list of CNG stations available <i class="demo-icon uil-arrow-right"></i></a>`+
                '</div>'+
              '</div>'+
            '</div>'+
          '</div>')
          $(".slider-container-data").append(slideData);
}
    else if(val.SlideUrl != '' && val.SlideUrl == "https://www.mahanagargas.com/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request")
{
 var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
            '<div class="content-part">'+
              '<div class="slider-des">'+
                '<h1 class="sl-title">'+val.Name+'</h1>'+
                  //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
                '<div class="slider-bottom">'+
                  `<a class="readon consultant white-slide" target="_blank" href="${val.SlideUrl}">Register Now <i class="demo-icon uil-arrow-right"></i></a>`+
                '</div>'+
              '</div>'+
            '</div>'+
          '</div>')
          $(".slider-container-data").append(slideData);
}
    else if (val.SlideUrl != ''){
          var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
            '<div class="content-part">'+
              '<div class="slider-des">'+
                '<h1 class="sl-title">'+val.Name+'</h1>'+
                  //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
                '<div class="slider-bottom">'+
                  `<a class="readon consultant white-slide" target="_blank" href="${val.SlideUrl}">Read More <i class="demo-icon uil-arrow-right"></i></a>`+
                '</div>'+
              '</div>'+
            '</div>'+
          '</div>')
          $(".slider-container-data").append(slideData);
        }else{
          var slideData = $('<div id="slide-'+count+'" class="slider-direction">'+
            '<div class="content-part">'+
              '<div class="slider-des">'+
                '<h1 class="sl-title">'+val.Name+'</h1>'+
                  //'<div class="sl-subtitle">'+val.SlideDesc+'</div>');
                '<div class="slider-bottom">'+

                '</div>'+
              '</div>'+
            '</div>'+
          '</div>')
          $(".slider-container-data").append(slideData);
        }
        tt.append('<img src="https://www.mahanagargas.com:3000/' + val.SlideImg + '" alt="" title="#slide-'+count+'" />');
        count++;
      });
      tt.append('</div>')
      $(".slider-container").append(tt);
      $('#slider').nivoSlider({
        effect: 'random',
        slices: 15,
        boxCols: 8,
        boxRows: 4,
        animSpeed: 1000,
        pauseTime: 12000,
        startSlide: 0,
        directionNav: true,
        controlNav: true,
        controlNavThumbs: false,
        pauseOnHover: false,
        manualAdvance: false,
        // prevText: this.prev(),
        // nextText: 'Next',
        randomStart: false,
        beforeChange: function () { },
        afterChange: function () { },
        slideshowEnd: function () { },
        lastSlide: function () { },
        afterLoad: function () { }
      });
    })
  }


  getAllNews(){
    this.slideService.getAllNews().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    //  console.log(response)
      this.allNews = data

      
      //console.log(this.allNews)
    })
  }

  getAllHighlight(){
    this.slideService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.allHighlight=data

      this.currentImg=this.allSlide[0]

    //  console.log(this.allHighlight)
    })
  }

  getAllClient(){
    this.clientService.getAllClient().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      this.allClient=data
    })
  }

  changePageToPrev(){
    if(0 >= this.count){

    }else{
      this.count=this.count -1
      this.currentImg = this.allSlide[this.count]

    }
    
  }

  changePageToNext(){
    if(this.count >= this.allSlide.length-1){

    }else{
      this.count=this.count +1
      this.currentImg = this.allSlide[this.count]
      
    }
  }

	getStockData(){
		let stockData = sessionStorage.getItem('stock');
		if (stockData) {
			let data = JSON.parse(stockData)
			this.stock = data
			//console.log("DATA1",data)

			if(Number((data.Changes).replace(/[(),%]/g, "")) > 0){
				this.NseColors = 'green'
			} else {
				this.NseColors = 'red'
			}

			if(Number((data.BseChange).replace(/[(),%]/g, "")) > 0){
				this.BseColors = 'green'
			} else {
				this.BseColors = 'red'
			}
		} else {
			this.service.getStockData().subscribe(response=>{
				
				let data = JSON.parse(JSON.stringify(response))
				sessionStorage.setItem('stock', JSON.stringify(response));
				this.stock = data
				//console.log("DATA2", sessionStorage.getItem('stock'))
				if(Number((data.Changes).replace(/[(),%]/g, "")) > 0){
					this.NseColors = 'green'
				} else {
					this.NseColors = 'red'
				}

				if(Number((data.BseChange).replace(/[(),%]/g, "")) > 0){
					this.BseColors = 'green'
				} else {
					this.BseColors = 'red'
				}

				//console.log((data.BseChange).replace(/[(),%]/g, "")>0)
		  
			})
		}
	}
  
  get(){
    this.blogService.getAll().subscribe((res: any) => {
      this.blogdata= res
     // console.log(this.blogdata)
  })
  

}



  openPopup(videoID: any) {
    this.Popup = 'show'
    this.display = 'block'
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/VvziUU62UNo");
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
   // console.log(this.Popup)
  }
}
