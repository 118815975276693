<div id="rs-services" class="rs-services style6 bn-service">
    <div class="marketstats">
      <img src="assets/images/market-stat-bg.png" class="mk-bg">
      <div class="marketstats-content">
         <ul>
          <li><!--<i class="demo-icon uil-calling"></i>-->
            <!--<p>Emergency No/ Dial before Dig </p>
              <h6 > 1800 266 9944 / 6867 4500</h6> 
             <h6> 1800 266 9944 / 6875 9400</h6>-->
             <h6><span>Emergency no.</span>1800 266 9944 / 6875 9400</h6>
             <h6><span>Dial before Dig no.</span> 1800 2100 2100</h6>
          </li>
          <li><div class="mid-social">
          <a href=" https://www.facebook.com/mglltdofficial" target="_blank"><i class="fa fa-facebook"></i></a>
          <a href="https://twitter.com/mahanagargas" target="_blank"><i class="new-twitter-icon"><img src="./assets/images/x-iconb.png"></i></a>
          <a href="https://youtu.be/07Jxsttz6X8" target="_blank"><i class="fa fa-youtube-play"></i></a>
          <a href="https://instagram.com/mahanagargas?igshid=YmMyMTA2M2Y=" target="_blank"><i class="fa fa-instagram"></i></a>
          <a href="https://in.linkedin.com/company/mglltd" target="_blank"><i class="fa fa-linkedin"></i></a>
          </div></li> 
          <!-- <li><p>MGL-NSE</p>
              <h6 ><i class="demo-icon uil-chart-line"></i> ₹{{stock.CurrentPrice}} <span [ngStyle]="{'color':NseColors}"  >({{stock.Changes}}%)</span></h6>
          </li>
          <li><p>MGL-BSE</p>
              <h6><i class="demo-icon uil-chart-line"></i> ₹{{stock.BseCurrentPrice}} <span [ngStyle]="{'color':BseColors}" >{{stock.BseChange}}</span> </h6>
          </li>
          <li><p>Call Us on</p>
              <h6><i class="demo-icon uil-phone-alt"></i> 68674500 / 61564500 </h6>
          </li> -->
         </ul>

      </div>
    </div>  
  <div class="looking-for mgl-corporate-service-sect">
      <img src="assets/images/i-looking-for-bg.svg" class="looking-for-bgimg">
      <div class="looking-for-services">
          <h3>I am <span> looking for</span></h3>
          <ul>
           <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/domestic-connection.png">
                  <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request']">
                    <h6>Domestic <span>Connection</span></h6>
                  </a>
                </abbr>
                </li>
                <li>
                    <abbr>
                      <img src="assets/images/looking-for/mgl-corporate/update-your-contact-details.png">
                      <a [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">
                        <h6>Update your <span>Contact Details</span></h6>
                      </a>
                    </abbr>
                    </li>
            <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/generate-your-bill.png">
                  <a [routerLink]="['/residential-png/customer-zone/current-bill']">
                    <h6>Generate <span>your Bill</span></h6>
                  </a>
                </abbr>
              </li>   
              
              <!-- <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/view-your-bill.png">
                  <a [routerLink]="['/view-your-bill']">
                    <h6>View <span>your Bill</span></h6>
                  </a>
                </abbr>
              </li> -->  
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/view-authorised-person.png">
                  <a [routerLink]="['/residential-png/view-authorized-personnel']">
                    <h6>View Authorised<span>Person</span></h6>
                  </a>
                </abbr>
              </li>
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/nearest-cng-station.png">
                  <a [routerLink]="['/cng/customer-zone/cng-locator']">
                    <h6>Nearest CNG <span>Station</span></h6>
                  </a>
                </abbr>
              </li>  
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/apply-for-cng-ro.png">
                  <a [routerLink]="['/cng/customer-zone/saving-calculator']">
                    <h6>Apply for<span>CNG RO</span></h6>
                  </a>
                  </abbr>
                  </li> 
                 <li>
                    <abbr>
                      <img src="assets/images/looking-for/mgl-corporate/feedback-enquiry-png.png">
                      <a [routerLink]="['/residential-png/customer-care/feedback-inquiry-form']">
                        <h6>Feedback / <span>Enquiry PNG</span></h6>
                      </a>
                    </abbr>
                  </li>   
              </ul>
      </div>
  </div>
</div>