import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutMglComponent } from './Component/about-mgl/about-mgl.component';
import { ActiveTenderComponent } from './Component/active-tender/active-tender.component';
import { AwardsComponent } from './Component/awards/awards.component';
import { BillingCycleComponent } from './Component/billing-cycle/billing-cycle.component';
import { BillingInfoComponent } from './Component/billing-info/billing-info.component';
import { BlogDetailsComponent } from './Component/blog-details/blog-details.component';
import { BlogListComponent } from './Component/blog-list/blog-list.component';
import { CngFillingstationComponent } from './Component/cng-fillingstation/cng-fillingstation.component';
import { ComPngApplicationComponent } from './Component/com-png-application/com-png-application.component';
import { CsrInitiativeEducComponent } from './Component/CSR/csr-initiative-educ/csr-initiative-educ.component';
import { CsrInitiativeEmpComponent } from './Component/CSR/csr-initiative-emp/csr-initiative-emp.component';
import { CsrInitiativeEnvComponent } from './Component/CSR/csr-initiative-env/csr-initiative-env.component';
import { CsrInitiativeComponent } from './Component/CSR/csr-initiative/csr-initiative.component';
import { CsrOtherInitiativeComponent } from './Component/CSR/csr-other-initiative/csr-other-initiative.component';
import { CsrPolicyComponent } from './Component/CSR/csr-policy/csr-policy.component';
import { CurrentOppComponent } from './Component/current-opp/current-opp.component';
import { CustomerCareOverviewComponent } from './Component/customer-care-overview/customer-care-overview.component';
import { CustomerDashboardComponent } from './Component/customer-dashboard/customer-dashboard.component';
import { CustomerLoginComponent } from './Component/customer-login/customer-login.component';
import { CustomerUserRegistrationComponent } from './Component/customer-user-registration/customer-user-registration.component';
import { DisclamerComponent } from './Component/disclamer/disclamer.component';
import { EmdFormatComponent } from './Component/emd-format/emd-format.component';
import { FaqListComponent } from './Component/faq-list/faq-list.component';
import { GalleryComponent } from './Component/gallery/gallery.component';
import { InstructionToBiddersComponent } from './Component/instruction-to-bidders/instruction-to-bidders.component';
import { LifemglComponent } from './Component/lifemgl/lifemgl.component';
import { MglJourneyComponent } from './Component/mgl-journey/mgl-journey.component';
import { NachOnlineComponent } from './Component/nach-online/nach-online.component';
import { NewsListComponent } from './Component/news-list/news-list.component';
import { OnlinePaymentComponent } from './Component/online-payment/online-payment.component';
import { OurObjectiveComponent } from './Component/our-objective/our-objective.component';
import { OverviewComponent } from './Component/overview/overview.component';
import { PrivacypolicyComponent } from './Component/privacypolicy/privacypolicy.component';
import { SecurityDepositComponent } from './Component/security-deposit/security-deposit.component';
import { TypesOfIndustriesComponent } from './Component/types-of-industries/types-of-industries.component';
import { HomePageContentComponent } from './home-page-content/home-page-content.component';

import { CustomerSaftyCheckListComponent } from './Component/customer-safty-check-list/customer-safty-check-list.component';
import { TechGuideForPngInstallComponent } from './Component/tech-guide-for-png-install/tech-guide-for-png-install.component';
import { AfterSaleServiceChargeComponent } from './Component/after-sale-service-charge/after-sale-service-charge.component';
import { ImportantPolicyComponent } from './Component/important-policy/important-policy.component';
import { CustomerPaymentHistoryComponent } from './Component/customer-payment-history/customer-payment-history.component';
import { CustomerBillingHistoryComponent } from './Component/customer-billing-history/customer-billing-history.component';
import { InddocumentComponent } from './Component/inddocument/inddocument.component';
import { HowtoapplyComponent } from './Component/howtoapply/howtoapply.component';
import { DocumentrequireComponent } from './Component/documentrequire/documentrequire.component';
import { PngpaymentComponent } from './Component/pngpayment/pngpayment.component';
import { ChangePasswordComponent } from './Component/change-password/change-password.component';
import { IndapplyComponent } from './Component/indapply/indapply.component';
import { LogcomplaintComponent } from './Component/logcomplaint/logcomplaint.component';
import { ViewcomplaintComponent } from './Component/viewcomplaint/viewcomplaint.component';
import { EditProfileComponent } from './Component/edit-profile/edit-profile.component';
import { BenefitsComponent } from './Component/benefits/benefits.component';
import { ClienteleComponent } from './Component/clientele/clientele.component';
import { CautionNoticeComponent } from './Component/caution-notice/caution-notice.component';
import { OverviewEnviroBenifitsComponent } from './Component/overview-enviro-benifits/overview-enviro-benifits.component'
import { WalkinCenterComponent } from './Component/walkin-center/walkin-center.component';
import { LogMeterReadingComponent } from './Component/log-meter-reading/log-meter-reading.component';
import { PaymentLinkComponent } from './Component/payment-link/payment-link.component';
import { HowbilliscalculatedComponent } from './Component/howbilliscalculated/howbilliscalculated.component';
import { GasgyserrateComponent } from './Component/gasgyserrate/gasgyserrate.component';
import { CngratecardComponent } from './Component/cngratecard/cngratecard.component';

import { ReqmtForOpenPlotComponent } from './Component/reqmt-for-open-plot/reqmt-for-open-plot.component';
import { DomesticConnectionComponent } from './Component/domestic-connection/domestic-connection.component';
import { CngtetingstationComponent } from './Component/cngtetingstation/cngtetingstation.component';
import { NewsDetailComponent } from './Component/news-detail/news-detail.component';
import { CngkitsupplierComponent } from './Component/cngkitsupplier/cngkitsupplier.component';
import { ConversionEnquiryFormComponent } from './Component/conversion-enquiry-form/conversion-enquiry-form.component';
import { LeadershipComponent } from './Component/leadership/leadership.component';
import { AnnualReportComponent } from './Component/annual-report/annual-report.component';
import { AuditedResultsComponent } from './Component/audited-results/audited-results.component';
import { ApplyForNewConnectionComponent } from './Component/apply-for-new-connection/apply-for-new-connection.component';
import { HowToApplyOnlineComponent } from './Component/how-to-apply-online/how-to-apply-online.component';
import { DocumentsRequiredComponent } from './Component/documents-required/documents-required.component';
import { CngMobileRefuelingUnitComponent } from './Component/cng-mobile-refueling-unit/cng-mobile-refueling-unit.component';
import { EmpanelMentNoticeComponent } from './Component/empanel-ment-notice/empanel-ment-notice.component';
import { CngupcomingreportsComponent } from './Component/cngupcomingreports/cngupcomingreports.component';
import { CarcareComponent } from './Component/carcare/carcare.component';
import { PngRateCardComponent } from './Component/png-rate-card/png-rate-card.component';
import { HesPolicyComponent } from './Component/hes-policy/hes-policy.component';
import { EmpanelmentComponent } from './Component/empanelment/empanelment.component';
import { BiddersOfHolidayComponent } from './Component/bidders-of-holiday/bidders-of-holiday.component';
import { QuarterlyResultComponent } from './Component/quarterly-result/quarterly-result.component';
import { InvestorGuideComponent } from './Component/investor-guide/investor-guide.component';
import { PoliciesComponent } from './Component/policies/policies.component';
import { CorporateGovernanceComponent } from './Component/corporate-governance/corporate-governance.component';
import { AnnualGeneralMeetingComponent } from './Component/annual-general-meeting/annual-general-meeting.component';
import { DividendPolicyComponent } from './Component/dividend-policy/dividend-policy.component';
import { UnclaimedDividendComponent } from './Component/unclaimed-dividend/unclaimed-dividend.component';
import { FeedbackInquiryComponent } from './Component/feedback-inquiry/feedback-inquiry.component';
import { IfYouSmellGasComponent } from './Component/if-you-smell-gas/if-you-smell-gas.component';
import { MglLifeSavingRulesComponent } from './Component/mgl-life-saving-rules/mgl-life-saving-rules.component';
import { EnvironmentalComplianceComponent } from './Component/environmental-compliance/environmental-compliance.component';
import { DropboxComponent } from './Component/dropbox/dropbox.component';
import { NaturalGasDataSheetComponent } from './Component/natural-gas-data-sheet/natural-gas-data-sheet.component';
import { MGLQualityPolicyComponent } from './Component/mglquality-policy/mglquality-policy.component';
import { RegisteredandcorporateofficeaddressComponent } from './Component/registeredandcorporateofficeaddress/registeredandcorporateofficeaddress.component';
import { SafetyAsCoreValueComponent } from './Component/safety-as-core-value/safety-as-core-value.component';
import { AssetIntegrityComponent } from './Component/asset-integrity/asset-integrity.component';
import { STCTrainingComponent } from './Component/stc-training/stc-training.component';
import { SafetyFeaturesComponent } from './Component/safety-features/safety-features.component';
import { VideogalleryComponent } from './Component/videogallery/videogallery.component';
import { MglSpiritStmtComponent } from './Component/mgl-spirit-stmt/mgl-spirit-stmt.component';
import { OurApproachtoSustainabilityComponent } from './Component/our-approachto-sustainability/our-approachto-sustainability.component';
import { ServiceproviderComponent } from './Component/serviceprovider/serviceprovider.component';
import { ChargestobetakenComponent } from './Component/PngServicecharges/chargestobetaken.component';
import { NametranferComponent } from './Component/nametranfer/nametranfer.component';
import { ApplyfornameComponent } from './Component/applyforname/applyforname.component';
import { InvestorUpdateAndPresentationComponent } from './Component/investor-update-and-presentation/investor-update-and-presentation.component';
import { ShareHoldingPatternComponent } from './Component/share-holding-pattern/share-holding-pattern.component';
import { SitemapComponent } from './Component/sitemap/sitemap.component';
import { CNGsavingcalculatorComponent } from './Component/cngsavingcalculator/cngsavingcalculator.component';
import { CurrentBillComponent } from './Component/current-bill/current-bill.component';
import { ApplyforcngouletComponent } from './Component/applyforcngoulet/applyforcngoulet.component';
import { CustomersupportComponent } from './Component/customersupport/customersupport.component';
import { CngFeedbackFormComponent } from './Component/cng-feedback-form/cng-feedback-form.component';
import { CngComplaintStatusComponent } from './Component/cng-complaint-status/cng-complaint-status.component';
import { ForgotPasswordComponent } from './Component/forgot-password/forgot-password.component';
import { CnglocatorsComponent } from './Component/cnglocators/cnglocators.component';
import { QrPayComponent } from './Component/qr-pay/qr-pay.component';
import { AuthorizedpersonComponent } from './Component/authorizedperson/authorizedperson.component';
import { DomesticConReqFormComponent } from './Component/domestic-con-req-form/domestic-con-req-form.component';
import { DomesticConfromNonGasifiedNotAvailComponent } from './Component/domestic-confrom-non-gasified-not-avail/domestic-confrom-non-gasified-not-avail.component';
import { DomesticConfromNonGasifiedNotAvailIsBeenLaidComponent } from './Component/domestic-confrom-non-gasified-not-avail-is-been-laid/domestic-confrom-non-gasified-not-avail-is-been-laid.component';
import { ConnectionApplicationStatusComponent } from './Component/connection-application-status/connection-application-status.component';
import { TrackNameTransferReqComponent } from './Component/track-name-transfer-req/track-name-transfer-req.component';
import { DomesticPngConnGasifiedBulidComponent } from './Component/domestic-png-conn-gasified-bulid/domestic-png-conn-gasified-bulid.component';
import { ContactUsComponent } from './Component/contact-us/contact-us.component';
import { CarrierjobapplyComponent } from './Component/carrierjobapply/carrierjobapply.component';
import { BlogarticleComponent } from './Component/blogarticle/blogarticle.component';
import { MGLSahayogiDialbeforeDigComponent } from './Component/mglsahayogi-dialbefore-dig/mglsahayogi-dialbefore-dig.component';
import { IcBillComponent } from './Component/ic-bill/ic-bill.component'
import { NachCancellationComponent } from './Component/nach-cancellation/nach-cancellation.component';
import { PngNetworkComponent } from './Component/png-network/png-network.component';
import { RefundPolicyComponent } from './Component/refund-policy/refund-policy.component';
import { StockExchangeComponent } from './Component/stock-exchange/stock-exchange.component';
import { IandCNachCancellationComponent } from './Component/iand-cnach-cancellation/iand-cnach-cancellation.component';
import { BloglistsComponent } from './Component/bloglists/bloglists.component';
import { TenderComponent } from './Component/tender/tender.component';
import { VehiclepromotionComponent } from './Component/vehiclepromotion/vehiclepromotion.component';
import { QuickPayComponent } from './Component/quick-pay/quick-pay.component';
import { ThankYouComponent } from './Component/thank-you/thank-you.component';
import { EditVehiclepromotionComponent } from './Component/edit-vehiclepromotion/edit-vehiclepromotion.component';
import { EditVehiclepromotionforeditComponent } from './Component/edit-vehiclepromotionforedit/edit-vehiclepromotionforedit.component';
import { ViewYourBillComponent } from './Component/view-your-bill/view-your-bill.component';
import { PaymentErrorComponent } from './Component/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './Component/payment-success/payment-success.component';
import { ThankYouOutletComponent } from './Component/thank-you-outlet/thank-you-outlet.component';
import { IcQrPayComponent } from './Component/ic-qr-pay/ic-qr-pay.component';
import { AwardDetailsComponent } from './Component/award-details/award-details.component';
import { AwardsListComponent } from './Component/awards-list/awards-list.component';
import { SavingsKiShubhShuruaatBrochureComponent } from './Component/savings-ki-shubh-shuruaat-brochure/savings-ki-shubh-shuruaat-brochure.component';
import { MgltezComponent } from './Component/mgltez/mgltez.component';
import { ExpressionOfInterestComponent } from './Component/expression-of-interest/expression-of-interest.component';
import { NtdcComponent } from './other/ntdc/ntdc.component';
import { NtlcComponent } from './other/ntlc/ntlc.component';
import { NtzcComponent } from './other/ntzc/ntzc.component';
import { NtzdComponent } from './other/ntzd/ntzd.component';
import { Mr1Component } from './other/mr1/mr1.component';
import { EbillzprojectComponent } from './other/ebillzproject/ebillzproject.component';
import { MeterreadingprojectComponent } from './other/meterreadingproject/meterreadingproject.component';
import { NexgenprojectComponent } from './other/nexgenproject/nexgenproject.component';
import { StatusofopenrequestComponent } from './Component/statusofopenrequest/statusofopenrequest.component';
import { SteelpipelinenetworklayoutComponent } from './Component/steelpipelinenetworklayout/steelpipelinenetworklayout.component';
import { ResetPasswordComponent } from './Component/reset-password/reset-password.component';
import { VisionComponent } from './Component/vision/vision.component';
import { NametransferdetailsComponent } from './Component/nametransferdetails/nametransferdetails.component';
import { ApplyfornameEditComponent } from './Component/applyforname-edit/applyforname-edit.component';
import { EtendersapsrmComponent } from './Component/etendersapsrm/etendersapsrm.component'
import { CommercialtariffcardComponent } from './Component/commercialtariffcard/commercialtariffcard.component';
import { IndustrialtariffcardComponent } from './Component/industrialtariffcard/industrialtariffcard.component'
import { OpenplotnearusarComponent } from './Component/openplotnearusar/openplotnearusar.component'
import { WithoutloginlogmeterreadingComponent } from './Component/withoutloginlogmeterreading/withoutloginlogmeterreading.component'
import { CsrprojectComponent } from './Component/csrproject/csrproject.component';
import { NewslettersComponent } from './Component/newsletters/newsletters.component';
import { NewslettersdetailsComponent } from './Component/newslettersdetails/newslettersdetails.component';
import { NachpaymentsuccesssComponent } from './Component/nachpaymentsuccesss/nachpaymentsuccesss.component';
import { NachpaymenterrorComponent } from './Component/nachpaymenterror/nachpaymenterror.component';
import { UpiautopayComponent } from './Component/upiautopay/upiautopay.component';
import { RetrofittersdetailsComponent } from './Component/retrofittersdetails/retrofittersdetails.component';
import { NewCNGVehicleComponent } from './Component/new-cngvehicle/new-cngvehicle.component';
import { RetrofitmentComponent } from './Component/retrofitment/retrofitment.component';
import { CngmahotsavmarutisuzukiComponent } from './Component/cngmahotsavmarutisuzuki/cngmahotsavmarutisuzuki.component';
import { RetrofitmentFAQsComponent } from './Component/retrofitment-faqs/retrofitment-faqs.component';
import { NewvehicleFAQComponent } from './Component/newvehicle-faq/newvehicle-faq.component';
import { CngmahotsavmarutisuzukiappstatusComponent } from './Component/cngmahotsavmarutisuzukiappstatus/cngmahotsavmarutisuzukiappstatus.component';
import { CngmahotsavmarutisuzukiappdetailsComponent } from './Component/cngmahotsavmarutisuzukiappdetails/cngmahotsavmarutisuzukiappdetails.component';
import { MglcngmahotsavComponent } from './Component/mglcngmahotsav/mglcngmahotsav.component';
import { CngmahotsavmarutisuzukiapprovalComponent } from './Component/cngmahotsavmarutisuzukiapproval/cngmahotsavmarutisuzukiapproval.component';
import { QuarterlycorporategovernanceComponent } from './Component/quarterlycorporategovernance/quarterlycorporategovernance.component';
import { MarutisuzukithankyouComponent } from './Component/marutisuzukithankyou/marutisuzukithankyou.component';
import { DownloadapplinkComponent } from './Component/downloadapplink/downloadapplink.component';
import { OemsnewvehiclesComponent } from './Component/oemsnewvehicles/oemsnewvehicles.component';
import { OemsnewvehiclesdetailsComponent } from './Component/oemsnewvehiclesdetails/oemsnewvehiclesdetails.component';
import { OemsnewvehiclesapprovalComponent } from './Component/oemsnewvehiclesapproval/oemsnewvehiclesapproval.component';
import { OemsnewvehiclesstatusComponent } from './Component/oemsnewvehiclesstatus/oemsnewvehiclesstatus.component';
import { CngmahotsavmarutisuzukiapplicationeditComponent } from './Component/cngmahotsavmarutisuzukiapplicationedit/cngmahotsavmarutisuzukiapplicationedit.component';
import { OemsnewvehicleseditComponent } from './Component/oemsnewvehiclesedit/oemsnewvehiclesedit.component';
import { RetrofittersloginComponent } from './Component/retrofitterslogin/retrofitterslogin.component';
import { RetrofittersappstatusComponent } from './Component/retrofittersappstatus/retrofittersappstatus.component';
import { RetrofittersappdetailsComponent } from './Component/retrofittersappdetails/retrofittersappdetails.component';
import { RetrofittersappeditComponent } from './Component/retrofittersappedit/retrofittersappedit.component';
import { DomesticpngconngasifiedbulidnewComponent } from './Component/domesticpngconngasifiedbulidnew/domesticpngconngasifiedbulidnew.component';
import { ConnectionApplicationStatusnewComponent } from './Component/connection-application-statusnew/connection-application-statusnew.component';
import { PaymentSuccessTestComponent } from './Component/payment-success-test/payment-success-test.component';
import { PaymentErrorTestComponent } from './Component/payment-error-test/payment-error-test.component';
import { GenerateyourbillComponent } from './Component/generateyourbill/generateyourbill.component';
import { NationalpngdriveComponent } from './Component/nationalpngdrive/nationalpngdrive.component';
import { MglcorporatebroucherComponent } from './Component/mglcorporatebroucher/mglcorporatebroucher.component';
import { LoginwithotpComponent } from './Component/loginwithotp/loginwithotp.component';
import { PayuwalletComponent } from './Component/payuwallet/payuwallet.component';
import { PayuwalletbalanceComponent } from './Component/payuwalletbalance/payuwalletbalance.component';
import { PayuwalletthankyouComponent } from './Component/payuwalletthankyou/payuwalletthankyou.component';
import { PayupaymentsuccessComponent } from './Component/payupaymentsuccess/payupaymentsuccess.component';
import { PayupaymenterrorComponent } from './Component/payupaymenterror/payupaymenterror.component';
import { PayUpaymenterrorComponent } from './Component/pay-upaymenterror/pay-upaymenterror.component';
import { PayUpaymentsuccessComponent } from './Component/pay-upaymentsuccess/pay-upaymentsuccess.component';
import { PayUunloadpaymenterrorComponent } from './Component/pay-uunloadpaymenterror/pay-uunloadpaymenterror.component';
import { PayUunloadpaymentsuccessComponent } from './Component/pay-uunloadpaymentsuccess/pay-uunloadpaymentsuccess.component';
import { ViewpayutransactionComponent } from './Component/viewpayutransaction/viewpayutransaction.component';
import { ApplyfornametsteponeComponent } from './Component/applyfornametstepone/applyfornametstepone.component';
import { ApplyfornametsteptwoComponent } from './Component/applyfornametsteptwo/applyfornametsteptwo.component';
import { ApplyfornametstepthreeComponent } from './Component/applyfornametstepthree/applyfornametstepthree.component';
import { ApplyfornametstepfourComponent } from './Component/applyfornametstepfour/applyfornametstepfour.component';
import { ApplyfornametsteponeeditComponent } from './Component/applyfornametsteponeedit/applyfornametsteponeedit.component';
import { ApplyfornametsteptwoeditComponent } from './Component/applyfornametsteptwoedit/applyfornametsteptwoedit.component';
import { ApplyfornametstepthreeeditComponent } from './Component/applyfornametstepthreeedit/applyfornametstepthreeedit.component';
import { NametransferthankyouComponent } from './Component/nametransferthankyou/nametransferthankyou.component';
import { PledgeComponent } from './Component/pledge/pledge.component';
import { PledgethankyouComponent } from './Component/pledgethankyou/pledgethankyou.component';
import { RetrofittersleveloneComponent } from './Component/retrofitterslevelone/retrofitterslevelone.component';
import { RetrofittersleveltwoComponent } from './Component/retrofittersleveltwo/retrofittersleveltwo.component';
import { RetrofitterslevelonestatusComponent } from './Component/retrofitterslevelonestatus/retrofitterslevelonestatus.component';
import { RetrofitterslevelonedetailsComponent } from './Component/retrofitterslevelonedetails/retrofitterslevelonedetails.component';
import { RetrofittersleveltwostatusComponent } from './Component/retrofittersleveltwostatus/retrofittersleveltwostatus.component';
import { RetrofittersleveltwodetailsComponent } from './Component/retrofittersleveltwodetails/retrofittersleveltwodetails.component';
import { RetrofittersleveltwoeditComponent } from './Component/retrofittersleveltwoedit/retrofittersleveltwoedit.component';
import { RetrofittersleveloneeditComponent } from './Component/retrofittersleveloneedit/retrofittersleveloneedit.component';
import { DealerzonefaqsComponent } from './Component/dealerzonefaqs/dealerzonefaqs.component';
import { OpenplotnearjitegaonComponent } from './Component/openplotnearjitegaon/openplotnearjitegaon.component';
import { ListofdocumentrequiredComponent } from './Component/listofdocumentrequired/listofdocumentrequired.component';
import { ProcessofloiissuanceComponent } from './Component/processofloiissuance/processofloiissuance.component';
import { Oemsnewvehicles20Component } from './Component/oemsnewvehicles20/oemsnewvehicles20.component';
import { Oemsnewvehicles20detailsComponent } from './Component/oemsnewvehicles20details/oemsnewvehicles20details.component';
import { Oemsnewvehicles20editComponent } from './Component/oemsnewvehicles20edit/oemsnewvehicles20edit.component';
import { Oemsnewvehicles20statusComponent } from './Component/oemsnewvehicles20status/oemsnewvehicles20status.component';
import { Oemsnewvehicles20approveComponent } from './Component/oemsnewvehicles20approve/oemsnewvehicles20approve.component';
import { DomesticPngConnGasifiedBulidInstitutionComponent } from './Component/domestic-png-conn-gasified-bulid-institution/domestic-png-conn-gasified-bulid-institution.component';
import { PaymentInstitutionSuccessComponent } from './Component/payment-institution-success/payment-institution-success.component';
import { PaymentInstitutionErrorComponent } from './Component/payment-institution-error/payment-institution-error.component';
import { ConnectionApplicationInstitutionStatusComponent } from './Component/connection-application-institution-status/connection-application-institution-status.component';
import { Mglcngmaostav20dashboardComponent } from './Component/mglcngmaostav20dashboard/mglcngmaostav20dashboard.component';
import { Mglcngmaostav20oemdashboardComponent } from './Component/mglcngmaostav20oemdashboard/mglcngmaostav20oemdashboard.component';
import { Oemsnewvehicles20leveltwostatusComponent } from './Component/oemsnewvehicles20leveltwostatus/oemsnewvehicles20leveltwostatus.component';
import { Oemsnewvehicles20leveltwodetailsComponent } from './Component/oemsnewvehicles20leveltwodetails/oemsnewvehicles20leveltwodetails.component';
import { Oemsnewvehicles20leveltwoeditComponent } from './Component/oemsnewvehicles20leveltwoedit/oemsnewvehicles20leveltwoedit.component';
import { Retrofitters20leveloneComponent } from './Component/retrofitters20levelone/retrofitters20levelone.component';
import { Retrofitters20leveltwoComponent } from './Component/retrofitters20leveltwo/retrofitters20leveltwo.component';
import { Retrofitters20levelonestatusComponent } from './Component/retrofitters20levelonestatus/retrofitters20levelonestatus.component';
import { Retrofitters20levelonedetailsComponent } from './Component/retrofitters20levelonedetails/retrofitters20levelonedetails.component';
import { Retrofitters20leveltwostatusComponent } from './Component/retrofitters20leveltwostatus/retrofitters20leveltwostatus.component';
import { Retrofitters20leveltwodetailsComponent } from './Component/retrofitters20leveltwodetails/retrofitters20leveltwodetails.component';
import { Retrofitters20leveltwoeditComponent } from './Component/retrofitters20leveltwoedit/retrofitters20leveltwoedit.component';
import { Retrofitters20leveloneditComponent } from './Component/retrofitters20levelonedit/retrofitters20levelonedit.component';
import { Marutisuzuki20thankyouComponent } from './Component/marutisuzuki20thankyou/marutisuzuki20thankyou.component';
import { ApplyforcngouletnewComponent } from './Component/applyforcngouletnew/applyforcngouletnew.component';
import { ApplyoutletstatusComponent } from './Component/applyoutletstatus/applyoutletstatus.component';
import { SubsidiaryFinancialsComponent } from './Component/subsidiary-financials/subsidiary-financials.component';
import { UnisonEnviroPrivateLimitedComponent } from './Component/unison-enviro-private-limited/unison-enviro-private-limited.component';
import { MahanagarLNGPrivateLimitedComponent } from './Component/mahanagar-lngprivate-limited/mahanagar-lngprivate-limited.component';
import { ApplyoutletthankyouComponent } from './Component/applyoutletthankyou/applyoutletthankyou.component';
import { CommercialfaqsComponent } from './Component/commercialfaqs/commercialfaqs.component';
import { IndustrialfaqsComponent } from './Component/industrialfaqs/industrialfaqs.component';
import { OemloginComponent } from './Component/oemlogin/oemlogin.component';
import { ApplyforcngouletmComponent } from './Component/applyforcngouletm/applyforcngouletm.component';
import { ApplyforcngouletnewmComponent } from './Component/applyforcngouletnewm/applyforcngouletnewm.component';
import { ApplyoutletthankyoumComponent } from './Component/applyoutletthankyoum/applyoutletthankyoum.component';
import { ThankYouOutletmComponent } from './Component/thank-you-outletm/thank-you-outletm.component';
import { ApplyoutletstatusmComponent } from './Component/applyoutletstatusm/applyoutletstatusm.component';
import { Oem20faqsComponent } from './Component/oem20faqs/oem20faqs.component';
import { ListofmglcngstationsComponent } from './Component/listofmglcngstations/listofmglcngstations.component';
import { ApplyfornameforappComponent } from './Component/applyfornameforapp/applyfornameforapp.component';
import { ApplyfornametsteponeforappComponent } from './Component/applyfornametsteponeforapp/applyfornametsteponeforapp.component';
import { ExpressionofinterestoneComponent } from './Component/expressionofinterestone/expressionofinterestone.component';
import { CheckbothdomesticapplicationstatusComponent } from './Component/checkbothdomesticapplicationstatus/checkbothdomesticapplicationstatus.component';
import { KhushiyanlakhonkiiComponent } from './Component/khushiyanlakhonkii/khushiyanlakhonkii.component';
import { DomesticPngConnGasifiedBulidCscComponent } from './Component/domestic-png-conn-gasified-bulid-csc/domestic-png-conn-gasified-bulid-csc.component';
import { SchemeofmergerComponent } from './Component/schemeofmerger/schemeofmerger.component';
import { MoaandaoaComponent } from './Component/moaandaoa/moaandaoa.component';
import { DomesticPngConnGasifiedBulidCscApplyComponent } from './Component/domestic-png-conn-gasified-bulid-csc-apply/domestic-png-conn-gasified-bulid-csc-apply.component';
import { WinnerslistComponent } from './Component/winnerslist/winnerslist.component';
import { PaymentCscSuccessComponent } from './Component/payment-csc-success/payment-csc-success.component';
import { PaymentCscErrorComponent } from './Component/payment-csc-error/payment-csc-error.component';
import { ConnectionApplicationCscStatusComponent } from './Component/connection-application-csc-status/connection-application-csc-status.component';
import { MglBrochuresAndSmComponent } from './Component/mgl-brochures-and-sm/mgl-brochures-and-sm.component';
import { RegulationOfSebiComponent } from './Component/regulation-of-sebi/regulation-of-sebi.component';

import { BillingtestComponent } from './Component/billingtest/billingtest.component';
import { ApppayupaymentsuccessComponent } from './Component/apppayupaymentsuccess/apppayupaymentsuccess.component';
import { ApppayupaymenterrorComponent } from './Component/apppayupaymenterror/apppayupaymenterror.component';
import { ApplyfornametestComponent } from './Component/applyfornametest/applyfornametest.component';

import { ApppaymentSuccessComponent } from './Component/apppayment-success/apppayment-success.component';
import { ApppaymentErrorComponent } from './Component/apppayment-error/apppayment-error.component';

import { FrameBlockGuard } from './guards/frame-block.guard';

const routes: Routes = [
  {
    path: '',
    component: HomePageContentComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Home'
    }
  }, {
    path: "residential-png/domestic-connection-request/png-network",
    component: PngNetworkComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'PNG Network'
    }
  }, {
    path: "residential-png/customer-zone/nach-cancel-form",
    component: NachCancellationComponent
  }, {
    path: 'residential-png/customer-zone/customer-login',
    component: CustomerLoginComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'User Login'
    }
  }, {
    path: 'residential-png/customer-zone/customer-login-with-otp',
    component: LoginwithotpComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'User Login With OTP'
    }
  }, {
    path: 'user-registration',
    component: CustomerUserRegistrationComponent,
    canActivate: [FrameBlockGuard],
  }, {
    path: 'user-dashboard',
    component: CustomerDashboardComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'User Dashboard'
    }
  }, {
    path: 'MGL-corporate/awards',
    component: AwardsComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Awards'
    }
  }, {
    path: 'MGL-corporate/about-MGL',
    component: AboutMglComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'About MGL'
    }
  }, {
    path: 'MGL-corporate/media/gallery/images',
    component: GalleryComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Images'
    }
  }, {
    path: 'residential-png/customer-zone/billing-cycle',
    component: BillingCycleComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'User Billing Cycle'
    }
  }, {
    path: 'commercial-png-application',
    component: ComPngApplicationComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Commercial PNG Application'
    }
  }, {
    path: 'MGL-corporate/media/news',
    component: NewsListComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'News'
    }
  }, {
    path: 'industrial-and-commercial/industrial-png/overview/types-of-industries',
    component: TypesOfIndustriesComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Types of Industries'
    }
  }, {
    path: 'faq-list',
    component: FaqListComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Faq List'
    }
  }, {
    path: 'blog-list',
    component: BlogListComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Blog List'
    }
  }, {
    path: 'blog-list/blog-detail',
    component: BlogDetailsComponent,
    canActivate: [FrameBlockGuard],
  }, {
    path: 'residential-png/nach-online',
    component: NachOnlineComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Nach Online'
    }
  }, {
    path: 'residential-png/upi-autopay-mandate',
    component: UpiautopayComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'UPI Autopay'
    }
  },{
    path: 'privacy-policy',
    component: PrivacypolicyComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Privacy Policy'
    }
  }, {
    path: 'disclamer',
    component: DisclamerComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Disclaimer'
    }
  }, {
    path: "MGL-corporate/career/life-Mgl",
    component: LifemglComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Life Mgl'
    }
  }, {
    path: 'MGL-corporate/contracts-and-procurements/tenders/active-tender',
    component: ActiveTenderComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Active Tender'
    }
  }, {
    path: 'billing-info',
    component: BillingInfoComponent,
    canActivate: [FrameBlockGuard],
  }, {
    path: 'residential-png/customer-zone/cng-filling-station',
    component: CngFillingstationComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'CNG Filling Station'
    }
  }, {
    path: 'MGL-corporate/career/current-opp',
    component: CurrentOppComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Current Opportunity'
    }
  }, {
    path: 'residential-png/customer-care/customer-care-overview',
    component: CustomerCareOverviewComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Customer Care Overview'
    }
  }, {
    path: 'emd-format',
    component: EmdFormatComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Emd Format'
    }
  }, {
    path: 'MGL-corporate/contracts-and-procurements/important-T&C-of-tenders/instruction-to-bidders',
    component: InstructionToBiddersComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Instruction TO Bidder'
    }
  }, {
    path: 'MGL-journey',
    component: MglJourneyComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'MGL Journey'
    }
  }, {
    path: 'residential-png/customer-zone/online-payment',
    component: OnlinePaymentComponent,
    canActivate: [FrameBlockGuard],
  }, {
    path: 'our-objective',
    component: OurObjectiveComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Our Objective'
    }
  }, {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Overview'
    }
  }, {
    path: "security-deposit",
    component: SecurityDepositComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Security Deposit'
    }
  }, {
    path: "MGL-corporate/csr/csr-policy",
    component: CsrPolicyComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Csr Policy'
    }
  }, {
    path: "MGL-corporate/csr/csr-initiative",
    component: CsrInitiativeComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Csr Initiative'
    }
  }, {
    path: "MGL-corporate/csr/csr-initiative/csr-initiative-env",
    component: CsrInitiativeEnvComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Csr Initiative Envirnment'
    }
  }, {
    path: "MGL-corporate/csr/csr-initiative/csr-initiative-edu",
    component: CsrInitiativeEducComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Csr Initiative Education'
    }
  }, {
    path: "MGL-corporate/csr/csr-initiative/csr-initiative-emp",
    component: CsrInitiativeEmpComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Csr Initiative Empowerment'
    }
  }, {
    path: "MGL-corporate/csr/csr-initiative/csr-other-initiative",
    component: CsrOtherInitiativeComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Csr Other Initiative'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/png-overview",
    component: OverviewComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'PNG Overview'
    }
  }, {
    path: "residential-png/customer-zone/consumer-safty-checklist",
    component: CustomerSaftyCheckListComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Consumer Safty Checklist'
    }
  }, {
    path: "residential-png/domestic-connection-request/tech-guide-for-png-installation",
    component: TechGuideForPngInstallComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Tech Guide For PNG Installation'
    }
  }, {
    path: "after-sale-service-charges",
    component: AfterSaleServiceChargeComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'After Sale Service Charges'
    }
  }, {
    path: "residential-png/policies-and-procedures/important-policy",
    component: ImportantPolicyComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Important Policy'
    }
  }, {
    path: "MGL-corporate/contracts-and-procurements/downloads/security-deposit",
    component: SecurityDepositComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Security Deposit'
    }
  }, {
    path: 'residential-png/customer-zone/payment-history',
    component: CustomerPaymentHistoryComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'User Payment History'
    }
  }, {
    path: 'residential-png/customer-zone/billing-history',
    component: CustomerBillingHistoryComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'User Billing History'
    }
  }, {
    path: "industrial-and-commercial/commercial-png/png-payment",
    component: PngpaymentComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'PNG Payment'
    }
  }, {
    path: "industrial-and-commercial/commercial-png/apply-for-commercial-connection/document",
    component: DocumentrequireComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Document'
    }
  }, {
    path: "industrial-and-commercial/Commercial-png/apply-for-commercial-connection/how-to-apply",
    component: HowtoapplyComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Commercial PNG How to apply'
    }
  }, {
    path: "industrial-and-commercial/industrial-png/apply-for-commercial-connection/ind-document",
    component: InddocumentComponent,
    data: {
      name: 'Industrial Document'
    }
  }, {
    path: 'residential-png/customer-zone/change-password',
    component: ChangePasswordComponent
  }, {
    path: "industrial-and-commercial/industrial-png/apply-for-commercial-connection/how-to-apply-ind",
    component: IndapplyComponent,
    data: {
      name: 'Industrial PNG How to Apply'
    }
  }, {
    path: "residential-png/customer-zone/log-complaint",
    component: LogcomplaintComponent,
    data: {
      name: 'Log Complaint'
    }
  }, {
    path: "residential-png/customer-zone/view-complaint",
    component: ViewcomplaintComponent,
    data: {
      name: 'View Complaint'
    }
  }, {
    path: "residential-png/customer-zone/customer-login/edit-profile",
    component: EditProfileComponent
  }, {
    path: "industrial-and-commercial/commercial-png/overview/benefits",
    component: BenefitsComponent,
    data: {
      name: 'Benefits'
    }
  }, {
    path: "industrial-and-commercial/conservation-tips-for-industrial-usage/clien-tele",
    component: ClienteleComponent,
    data: {
      name: 'Clientele'
    }
  }, {
    path: "caution-notice",
    component: CautionNoticeComponent,
    data: {
      name: 'Caution Notice'
    }
  }, {
    path: "cng/overview/overview-enviro-benifits",
    component: OverviewEnviroBenifitsComponent,
    data: {
      name: 'Overview Envirnment Benefits'
    }
  }, {
    path: "residential-png/customer-care/walk-in-center",
    component: WalkinCenterComponent,
    data: {
      name: 'Walk in Center'
    }
  }, {
    path: 'residential-png/customer-zone/log-meter-reading',
    component: LogMeterReadingComponent,
    data: {
      name: 'Log Meter Reading'
    }
  }, {
    path: 'residential-png/customer-zone/without-registering-log-meter-reading',
    component: WithoutloginlogmeterreadingComponent,
    data: {
      name: 'Without Registering Log Meter Reading'
    }
  }, {
    path: "payment-link",
    component: PaymentLinkComponent,
    data: {
      name: 'Payment Link'
    }
  }, {
    path: "how-to-cal-bill",
    component: HowbilliscalculatedComponent,
    data: {
      name: 'How bill is calculated'
    }
  },
  
  {
    path: "cng/customer-zone/cng-rate-card",
    component: CngratecardComponent,
    data: {
      name: 'CNG Rate Card'
    }
  }, {
    path: "cng/customer-zone/cng-testing-center",
    component: CngtetingstationComponent,
    data: {
      name: 'CNG Testing Center'
    }
  }, {
    path: "residential-png/customer-zone/domestic-connection",
    component: DomesticConnectionComponent,
    data: {
      name: 'Domestic Connection'
    }
  }, {
    path: "cng/dealer-zone/reqmt-open-slot",
    component: ReqmtForOpenPlotComponent,
    data: {
      name: 'Reqmt For Open Plot'
    }
  }, {
    path: "MGL-corporate/media/news/:id",
    component: NewsDetailComponent
  }, {
    path: "cng/customer-zone/cng-kit",
    component: CngkitsupplierComponent,
    data: {
      name: 'CNG Kit Supplier'
    }
  }, {
    path: "cng/customer-zone/cng-conversion-enquiry",
    component: ConversionEnquiryFormComponent,
    data: {
      name: 'CNG Conversion Enquiry'
    }
  }, {
    path: "MGL-corporate/leadership",
    component: LeadershipComponent,
    data: {
      name: 'Leadership'
    }
  }, {
    path: "MGL-corporate/investors/annual-report",
    component: AnnualReportComponent,
    data: {
      name: 'Annual Report'
    }
  }, {
    path: "MGL-corporate/investors/financial-results/audited-result",
    component: AuditedResultsComponent,
    data: {
      name: 'Audited Result'
    }
  },

  //  {
  //   path: "MGL-corporate/investors/financial-results/subsidiary-financials",
  //   component: SubsidiaryFinancialsComponent,
  //   data: {
  //     name: 'Subsidiary Financials'
  //   }
  // }

  {
    path: "MGL-corporate/investors/financial-results/subsidiary-financials/unison-enviro-private-limited",
    component: UnisonEnviroPrivateLimitedComponent,
    data: {
      name: 'Unison Enviro Private Limited'
    }
  },{
    path: "MGL-corporate/investors/financial-results/subsidiary-financials/mahanagar-lng-private-limited",
    component: MahanagarLNGPrivateLimitedComponent,
    data: {
      name: ' Mahanagar LNG Private Limited'
    }
  },{
    path: "industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection",
    component: ApplyForNewConnectionComponent,
    data: {
      name: 'Apply For New Connection'
    }
  }, {
    path: "how-to-apply",
    component: HowToApplyOnlineComponent,
    data: {
      name: 'How to apply Online'
    }
  }, {
    path: "document-required",
    component: DocumentsRequiredComponent,
    data: {
      name: 'Document Required'
    }
  }, {
    path: "cng/dealer-zone/mobile-unit",
    component: CngMobileRefuelingUnitComponent,
    data: {
      name: 'CNG Mobile Refueling Unit'
    }
  }, {
    path: "MGL-corporate/contracts-and-procurements/tenders/empanelment-notice",
    component: EmpanelMentNoticeComponent,
    data: {
      name: 'Empanelment Notice'
    }
  }, {
    path: 'cng/cng-offers-schemes/cng-up-report',
    component: CngupcomingreportsComponent,
    data: {
      name: 'CNG Upcoming Report'
    }
  }, {
    path: "cng/customer-zone/car-care",
    component: CarcareComponent,
    data: {
      name: 'Car Care'
    }
  }, {
    path: "residential-png/domestic-connection-request/png-rate-card",
    component: PngRateCardComponent,
    data: {
      name: 'PNG Rate Card'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/policies/hse-policy",
    component: HesPolicyComponent,
    data: {
      name: 'Hse Policy'
    }
  }, {
    path: "MGL-corporate/contracts-and-procurements/tenders/archives/empanelment",
    component: EmpanelmentComponent,
    data: {
      name: 'Empanelment'
    }
  }, {
    path: "MGL-corporate/contracts-and-procurements/bidders-on-holiday",
    component: BiddersOfHolidayComponent,
    data: {
      name: 'Bidders on Holiday'
    }
  }, {
    path: "MGL-corporate/investors/financial-results/quarterly-result",
    component: QuarterlyResultComponent,
    data: {
      name: 'Quarterly Result'
    }
  }, {
    path: "MGL-corporate/investors/investor-guide",
    component: InvestorGuideComponent,
    data: {
      name: 'Investor Guide'
    }
  }, {
    path: "MGL-corporate/investors/policies",
    component: PoliciesComponent,
    data: {
      name: 'Policies'
    }
  }, {
    path: "MGL-corporate/investors/corporate-governance",
    component: CorporateGovernanceComponent,
    data: {
      name: 'Corporate Governance'
    }
  }, {
    path: "MGL-corporate/investors/annual-general-meeting",
    component: AnnualGeneralMeetingComponent,
    data: {
      name: 'Annual General Meeting'
    }
  }, {
    path: "MGL-corporate/investors/dividend/dividend-policy",
    component: DividendPolicyComponent,
    data: {
      name: 'Dividend Policy'
    }
  }, {
    path: "MGL-corporate/investors/dividend/unclaimed-dividend",
    component: UnclaimedDividendComponent,
    data: {
      name: 'Unclaimed Dividend'
    }
  }, {
    path: "residential-png/customer-care/feedback-inquiry-form",
    component: FeedbackInquiryComponent,
    data: {
      name: 'Feedback Inquiry Form'
    }
  }, {
    path: "residential-png/if-you-smell-gas",
    component: IfYouSmellGasComponent,
    data: {
      name: 'If You Smell Gas'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/mgl-life-savers",
    component: MglLifeSavingRulesComponent,
    data: {
      name: 'Mgl Life Savers'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/environmental-compliance",
    component: EnvironmentalComplianceComponent,
    data: {
      name: 'Envirnmental Complaince'
    }
  }, {
    path: "residential-png/customer-care/dropbox",
    component: DropboxComponent,
    data: {
      name: 'Dropbox'
    }
  },{
    path: "MGL-corporate/safety-health-and-environment/natural-gas-data-sheet",
    component: NaturalGasDataSheetComponent,
    data: {
      name: 'Natural Gas Data Sheet'
    }
  }, {
    path: "MGL-corporate/Contact-Us/registered-and-corporate-office-address",
    component: RegisteredandcorporateofficeaddressComponent,
    data: {
      name: 'Registered And Corporate Office Address'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/policies/mgl-quality-policy",
    component: MGLQualityPolicyComponent,
    data: {
      name: 'Mgl Quality Policy'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/safety-and-sustainability/safety-as-core-value",
    component: SafetyAsCoreValueComponent,
    data: {
      name: 'Safty As Core Value'
    }
  }, {
    path: "MGL-corporate/asset-integrity",
    component: AssetIntegrityComponent,
    data: {
      name: 'Asset Integrity'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/stc-training",
    component: STCTrainingComponent,
    data: {
      name: 'Stc Training'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/safety-feature",
    component: SafetyFeaturesComponent,
    data: {
      name: 'Safety Feature'
    }
  }, {
    path: "MGL-corporate/media/gallery/video-gallary",
    component: VideogalleryComponent,
    data: {
      name: 'Video Gallery'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/safety-and-sustainability/mgl-spirit",
    component: MglSpiritStmtComponent,
    data: {
      name: 'Mgl Spirit'
    }
  }, {
    path: "MGL-corporate/safety-health-and-environment/safety-and-sustainability/our-approach-to-sustainability",
    component: OurApproachtoSustainabilityComponent,
    data: {
      name: 'Our Approach To Sustainability'
    }
  }, {
    path: "residential-png/policies-and-procedures/aftersales-services/service-provider",
    component: ServiceproviderComponent,
    data: {
      name: 'Service Provider'
    }
  }, {
    path: "residential-png/policies-and-procedures/aftersales-services/png-service-charges",
    component: ChargestobetakenComponent,
    data: {
      name: 'Charges to be taken'
    }
  }, {
    path: "residential-png/customer-zone/name-transfer",
    component: NametranferComponent,
    data: {
      name: 'Name Transfer'
    }
  }, {
    path: "residential-png/customer-zone/name-transfer/apply-for-name",
    component: ApplyfornameComponent,
    data: {
      name: 'Apply for name'
    }
  }, {
    path:"MGL-corporate/investors/investor-update-and-presentation",
    component: InvestorUpdateAndPresentationComponent,
    data: {
      name: 'Investor Update And Presentation'
    }
  }, {
    path:"MGL-corporate/investors/shareholding-pattern",
    component: ShareHoldingPatternComponent,
    data: {
      name: 'Share Holding Pattern'
    }
  }, {
    path:"sitemap",
    component: SitemapComponent,
    data: {
      name: 'Sitemap'
    }
  }, {
    path:"cng/customer-zone/saving-calculator",
    component: CNGsavingcalculatorComponent,
    data: {
      name: 'Saving Calculator'
    }
  }, {
    path:"residential-png/customer-zone/current-bill",
    component: CurrentBillComponent
  }, {
    path:"cng/dealer-zone/apply-for-cng-oulet",
    component: ApplyforcngouletComponent,
    data: {
      name: 'Apply for CNG outlet'
    }
  }, {
    path:"cng/dealer-zone/apply-for-cng-oulet-search",
    component: ApplyoutletstatusComponent,
    data: {
      name: 'Apply for CNG outlet search'
    }
  }, {
    path:"cng/dealer-zone/apply-for-cng-retail-oulet",
    component: ApplyforcngouletnewComponent,
    data: {
      name: 'Apply for CNG retail outlet'
    }
  }, {
    path:"cng/dealer-zone/marathi-apply-for-cng-oulet",
    component: ApplyforcngouletmComponent,
    // data: {
    //   name: 'Apply for CNG outlet'
    // }
  }, {
    path:"cng/dealer-zone/marathi-apply-for-cng-oulet-search",
    component: ApplyoutletstatusmComponent,
    // data: {
    //   name: 'Apply for CNG outlet search'
    // }
  }, {
    path:"cng/dealer-zone/marathi-apply-for-cng-retail-oulet",
    component: ApplyforcngouletnewmComponent,
    // data: {
    //   name: 'Apply for CNG retail outlet'
    // }
  }, {
    path:"residential-png/customer-care/customer-support",
    component:CustomersupportComponent,
    data: {
      name: 'Customer Support'
    }
  }, {
    path: "cng/dealer-zone/login-for-existing-dealers/give-feedback",
    component: CngFeedbackFormComponent,
    data: {
      name: 'CNG Feedback Form'
    }
  }, {
    path: "cng/dealer-zone/login-for-existing-dealers/cng-view-complaint-status",
    component: CngComplaintStatusComponent,
    data: {
      name: 'CNG Complaint Status'
    }
  }, {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    data: {
      name: 'Forgot Password'
    }
  }, {
    path: "cng/customer-zone/cng-locator",
    component: CnglocatorsComponent,
    data: {
      name: 'CNG Locators'
    }
  }, {
    path: "residential-png/view-authorized-personnel",
    component: AuthorizedpersonComponent,
    data: {
      name: 'Autorized Person'
    }
  }, {
    path: "qr-pay",
    component: QrPayComponent,
    data: {
      name: 'Qr Pay'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request",
    component: DomesticConReqFormComponent,
    data: {
      name: 'Domestic Connection Req Form'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Non-gasified",
    component: DomesticConfromNonGasifiedNotAvailComponent,
    data: {
      name: 'Domestic Connection from Non Gasified Not Available'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form_Connlaid",
    component: DomesticConfromNonGasifiedNotAvailIsBeenLaidComponent,
    data: {
      name: 'Domestic Connection From Non Gasified Not Available Is Been Laid'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check",
    component: ConnectionApplicationStatusComponent,
    data: {
      name: 'Connection Application Status'
    }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_institution_check",
    component: ConnectionApplicationInstitutionStatusComponent,
    canActivate: [FrameBlockGuard],
    data: {
      name: 'Connection Application Institution Status'
    }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_csc_check",
    component: ConnectionApplicationCscStatusComponent,
    data: {
      name: 'Connection Application CSC Status'
    }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check_new",
    component: ConnectionApplicationStatusnewComponent,
    data: {
      name: 'Connection Application Status New'
    }
  }, {
    path: "residential-png/customer-zone/name-transfer/name-transfer-status",
    component: TrackNameTransferReqComponent,
    data: {
      name: 'Track Name Transfer Request'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form",
    component: DomesticPngConnGasifiedBulidComponent,
    data: {
      name: 'Domestic PNG Connection Gasified Build'
    }
  }, {
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request-institution/institution_Reg_Form",
    component: DomesticPngConnGasifiedBulidInstitutionComponent,
    data: {
      name: 'Domestic PNG Connection Gasified Build Institution'
    }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/domestic-and-institution-application_check",
    component: CheckbothdomesticapplicationstatusComponent,
    data: {
      name: 'Domestic and Institution Application Status'
    }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Formnew",
    component: DomesticpngconngasifiedbulidnewComponent,
    data: {
      name: 'Domestic PNG Connection Gasified Build new'
    }
  },{
    path: "industrial-and-commercial/contact-us",
    component: ContactUsComponent,
    data: {
      name: 'Contact Us'
    }
  },{
	path:"blog/:id",
	component:BlogarticleComponent,
},{
	path:"MGL-corporate/MGL-sahayogi",
	component:MGLSahayogiDialbeforeDigComponent,
	data: {
      name: 'MGL Sahayogi Dial before Dig'
    }
}, {
  path: "industrial-and-commercial/ic-bill",
  component: IcBillComponent,
  data: {
    name: "Industrial Commercial Bill"
  }
},{
	path:"MGL-corporate/career/current-opp/carriers-job-apply",
	component:CarrierjobapplyComponent,
	data: {
      name: "Carrier job apply"
    }
}, {
  path: "residential-png/policies-and-procedures/refund-policy",
  component: RefundPolicyComponent,
  data: {
      name: "Refund Policy"
    }
},{
	path:"MGL-corporate/investors/other-filings-with-stock-exchanges",
	component:StockExchangeComponent,
	data: {
      name: "Other Filings With Stock Exchanges"
    }
}, {
  path: "nachcancel_commercial",
  component: IandCNachCancellationComponent
},{
  path:"BlogLists",
  component:BloglistsComponent
},{
  path:"MGL-corporate/contracts-and-procurements/tenders/archives/tender",
  component:TenderComponent,
  data: {
      name: "tender"
    }
},
// {
//   path: 'cng/cng-offers-schemes/savings-ki-shubh-shuruaat',
//   component: VehiclepromotionComponent,
//   data: {
//     name: 'Savings Ki Shubh Shuruaat'
//   }
// },
{
  path:"quick-bill-pay",
  component:QuickPayComponent,
  data: {
      name: "Quick Bill Pay"
    }
}, {
	path:"thank-you",
	component:ThankYouComponent
},
// {
//   path: 'cng/cng-offers-schemes/application-status',
//   component: EditVehiclepromotionComponent,
//   data: {
//     name: 'Savings Ki Shubh Shuruaat Application Status'
//   }
// }

{
  path: 'cng/cng-offers-schemes/update-savings-ki-shubh-shuruaat',
  component: EditVehiclepromotionforeditComponent,
  data: {
    name: 'Update Savings Ki Shubh Shuruaat'
  }
},
{
  path: 'cng/cng-offers-schemes/savings-ki-shubh-shuruaat-Brochure',
  component: SavingsKiShubhShuruaatBrochureComponent,
  data: {
    name: 'Savings Ki Shubh Shuruaat Brochure'
  }
},
 {
	path:"view-your-bill",
	component:ViewYourBillComponent,
  data: {
      name: "View Your Bill"
    }
},{
	path:"payment-success/:id",
	component:PaymentSuccessComponent
}, {
	path:"payment-error/:id/:err",
	component:PaymentErrorComponent
},{
	path:"payment-institution-success/:id",
	component:PaymentInstitutionSuccessComponent
}, {
	path:"payment-institution-error/:id/:err",
	component:PaymentInstitutionErrorComponent
},{
	path:"payment-success-test/:id",
	component:PaymentSuccessTestComponent
},{
	path:"payment-error-test/:id/:err",
	component:PaymentErrorTestComponent
},{
	path:"payment-csc-success/:id",
	component:PaymentCscSuccessComponent
}, {
	path:"payment-csc-error/:id/:err",
	component:PaymentCscErrorComponent
},{
	path:"pay-success/:id",
	component:NachpaymentsuccesssComponent
}, {
	path:"pay-error/:id/:err",
	component:NachpaymenterrorComponent
},
{
  path:"thank-you-outlet/:id",
  component:ThankYouOutletComponent
}, {
  path:"thank-you-apply-outlet/:id",
  component:ApplyoutletthankyouComponent
},{
  path:"marathi-thank-you-outlet/:id",
  component:ThankYouOutletmComponent
}, {
  path:"marathi-thank-you-apply-outlet/:id",
  component:ApplyoutletthankyoumComponent
},{
  path: "industrial-and-commercial/qr-pay",
  component: IcQrPayComponent,
  data: {
    name: 'IC Qr Pay'
  }
},
{
  path:"MGL-corporate/award-details/:id",
  component:AwardDetailsComponent,
  data: {
    name: 'award details'
  }
},
{
  path:"MGL-corporate/award/:Year",
  component:AwardsListComponent,
  data: {
    name: 'Award List'
  }
},
{
  path:"cng/customer-zone/mgl-tez",
  component:MgltezComponent,
   data: {
      name: "Mgl Tez"
    }
},{
  path:"MGL-corporate/expression-of-interest",
  component:ExpressionOfInterestComponent,
   data: {
      name: "Expression Of Interest"
    }
},{
  path:"MGL-corporate/contracts-and-procurements-tenders-expression-of-interest",
  component:ExpressionofinterestoneComponent,
   data: {
      name: "Expression Of Interest"
    }
}, {
  path: 'NTDC/:id',
  component: NtdcComponent
}, {
  path: 'NTLC/:id',
  component: NtlcComponent
}, {
  path: 'NTZC/:id',
  component: NtzcComponent
}, {
  path: 'NTZD/:id',
  component: NtzdComponent
},{
  path: 'mr1/:id',
  component: Mr1Component
},{
  path: 'bills/:id',
  component: EbillzprojectComponent
},{
  path: 'mr/:id',
  component: MeterreadingprojectComponent
},{
  path: 'bill/:id',
  component: NexgenprojectComponent
},{
  path: "cng/dealer-zone/login-for-existing-dealers/status-of-open-request",
  component: StatusofopenrequestComponent,
  data: {
    name: 'CNG Open Request'
  }
},{
  path: "cng/dealer-zone/steel-pipe-line-network-layout",
  component: SteelpipelinenetworklayoutComponent,
  data: {
    name: 'Steel Pipe Line Network Layout'
  }
},{
  path:"MGL-corporate/vision",
  component:VisionComponent,
   data: {
    name: 'Vision'
  }
},{
  path:"residential-png/customer-zone/name-transfer/name-transfer-details",
  component:NametransferdetailsComponent,
   data: {
    name: 'Name Transfer Details'
  }
},{
  path:"residential-png/customer-zone/name-transfer/name-transfer-edit",
  component:ApplyfornameEditComponent,
   data: {
    name: "Name Transfer Edit"
  }
},{
    path: 'industrial-and-commercial/industrial-png/industrial-tariff-card',
    component: IndustrialtariffcardComponent,
    data: {
      name: 'Industrial Tariff Card'
    }
  },{
    path: 'industrial-and-commercial/commercial-png/commercial-tariff-card',
    component: CommercialtariffcardComponent,
    data: {
      name: 'Commercial Tariff Card'
    }
  },{
    path: 'reset-password/:id',
    component: ResetPasswordComponent
  },
  {
  path:"MGL-corporate/contracts-and-procurements/tenders/e-tender",
  component:EtendersapsrmComponent,
   data: {
    name: "E Tender"
  }
},{
  path:"cng/dealer-zone/open-plot-near-usar",
  component:OpenplotnearusarComponent,
   data: {
    name: "Open Plot near Usar"
  }
},{
  path:"cng/dealer-zone/open-plot-near-jitegaon",
  component:OpenplotnearjitegaonComponent,
   data: {
    name: "Open Plot near Jitegaon"
  }
},{
  path: "MGL-corporate/csr/csr-project",
  component: CsrprojectComponent,
  data: {
    name: 'Csr Project'
  }
},{
  path: "MGL-corporate/media/newsletters",
  component: NewslettersComponent,
  data: {
    name: 'Newsletters'
  }
},{
  path: "MGL-corporate/media/newsletters-details/:id",
  component: NewslettersdetailsComponent,
  data: {
    name: 'Newsletters Details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-details',
  component: RetrofittersdetailsComponent,
  data: {
    name: 'Retrofitters Details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-login',
  component: RetrofittersloginComponent,
  data: {
    name: 'Retrofitters Login'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-booking-intimation',
  component: RetrofittersleveloneComponent,
  data: {
    name: 'Retrofitters Booking Intimation'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-bill-processing',
  component: RetrofittersleveltwoComponent,
  data: {
    name: 'Retrofitters Bill Processing'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-booking-intimation-status',
  component: RetrofitterslevelonestatusComponent,
  data: {
    name: 'Retrofitters Booking Intimation Status'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-booking-intimation-details',
  component: RetrofitterslevelonedetailsComponent,
  data: {
    name: 'Retrofitters Booking Intimation details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-bill-processing-status',
  component: RetrofittersleveltwostatusComponent,
  data: {
    name: 'Retrofitters Bill Processing Status'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-bill-processing-details',
  component: RetrofittersleveltwodetailsComponent,
  data: {
    name: 'Retrofitters Bill Processing details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-booking-intimation-edit',
  component: RetrofittersleveloneeditComponent,
  data: {
    name: 'Retrofitters Bill Intimation Edit'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-bill-processing-edit',
  component: RetrofittersleveltwoeditComponent,
  data: {
    name: 'Retrofitters Bill Processing Edit'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-booking-intimation',
  component: Retrofitters20leveloneComponent,
  data: {
    name: 'Retrofitters 2.0 Booking Intimation'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-bill-processing',
  component: Retrofitters20leveltwoComponent,
  data: {
    name: 'Retrofitters 2.0 Bill Processing'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-booking-intimation-status',
  component: Retrofitters20levelonestatusComponent,
  data: {
    name: 'Retrofitters 2.0 Booking Intimation Status'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-booking-intimation-details',
  component: Retrofitters20levelonedetailsComponent,
  data: {
    name: 'Retrofitters 2.0 Booking Intimation details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-bill-processing-status',
  component: Retrofitters20leveltwostatusComponent,
  data: {
    name: 'Retrofitters 20 Bill Processing Status'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-bill-processing-details',
  component: Retrofitters20leveltwodetailsComponent,
  data: {
    name: 'Retrofitters 2.0 Bill Processing details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-booking-intimation-edit',
  component: Retrofitters20leveloneditComponent,
  data: {
    name: 'Retrofitters 2.0 Bill Intimation Edit'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters20-bill-processing-edit',
  component: Retrofitters20leveltwoeditComponent,
  data: {
    name: 'Retrofitters 2.0 Bill Processing Edit'
  }
},{
  path: 'cng/cng-offers-schemes/new-cng-vehicle-promotion',
  component: NewCNGVehicleComponent,
  data: {
    name: 'New CNG Vehicle Promotion'
  }
},{
  path: 'cng/cng-offers-schemes/mobile-app/new-cng-vehicle-promotion',
  component: NewCNGVehicleComponent,
  data: {
    name: 'CNG Vehicle Promotion'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitment',
  component: RetrofitmentComponent,
  data: {
    name: 'Retrofitment'
  }
},{
  path: 'cng/cng-offers-schemes/mobile-app/retrofitment',
  component: RetrofitmentComponent,
  data: {
    name: 'Retrofitment App'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitment-faqs',
  component: RetrofitmentFAQsComponent ,
  data: {
    name: 'Retrofitment Faqs'
  }
},{
  path: 'cng/cng-offers-schemes/newvehicle-faqs',
  component: NewvehicleFAQComponent,
  data: {
    name: 'New Vehicle Faqs'
  }
},
// ,{
//   path: 'cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki',
//   component: CngmahotsavmarutisuzukiComponent,
//   data: {
//     name: 'MGL CNG Mahotsav–Maruti Suzuki'
//   }
// },{
//   path: 'cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki-application-status',
//   component: CngmahotsavmarutisuzukiappstatusComponent,
//   data: {
//     name: 'Cng Mahotsav Maruti Suzuki Application Status'
//   }
// }
// ,{
//   path: 'cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki-application-details',
//   component: CngmahotsavmarutisuzukiappdetailsComponent,
//   data: {
//     name: 'Cng Mahotsav Maruti Suzuki Application Details'
//   }
// },{
//   path: 'cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki-application-edit',
//   component: CngmahotsavmarutisuzukiapplicationeditComponent,
//   data: {
//     name: 'Cng Mahotsav Maruti Suzuki Application Edit'
//   }
// },
{
  path: 'cng/cng-offers-schemes/mgl-mahotsav',
  component: MglcngmahotsavComponent,
  data: {
    name: 'Mgl Mahotsav'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki-application-approval',
  component: CngmahotsavmarutisuzukiapprovalComponent,
  data: {
    name: 'Cng Mahotsav Maruti Suzuki Application Approval'
  }
},{
    path:"MGL-corporate/investors/quarterly-corporate-governance",
    component: QuarterlycorporategovernanceComponent,
    data: {
      name: 'Quarterly Corporate Governance'
    }
  },{
	path:"mgl-cng-mahotsav-thank-you/:id",
	component:MarutisuzukithankyouComponent
},{
	path:"mgl-cng-mahotsav20-thank-you/:id",
	component:Marutisuzuki20thankyouComponent
},{
  path: 'residential-png/customer-zone/download-app-link',
  component: DownloadapplinkComponent,
  data: {
    name: 'Download App Link'
  }
},
{
  path: 'cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles',
  component: OemsnewvehiclesComponent,
  data: {
    name: 'MGL CNG Mahotsav–OEMs New Vehicles'
  }
},

{
  path: 'cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-status',
  component: OemsnewvehiclesstatusComponent,
  data: {
    name: 'MGL CNG Mahotsav–OEMs New Vehicles Application Status'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-details',
  component: OemsnewvehiclesdetailsComponent,
  data: {
    name: 'MGL CNG Mahotsav–OEMs New Vehicles Application Details'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-approval',
  component: OemsnewvehiclesapprovalComponent,
  data: {
    name: 'MGL CNG Mahotsav–OEMs New Vehicles Application Approval'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-edit',
  component: OemsnewvehicleseditComponent,
  data: {
    name: 'MGL CNG Mahotsav–OEMs New Vehicles Application Edit'
  }
},{
   path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles',
  component: Oemsnewvehicles20Component,
 data: {
    name: 'MGL CNG Mahotsav-2.0–OEMs New Vehicles'
 }
 },{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-status',
  component: Oemsnewvehicles20statusComponent,
  data: {
    name: 'MGL CNG Mahotsav-2.0–OEMs New Vehicles Application Status'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-details',
  component: Oemsnewvehicles20detailsComponent,
  data: {
    name: 'MGL CNG Mahotsav-2.0–OEMs New Vehicles Application Details'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-approval',
  component: Oemsnewvehicles20approveComponent,
  data: {
    name: 'MGL CNG Mahotsav-2.0–OEMs New Vehicles Application Approval'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-edit',
  component: Oemsnewvehicles20editComponent,
  data: {
    name: 'MGL CNG Mahotsav-2.0–OEMs New Vehicles Application Edit'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-level-two-status',
  component: Oemsnewvehicles20leveltwostatusComponent,
  data: {
    name: 'MGL CNG Mahotsav 2.0 OEMs Level Two Status'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-level-two-details',
  component: Oemsnewvehicles20leveltwodetailsComponent,
  data: {
    name: 'MGL CNG Mahotsav 2.0 OEMs Level Two Details'
  }
},{
  path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oems-level-two-edit',
  component: Oemsnewvehicles20leveltwoeditComponent,
  data: {
    name: 'MGL CNG Mahotsav 2.0 OEMs Level Two Edit'
  }
},{
   path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-retrofitters-dashboard',
  component: Mglcngmaostav20dashboardComponent,
 data: {
    name: 'MGL CNG Mahotsav 2.0 Retrofitters Dashboard'
 }
 },{
   path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oem-dashboard',
  component: Mglcngmaostav20oemdashboardComponent,
 data: {
    name: 'MGL CNG Mahotsav 2.0 OEM Dashboard'
 }
 },{
   path: 'cng/cng-offers-schemes/cng-mahotsav-2.0-oem-faqs',
  component: Oem20faqsComponent,
 data: {
    name: 'MGL CNG Mahotsav 2.0 OEM FAQs'
 }
 },{
  path: 'cng/cng-offers-schemes/oem-login',
  component: OemloginComponent,
  data: {
    name: 'OEM Login'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-status',
  component: RetrofittersappstatusComponent,
  data: {
    name: 'Retrofitters Application Status'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-app-details',
  component: RetrofittersappdetailsComponent,
  data: {
    name: 'Retrofitters Application Details'
  }
},{
  path: 'cng/cng-offers-schemes/retrofitters-app-edit',
  component: RetrofittersappeditComponent,
  data: {
    name: 'Retrofitters Application Edit'
  }
},{
  path: 'residential-png/customer-zone/current-bill-test',
  component: BillingtestComponent,
  data: {
    name: 'Currentbill test'
  }
},{
  path: 'residential-png/customer-zone/generate-your-bill',
  component: GenerateyourbillComponent,
  data: {
    name: 'Generate Your Bill'
  }
},{
    path: 'residential-png/png-offers-schemes/png-scheme-offers',
    component: NationalpngdriveComponent,
    data: {
      name: 'PNG Scheme Offers'
    }
  },{
    path: 'MGL-corporate/media/brochure',
    component: MglcorporatebroucherComponent,
    data: {
      name: 'Brochure'
    }
  },{
	path:"app-pay-success",
	component:ApppayupaymentsuccessComponent
}, {
	path:"app-pay-error",
	component:ApppayupaymenterrorComponent
},{
  path: "create-wallet",
  component: PayuwalletComponent,
  data: {
    name: 'Payu Wallet'
  }
},{
  path: "view-wallet",
  component: PayuwalletbalanceComponent,
  data: {
    name: 'View Payu Wallet'
  }
},{
  path: "view-payu-transaction",
  component: ViewpayutransactionComponent,
  data: {
    name: 'View Payu Transaction'
  }
},{
	path:"wallet-thank-you",
	component:PayuwalletthankyouComponent
},{
	path:"payUpayment-success/:id/:amt",
	component:PayUpaymentsuccessComponent
}, {
	path:"payUpayment-error/:id/:err",
	component:PayUpaymenterrorComponent
},{
	path:"payUunloadpayment-success/:id/:amt",
	component:PayUunloadpaymentsuccessComponent
},{
	path:"payUunloadpayment-error/:id/:err",
	component:PayUunloadpaymenterrorComponent
},{
	path:"quick-test-pay",
	component:PayupaymentsuccessComponent
},{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-test",
    component: ApplyfornametestComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-one",
    component: ApplyfornametsteponeComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-two",
    component: ApplyfornametsteptwoComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-three",
    component: ApplyfornametstepthreeComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-four",
    component: ApplyfornametstepfourComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-one-edit/:id",
    component: ApplyfornametsteponeeditComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-two-edit",
    component: ApplyfornametsteptwoeditComponent,
    
  },{
    path: "residential-png/customer-zone/name-transfer/apply-for-name-step-three-edit",
    component: ApplyfornametstepthreeeditComponent,
    
  },{
	path:"name-transfer-thank-you/:id",
	component:NametransferthankyouComponent
},{
    path: "MGL-corporate/safety-health-and-environment/campaigns",
    component: PledgeComponent,
    data: {
      name: 'Campaigns'
    }
  },{
	path:"pledge-thank-you/:name",
	component:PledgethankyouComponent
},{
	path:"apppayment-success/:id",
	component:ApppaymentSuccessComponent
},{
	path:"apppayment-error/:id/:err",
	component:ApppaymentErrorComponent
},{
    path: "cng/dealer-zone/faqs",
    component: DealerzonefaqsComponent,
    data: {
      name: 'faqs'
    }
  },{
    path: "cng/dealer-zone/list-of-documents-required",
    component: ListofdocumentrequiredComponent,
    data: {
      name: 'List of Documents Required'
    }
  },{
    path: "cng/dealer-zone/process-of-loi-issuance",
    component: ProcessofloiissuanceComponent,
    data: {
      name: 'Process of LOI Issuance'
    }
  },{
    path: 'industrial-and-commercial/commercial-png/faq-list',
    component: CommercialfaqsComponent,
    data: {
      name: 'Commercial FAQS'
    }
  },{
    path: 'industrial-and-commercial/industrial-png/faq-list',
    component: IndustrialfaqsComponent,
    data: {
      name: 'Industrial FAQS'
    }
  },{
    path: "cng/cng-offers-schemes/list-of-mgl-cng-stations-accepting-mgl-fuel-card",
    component: ListofmglcngstationsComponent,
    data: {
      name: 'List of MGL CNG Stations accepting MGL Fuel Card'
    }
  },{
    path: "name-transfer/apply-for-name-app",
    component: ApplyfornameforappComponent,
    // data: {
    //   name: 'Apply for name'
    // }
  },{
    path: "name-transfer/apply-for-name-step-one-for-app",
    component: ApplyfornametsteponeforappComponent,
    
  },{
    path: 'residential-png/png-offers-schemes/khushiyan-lakhon-ki',
    component: KhushiyanlakhonkiiComponent,
    data: {
      name: 'Khushiyan Lakhon Ki'
    }
  },{
    path: 'residential-png/png-offers-schemes/khushiyan-lakhon-ki/winners-list',
    component: WinnerslistComponent,
    data: {
      name: 'Winner’s List'
    }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/csc_Reg_Form/:code/:state",
    component: DomesticPngConnGasifiedBulidCscComponent,
    // data: {
    //   name: 'Domestic PNG Connection Gasified Build'
    // }
  },{
    path: "residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/csc_Reg_Form_apply",
    component: DomesticPngConnGasifiedBulidCscApplyComponent,
    // data: {
    //   name: 'Domestic PNG Connection Gasified Build'
    // }
  },{
    path: "MGL-corporate/investors/scheme-of-merger",
    component: SchemeofmergerComponent,
    data: {
      name: 'Scheme of Merger'
    }
  },{
    path: "MGL-corporate/investors/moa-and-aoa",
    component: MoaandaoaComponent,
    data: {
      name: 'MOA and AOA'
    }
  },{
    path: "MGL-brochures-and-sm",
    component: MglBrochuresAndSmComponent,
    data: {
      name: 'MGL Brochures and SM'
    }
  },{
    path: "MGL-corporate/investors/regulation-46-of-sebi",
    component: RegulationOfSebiComponent,
    data: {
      name: 'Regulation 46 of SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015'
    }
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
