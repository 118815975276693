import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  UserForm!:FormGroup

  Submitted:boolean=false

  captcha:any

  constructor(private pageLoaderService: PageLoaderService,private FormBuilder : FormBuilder,private Service:FormServiceService) { }

  ngOnInit(): void {


    this.captchaGenerate()

    this.UserForm = this.FormBuilder.group({
      Name:['',[Validators.required]],
      Email:['',[Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      Contact:['',[Validators.required, Validators.pattern(/[0-9]{10}/)]],
      Subject:['',[Validators.required]],
      Message:['',[Validators.required]],
      Captcha:['',[Validators.required]]
    })

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }


  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }


  submit(){
    this.Submitted = true


    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
	this.captchaGenerate();

    if(this.UserForm.invalid){
      return
    }

    let obj = {
      "Name":this.UserForm.get('Name')?.value,
      "Email":this.UserForm.get('Email')?.value,
      "Contact":this.UserForm.get('Contact')?.value,
      "Subject":this.UserForm.get('Subject')?.value,
      "Message":this.UserForm.get('Message')?.value,
    }


    this.Service.postContactForm(obj).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.Response){
        this.alertType='submit'
        this.alertMsg = "Submitted"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        

        setTimeout(() => {
          this.alertType=''
          this.UserForm.reset()
          this.Submitted=false
        }, 1000);
      }
    })
  }

}
