import { Component } from '@angular/core';

@Component({
  selector: 'app-winnerslist',
  templateUrl: './winnerslist.component.html',
  styleUrls: ['./winnerslist.component.css']
})
export class WinnerslistComponent {

}
