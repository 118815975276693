<!-- Inner Page Content Section Start -->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Online <span>Payment</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a >Customer Zone</a></li>
                <li class=""><a >Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
        
                <li class="active" aria-current="page">Online Payment</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>


            </div>


            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">

                            <div class="customer-login-page">

                                <div class="log-meter-text">
                                  <h6 class="text-center">Pay Your Bill</h6>
                                </div>

                                <div  class="row">
                                    <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                        <a href="https://paytm.com/gas-bill-payment/Mahanagar-Gas--Mumbai" target="_blank">
                                            <img src="assets/images/paytm image.jpg"  />
                                        </a>
                                    </div> -->
                                    
                                   <!--  <div class="col-lg-6 col-md-6 col-sm-12">
                                        <a href="https://payments.billdesk.com/MercOnline/mglBillPay/mgl_billpayment.html" target="_blank">
                                            <img src="assets/images/billdesk image.jpg"/>
                                        </a>
                                    </div> -->
                                     <div class="col-lg-6 col-md-6 col-sm-12 mx- mx-auto">
                                        <a href="" onclick="return false;" (click)="getSessionData()" target="_blank" class="pay-bill-deskbtn">
                                            <img src="assets/images/billdesk image.jpg"/>
                                        </a>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>

