import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-oemsnewvehicles20details',
  templateUrl: './oemsnewvehicles20details.component.html',
  styleUrls: ['./oemsnewvehicles20details.component.css']
})
export class Oemsnewvehicles20detailsComponent {
 previouspageData:any;
  previouspageData1:any;
   oemLoginRole:any;
    oemEmail:any;
  constructor(private pageLoaderService: PageLoaderService, 
    private activatedRoute: ActivatedRoute,public router: Router,private UserService : UserRegistrationService) { 
      this.activatedRoute.queryParams.subscribe((data: any) => {
     //   console.log(data);
        this.previouspageData = JSON.parse(data.data);
        this.previouspageData1 = this.previouspageData.details;
       // console.log(this.previouspageData1);
        // if (data && data.id) {
        //   this.mobileNumber = data.mobile_no;
        // }
      });
      
    }

  ngOnInit(): void {
  
   this.UserService.checkLogin2()
     
       // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential2')))
    let data = '';

     let newData = sessionStorage.getItem('UserCredential2');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    
     if (data) {
      let oemLogindata = JSON.parse(data);

      let oemData = oemLogindata.details
     this.oemLoginRole = oemData.Role;
     this.oemEmail = oemData.Email;
     //console.log('details data',this.oemLoginRole)
    }

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

  }

   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  gotoLevelSec(){
     this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-approval'],
        {queryParams:{data:JSON.stringify(this.previouspageData1)}})
  }

    gotoEdit(){
     this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-edit'],
        {queryParams:{data:JSON.stringify(this.previouspageData1)}})
  }

}
