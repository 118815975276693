import { Component } from '@angular/core';
import { MenuService } from 'src/app/Service/menu.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';;

@Component({
  selector: 'app-mgl-brochures-and-sm',
  templateUrl: './mgl-brochures-and-sm.component.html',
  styleUrls: ['./mgl-brochures-and-sm.component.css']
})
export class MglBrochuresAndSmComponent {
allData:any[]=[]
  baseUrl=environment.baseUrl
  constructor(private Service:MenuService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getPdf()
  }

  getPdf(){
    this.Service.getPdfByCategory({PdfCategory:'E-Tender(SAP-SRM)'}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      this.allData=data
    })
  }
}
