import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { logcomplaint } from 'src/app/Service/logcomplaint.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-statusofopenrequest',
  templateUrl: './statusofopenrequest.component.html',
  styleUrls: ['./statusofopenrequest.component.css']
})
export class StatusofopenrequestComponent {

  OutletName: any[] = []
  feedback:any[]=[]

  Feedbackdetail:any[]=[]

  FinalFeedBack:any={}

  fuelType: string[] = []
  UserForm!: FormGroup
  submitted: boolean = false;
  data1 : any;

  constructor(private UserService : UserRegistrationService,private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder,private Service : logcomplaint) { }

  ngOnInit(): void {
    this.UserService.checkLogin()

   
   this.getSessionData()
    this.fuelType = ['Cat1', 'Cat2', 'Cat3', 'Cat4']

    this.UserForm = this.FormBuilder.group({
      DocketNo: ['', Validators.required],
      tpye_of_request: ['', Validators.required],
      Remark: ['', Validators.required],
      Status: ['', Validators.required],
      ComplaintDate: ['', Validators.required],
      
      })


    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  getSessionData(){
    // let data = sessionStorage.getItem('UserCredential')
     let data = ''
     let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    if(data)
    this.data1=JSON.parse(data)
    this.getAllFeedback()
  }
  getAllFeedback(){
   // console.log(this.data1)
    this.Service.get_All_cng_complaint_statuses({BPNo:this.data1.BpNo}).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.feedback = data
      //console.log(this.feedback)
    })

  }

  getFeedbackComplaintIdByOutlet(val:any){
    this.Feedbackdetail = this.feedback.filter((x:any)=>{
      return x.DocketNo == val

      
    })

    
  }

  getFeedBackDetailById(Id:any){

    this.FinalFeedBack = this.feedback.filter((x:any)=>{
      return x.Complaint == Id
    })
    var date = new Date();
    
     this.UserForm.get('ComplaintDate')?.setValue(date.toISOString().substring(0, 10))
     this.UserForm.get('Remark')?.setValue(this.FinalFeedBack[0].Remark)
     this.UserForm.get('Status')?.setValue(this.FinalFeedBack[0].Status)

    // console.log(this.UserForm.value)

  }


  getAllOutlet() {
    this.Service.getAllOutlet().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.OutletName = data
     // console.log(this.OutletName)
      this.OutletName.sort(function(a,b){
        if(a.OutletName< b.OutletName) return -1;
        if(a.OutletName >b.OutletName) return 1;
        if(a.OutletName< b.OutletName) return -1;
        if(a.OutletName >b.OutletName) return 1;
        return 0;
      });
    })
  }


  reset() {
    this.fuelType = []
    this.UserForm.reset()
    this.submitted = false
    setTimeout(() => {
      this.fuelType = ['Cat1', 'Cat2', 'Cat3', 'Cat4']
    }, 200);
  }

  submit() {
    this.submitted = true

    if (this.UserForm.invalid) {
      return
    }
  }

}

