<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <!-- <h1>Application for CNG Retail Outlets
        </h1> -->
        <h1>The Process for issuance of letter of intent(LOI)
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class="active" aria-current="page"><a href="#">The Process for issuance of letter of intent(LOI)

                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <!-- <app-recent-post></app-recent-post>

                <app-tips></app-tips> -->

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
               <!--  <div class="light-blue-bg">
                    <div class="row"> -->
                        
                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="applyoutletForm">
                                 <div class="light-blue-bg mt-10">
                                            <p><b>DETAILS OF APPLICANT :</b></p>
                            	
                                <div class="row">
                                	 <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeofapplicant'].errors}" (change)="getApplicanttype()">
                                                <option value="" selected>Select Type of applicant </option>
                                                <option value="Individual">Individual</option>
                                                <option value="Registered Firm">Registered Firm</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeofapplicant'].errors">Required Field</div>
                                            </div>
                                            <label for="typeofapplicant">Type of applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group" *ngIf="applicantIndividualFlag==true">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="numberofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['numberofapplicant'].errors}" (change)="getApplicantNumber()">
                                                <option value="" selected>Select Number of Applicants</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['numberofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['numberofapplicant'].errors">Required Field</div>
                                            </div>
                                            <label for="numberofapplicant">Select Number of Applicants<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    
                                       <div class="col-sm-6 form-group" *ngIf="firstFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="firstapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['firstapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['firstapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['firstapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="secondFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="secondapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['secondapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['secondapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['secondapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="thirdFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="thirdapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['thirdapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['thirdapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['thirdapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="fourthFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="fourthapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['fourthapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['fourthapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['fourthapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="fiveFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="fifthapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['fifthapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['fifthapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['fifthapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="sixFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="sixapplicant"
                                                placeholder="Name of the Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['sixapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['sixapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['sixapplicant'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="nameoffirm"
                                                placeholder="Name of the Firm"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['nameoffirm'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['nameoffirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['nameoffirm'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of the Firm<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeoffirm"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeoffirm'].errors}" (change)="getfirmtype()">
                                                <option value="" selected>Select type of Firm </option>
                                                <option value="Proprietorship">Proprietorship</option>
                                                <option value="Partnership">Partnership</option>
                                                <option value="Private Limited">Private Limited</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeoffirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeoffirm'].errors">Required Field</div>
                                            </div>
                                            <label for="typeoffirm">Select type of Firm<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group" *ngIf="othertypeFlag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="otherfirm"
                                                placeholder="Other Type of Firm"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['otherfirm'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['otherfirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['otherfirm'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Other Type of Firm<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="light-blue-bg mt-10">
                                            <p><b>DETAILS FOR COMMUNICATION :</b></p>

                                    <div class="row">

                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Name of Applicant"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Name">Name of Applicant<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="ContactNumber"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" />
                                            <div *ngIf="submitted && applyoutletForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors?.pattern">
                                                    invalid Contact Number </div>
                                            </div>
                                            <label for="ContactNumber">Contact Number<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Email'].errors.required">Required
                                                    Field
                                                </div>
                                                 <div *ngIf="applyoutletForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                            <label for="Email">Email</label>
                                            <!-- <label for="Email">Email<span style="color: crimson;">*</span></label> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Correspondence_Address"
                                                placeholder="Correspondence_Address"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Correspondence_Address'].errors }"
                                                id="Correspondence_Address" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Correspondence_Address'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Correspondence_Address'].errors.required">Required
                                                    Field
                                                </div>
                                                 
                                            </div>
                                            <label for="Correspondence_Address">Correspondence Address<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     </div>
                                      </div>

                                       <div class="light-blue-bg mt-10">
                                            <p><b>DETAILS OF LAND OFFERED :</b></p>

                                      <div class="row">


                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofPlotOwner"
                                                placeholder="NameofPlotOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofPlotOwner'].errors }"
                                                id="NameofPlotOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofPlotOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['NameofPlotOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofPlotOwner">Name of the Plot Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="lease_tenure_years"
                                                placeholder="lease_tenure_years"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['lease_tenure_years'].errors }"
                                                id="lease_tenure_years" />
                                            <div *ngIf="submitted && applyoutletForm.controls['lease_tenure_years'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['lease_tenure_years'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="lease_tenure_years">Lease tenure years<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                  <!--   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control"
                                                formControlName="RelationshipofAppOwner"
                                                placeholder="RelationshipofAppOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors }"
                                                id="RelationshipofAppOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['RelationshipofAppOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="RelationshipofAppOwner">Relationship of Applicant Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Addressofplot"
                                                placeholder="Addressofplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Addressofplot'].errors }"
                                                id="Addressofplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Addressofplot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Addressofplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Addressofplot">Address of plot</label>
                                            
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="SurveyNo"
                                                placeholder="SurveyNo"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['SurveyNo'].errors }"
                                                id="SurveyNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['SurveyNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['SurveyNo'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="SurveyNo">Survey No/ CTS No.<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="District"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['District'].errors}">
                                                <option value="" selected>Select District </option>
                                                <option *ngFor="let DistrictItem of district "
                                                    value={{DistrictItem.District}}>{{DistrictItem.District}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['District'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['District'].errors">Required Field</div>
                                            </div>
                                            <label for="District">District<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="PinCode"
                                                placeholder="PinCode"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['PinCode'].errors }"
                                                id="PinCode" />
                                            <div *ngIf="submitted && applyoutletForm.controls['PinCode'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors?.pattern">invalid
                                                    pincode
                                                </div>
                                            </div>
                                            <label for="PinCode">PinCode<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="AreaofthePlot"
                                                placeholder="AreaofthePlot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['AreaofthePlot'].errors }"
                                                id="AreaofthePlot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['AreaofthePlot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['AreaofthePlot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="AreaofthePlot">Area of plot for CNG (in Sq. M.)<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                         <label class="font-size11 mt-1">If the plot is on NH/SH Min. 35m Frontage and 1225 sq.m. area required</label>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Frontageoftheplot"
                                                placeholder="Frontageoftheplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Frontageoftheplot'].errors }"
                                                id="Frontageoftheplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Frontageoftheplot'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['Frontageoftheplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Frontageoftheplot">Frontage of the plot<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Depth"
                                                placeholder="Depth"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Depth'].errors }"
                                                id="Depth" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Depth'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Depth'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Depth">Depth<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Latitude"
                                                placeholder="Latitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Latitude'].errors }"
                                                id="Latitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Latitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Latitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            
                                            <label for="Latitude">Latitude</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Longitude"
                                                placeholder="Longitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Longitude'].errors }"
                                                id="Longitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Longitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Longitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                           
                                                    <label for="Longitude">Longitude</label>
                                        </div>
                                    </div> -->
                                  <!--   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Ownership_of_Land"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Ownership_of_Land'].errors}">
                                                <option value="" selected>Select Ownership of Land </option>
                                                <option value="Owned">Owned</option>
                                                <option value="Leased">Leased</option>
                                                <option value="Will be Leased/Purchased">Will be Leased/Purchased</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Ownership_of_Land'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Ownership_of_Land'].errors">Required Field</div>
                                            </div>
                                            <label for="Ownership_of_Land">Ownership of Land<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofRoad"
                                                placeholder="NameofRoad"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofRoad'].errors }"
                                                id="NameofRoad" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofRoad'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['NameofRoad'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofRoad">Name of Road<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Plot"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Plot'].errors}">
                                                <option value="" selected>Select Plot</option>
                                                <option *ngFor="let PlotItem of plot " value={{PlotItem.Plot}}>
                                                    {{PlotItem.Plot}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Plot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Plot'].errors"> Required Field
                                                </div>
                                            </div>
                                            <label for="Plot">Plot<span style="color: crimson;">*</span></label>
                                        </div>

                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Additionalremarks"
                                                placeholder="Additionalremarks" id="Additionalremarks" />
                                            <label for="Plot">Additional remarks</label>
                                        </div>

                                    </div> -->

                                </div>
                            </div>

                            <div class="light-blue-bg mt-10">
                                            <p><b>UNDERTAKING :</b></p>

                                            <div class="mb-25 apply-for-cng-outlet">
                                  
                                        <ol class="number-list">
                                        <li> I/We understand that after carrying out the detailed techno-commercial survey, MGL at its sole discretion may select the suitable plots for setting up of CNG Retail Outlet.</li>
                                        <li> I/We understand that once the Letter of Intent (LOI) is issued, then subsequently I/ We shall have to enter into various agreements as per the prevailing MGL policy.</li>
                                        <li>I/We understand that there may be other applications on the same stretch and MGL will award LOI basis merit of the application.</li>
                                         <li>I/We undertake to produce supporting documents as and when required by MGL, failing which my application will be dis-qualified.</li>
                                          <li>Demand Draft / Pay Order No. --- dated -- for Rs. 5,90,000/- (Five Lakhs Ninety Thousand only) inclusive of GST drawn on -- (Bank’s name) towards administrative fees in favor of Mahanagar Gas Limited is enclosed. I fully understand that this is a non-refundable amount once Letter of Intent (LOI) is issued by MGL.</li>
                                          <li>The data asked in this Application form is purely for collecting prima facie information to evaluate the application for setting up MGL CNG Retail Outlet. Submission of this form does not guarantee an award of LOI for CNG Retail Outlet.</li>
                                           <li>I/We understand that incomplete forms in all respects may be rejected</li>
                                          <!--  <li>I/We, the undersigned hereby, certify that the information given above is true to the best of my/our knowledge and belief. Any wrong information / suppression of facts will disqualify me from being considered for issuance of LOI for CNG Retail Outlet.</li> -->
                                           <li>
                                            <div class="row service-label">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <input formControlName="accept_term" type="checkbox" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['accept_term'].errors }">
                                        <div *ngIf="submitted && applyoutletForm.controls['accept_term'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['accept_term'].errors">Please tick on checkbox</div>
                                            </div>
                                        <label>
                                            <a  class="terms-label">I/We, the undersigned hereby, certify that the information given above is true to the best of my/our knowledge and belief. Any wrong information / suppression of facts will disqualify me from being considered for issuance of LOI for CNG Retail Outlet.</a></label>
                                           
                                    </div>

                                </div>
                            </div>
                        </li>
                                        
                                    </ol>
                                </div>

                                 <div class="row">
                                     
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="orderNo"
                                                placeholder="Demand Draft / Pay Order No."
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['orderNo'].errors }"
                                                id="orderNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['orderNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['orderNo'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="orderNo">Demand Draft / Pay Order No.<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="orderNodate"
                                                placeholder="Demand draft date"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['orderNodate'].errors }"
                                                id="orderNodate" />
                                            <div *ngIf="submitted && applyoutletForm.controls['orderNodate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['orderNodate'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="orderNodate">Demand Draft / Pay Order date<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="bank_name"
                                                placeholder="bank_name"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['bank_name'].errors }"
                                                id="bank_name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['bank_name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['bank_name'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="bank_name">Bank's name<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="light-blue-bg mt-10">
                                            <p><b>DOCUMENTS ANNEXED WITH THE APPLICATION FORM :</b></p>

                                    <div class="row">
                                    <p><b><i> You can upload only .doc,.docx,.pdf file format and file size within 5
                                                MB</i></b></p>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size111">Undertaking from Landowner/ Registered Lease Agreement<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'Undertaking')"
                                                    class="from-control" type="file"
                                                    formControlName="under_taking_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['under_taking_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['under_taking_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['under_taking_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="under_taking_doc">Undertaking from Landowner/ Registered Lease Agreement<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                         <label class="font-size111">Architect Layout<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'architect')"
                                                    class="from-control" type="file"
                                                    formControlName="architect_layout_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['architect_layout_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['architect_layout_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['architect_layout_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                        <!-- <label class="font-size11 mt-1" for="architect_layout_doc">Architect Layout<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size111">ID proof of Applicant(s) (Aadhaar Card / PAN Card / Any Govt. ID; any one)<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'idproof')"
                                                    class="from-control" type="file"
                                                    formControlName="id_proof_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['id_proof_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['id_proof_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['id_proof_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                      <!--   <label class="font-size11 mt-1" for="id_proof_doc">ID proof of Applicant(s) (Aadhaar Card / PAN Card / Any Govt. ID; any one)<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                         <label class="font-size111">Demand Draft<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'demanddraft')"
                                                    class="from-control" type="file"
                                                    formControlName="demand_draft_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['demand_draft_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['demand_draft_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['demand_draft_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="demand_draft_doc">Demand Draft<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                         <label class="font-size111">Please attach any one supporting documents - Incorporation Certificate or GST Certificate or PAN Card or Partnership Deed etc<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'incorporation')"
                                                    class="from-control" type="file"
                                                    formControlName="incorporation_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['incorporation_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['incorporation_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['incorporation_doc'].errors">
                                                    Required Field</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="incorporation_doc">Please attach any one supporting documents - Incorporation Certificate or GST Certificate or PAN Card or Partnership Deed etc<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 


                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div (copy)="false" class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && applyoutletForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="applyoutletForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="onSubmit1()" class="greenbtn2">Save as Draft<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                    <div *ngIf="alertType == 'submit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                                 </div>
                            </form>
                        </div>
                   <!--  </div>
                </div> -->
            </div>
        </div>
    </div>
</div>