import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  alertMsg:any=''
  alertColor : string=''
  alertTextColor : string=''
  alertBorder : string=''

  UserForm !: FormGroup
  data: any
  submitted: boolean = false

  constructor(private FormBuilder: FormBuilder, public FaqService: FaqServiceService, private service: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.service.checkLogin()

    this.UserForm = this.FormBuilder.group({
      OldPass: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]],
      NewPass: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]],
      ConfPass: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]],
      BpNo: [''],
      CaNo: [''],
      MobileNo: [''],
      Email: [''],
    });

  }

  clearStatus(){
    setTimeout(()=>{
      this.alertMsg=''
    },3000)
  }

  submit() {
    this.submitted = true
    //console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    if (this.UserForm.get('NewPass')?.value === this.UserForm.get('ConfPass')?.value) {
      let data = sessionStorage.getItem('UserCredential')
      if (data) {
        this.data = JSON.parse(data)
      }
      this.UserForm.get('BpNo')?.setValue(this.data.BpNo)
      this.UserForm.get('CaNo')?.setValue(this.data.CaNo)
      this.UserForm.get('MobileNo')?.setValue(this.data.Mobile)
      this.UserForm.get('Email')?.setValue(this.data.Email)

    //   let obj ={'BpNo':this.data.BpNo,'CaNo':this.data.CaNo,
    //   'MobileNo':this.data.Mobile,'Email':this.data.Email,
    // 'NewPass':this.UserForm.get('NewPass')?.value,'ConfPass':this.UserForm.get('ConfPass')?.value}

     var  convertBPno = crypto.AES.encrypt(this.data.BpNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertCAno = crypto.AES.encrypt(this.data.CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertMobileNo = crypto.AES.encrypt(this.data.Mobile, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertEmail = crypto.AES.encrypt(this.data.Email, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertNewPass = crypto.AES.encrypt(this.UserForm.get('NewPass')?.value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertConfPass = crypto.AES.encrypt(this.UserForm.get('ConfPass')?.value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertOldPass = crypto.AES.encrypt(this.UserForm.get('OldPass')?.value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     

      let obj ={'BpNo':convertBPno,'CaNo':convertCAno,
      'MobileNo':convertMobileNo,'Email':convertEmail,
    'NewPass':convertNewPass,'ConfPass':convertConfPass,
    'OldPass':convertOldPass}
    
      // this.service.ChangePass(obj).subscribe(response=>{
     this.service.changeUserPass_New(obj).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response))

        //console.log(response)
        this.alertMsg =data.message
        if(!data.Response){
          this.alertColor='rgba(255, 94, 109, 0.2)'
          this.alertTextColor='#c24753'
          this.alertBorder='1px solid #eb5664;'
          this.clearStatus()
        }else{
          this.alertColor='rgba(4, 183, 107, 0.2)'
          this.alertTextColor='#038b51'
          this.alertBorder='1px solid #04a862;'
          this.UserForm.reset()
          this.submitted=false
          this.clearStatus()
        }
        
      })
    }else{
      this.alertMsg="New Password And Confirm Password is not Matching"
      this.alertColor="rgba(255, 94, 109, 0.2)"
      this.alertTextColor='#c24753'
      this.alertBorder='1px solid #eb5664;'
      this.clearStatus()
    }


  }
}
