import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-domestic-png-conn-gasified-bulid-csc-apply',
  templateUrl: './domestic-png-conn-gasified-bulid-csc-apply.component.html',
  styleUrls: ['./domestic-png-conn-gasified-bulid-csc-apply.component.css']
})
export class DomesticPngConnGasifiedBulidCscApplyComponent {

   constructor(private pageLoaderService: PageLoaderService) { }

   ngOnInit(): void {
     this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

//   const current = new Date();
// const timestamp = current.getTime();
// // console.log(timestamp)
//     window.open("https://connectuat.csccloud.in/account/authorize?state="+timestamp+"&response_type=code&client_id=aceca683-ca32-4aa0-d8f9-42f36d3e6795&redirect_uri=https://www.mahanagargas.com:3000/transaction/csc_response", "_self");

   }

   gotoApply(){

//       const current = new Date();
// const timestamp = current.getTime();
// // console.log(timestamp)
//     window.open("https://connectuat.csccloud.in/account/authorize?state="+timestamp+"&response_type=code&client_id=aceca683-ca32-4aa0-d8f9-42f36d3e6795&redirect_uri=https://www.mahanagargas.com:3000/transaction/csc_response", "_self");

      const current = new Date();
const timestamp = current.getTime();
// console.log(timestamp)
    window.open("https://connect.csc.gov.in/account/authorize?state="+timestamp+"&response_type=code&client_id=20efbaa6-3e70-43fd-9327-a0bc93d00df7&redirect_uri=https://www.mahanagargas.com:3000/transaction/csc_response", "_self");

   }

}
