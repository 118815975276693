<!-- <div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Apply for Name Transfer
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a>Home</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Apply for Name Transfer
                </li>
            </ul>
        </div>
    </div>
</div> -->


<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                

               

           </div>




            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

                            <div *ngIf="!hideValidation" class="customer-login-page log-meter-reading 0">
                                <div class="mb-20">
                                    <strong><i>* Kindly enter BP or CA number as appearing on your bill. </i></strong>

                                </div>
                                <form [formGroup]="ValidationForm">
                                    <div class="row">


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly type="number" class="form-control" formControlName="BPnoCAno"
                                                    onKeyPress="if(this.value.length==12) return false;"
                                                    placeholder="BPnoCAno"
                                                    [ngClass]="{ 'is-invalid': submitted && ValidationForm.controls['BPnoCAno'].errors }"
                                                    id="BPnoCAno" name="name"/>
                                                <div *ngIf="submitted && ValidationForm.controls['BPnoCAno'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="ValidationForm.controls['BPnoCAno'].errors.required">
                                                        Required Field</div>

                                                </div>
                                                <label for="Captcha">Enter BP No/CA No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>







                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly type="number" class="form-control" formControlName="MobileNo"
                                                    onKeyPress="if(this.value.length==10) return false;"
                                                    placeholder="MobileNo"
                                                    [ngClass]="{ 'is-invalid': submitted && ValidationForm.controls['MobileNo'].errors }"
                                                    id="MobileNo" />
                                                <div *ngIf="submitted && ValidationForm.controls['MobileNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="ValidationForm.controls['MobileNo'].errors.required">
                                                        Required Field</div>
                                                    <div *ngIf="ValidationForm.controls['MobileNo'].errors.pattern">
                                                        Mobile No should be in 10 digits.</div>
                                                </div>
                                                <label for="Captcha">Mobile No<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                   <!--  <div *ngIf="!ShowGo" class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button s (click)="submit()" class="greenbtn2">GO<i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                    </div> -->


                                    <div *ngIf="alertType == 'firstSubmit'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <span *ngIf="!hideValidation">
                                <div class="mt-30 mb-10">
                                    <strong> Note :</strong><br>Please keep following documents for ‘Name Transfer’ process :
                                </div>
                                <div class="number-list">
                                    <ol class="">
                                        <li>Documents for Transfer of PNG connection.</li>
                                        <li>Photo Identity proof.</li>
                                        <li>NOC from previous owner.</li>
                                        <li>Current Meter Reading.</li>
                                    </ol>
                                </div>
                                <a _ngcontent-ued-c165="" href="assets/images/No-Objection-Certificate-Format.pdf"
                                    target="_Blank" class="bluebtn2">Click here to download the NOC format
                                    <i _ngcontent-ued-c165="" class="demo-icon uil-download-alt"></i></a>



                            </span>


                            <div *ngIf="ShowUserForm" class="customer-login-page log-meter-reading">
                                <p class="mt-30"><b><i>* Existing User Information</i></b></p>

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <label>Name</label>
                                        <div class="mb-20 cu-login-input2 mt-10">
                                            <!-- <i class="demo-icon uil-clipboard-notes"></i> -->


                                            <input disabled value="{{UserData.Name}}" class="from-control" type="text"
                                                id="name" name="name">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <label>Current Outstanding Amount</label>
                                        <div class="mb-20 cu-login-input2 mt-10">
                                            <!-- <i class="demo-icon uil-clipboard-notes"></i> -->



                                           <!--  <input disabled value="NIL" class="from-control" type="text" id="name"
                                                name="name"> -->
                                                <input *ngIf="OutstandingAmt1!='NIL'" disabled value="₹ {{OutstandingAmt1}}" class="from-control" type="text" id="name"
                                                name="name">
                                                <input *ngIf="OutstandingAmt1=='NIL'" disabled value="{{OutstandingAmt1}}" class="from-control" type="text" id="name"
                                                name="name">
                                        </div>
                                    </div>
                                     <div class="mt-20" *ngIf="OutstandingAmt1!='NIL'">
                                        <div class="alert alert-danger"><b> Note : Current outstanding amount to be cleared for successful Name Transfer.</b></div>
                                        </div>


                                </div>
                                <div class="row">
                                     <div class="col-6 col-lg-6 col-md-6 col-sm-6" *ngIf="paybuttonFlag==false">
                                        <button [routerLink]="['/qr-pay']"
                                            class="greenbtn2">Confirm to Payment<i class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                 
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6" *ngIf="paybuttonFlag!=false">
                                        <button (click)="gotoStepone()" class="greenbtn2">Confirm<i class="demo-icon uil-arrow-right"></i></button>
                                    </div>

                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                        <button
                                            (click)="this.router.navigate(['/residential-png/customer-zone/name-transfer'])"
                                            class="greybtn">Cancel<i class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

                






            </div>
        </div>
    </div>
</div>


<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            * Kindly enter OTP as appearing on your Mobile.
                        </div>
                        <form [formGroup]="checkOtpForm">
                            <div class="row">



                                <div class="col-sm-12 form-group">
                                    <div class="form-floating">
                                        <input type="number" class="form-control" formControlName="Otp" placeholder="Otp"
                                            [ngClass]="{ 'is-invalid': OtpSubmitted && checkOtpForm.controls['Otp'].errors }"
                                            id="Otp" />
                                        <div *ngIf="OtpSubmitted && checkOtpForm.controls['Otp'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="checkOtpForm.controls['Otp'].errors.required">Required Field
                                            </div>
                                        
                                        </div>
                                        <label for="Captcha">Enter OTP<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button [disabled]="submitStatus" (click)="verifyOtp()" class="greenbtn2">Submit
                                        OTP<i class="demo-icon uil-arrow-right"></i></button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button [disabled]="OtpButtonActive" greybtn (click)="OtpGenerate()"
                                        class="{{OtpButtonActive ? 'greybtn' :'greenbtn2' }}">{{OtpButtonActive ?
                                        this.Minute+":"+this.Sec:
                                        "Resend OTP"}}<i class="demo-icon uil-arrow-right"></i></button>
                                </div>
                            </div>


                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>