<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Be a part of MGL’s Digital Transformation and Go Green<span> </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <!-- <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Contracts & Procurements</a></li> -->
                <li class="active" aria-current="page">Be a part of MGL’s Digital Transformation and Go Green</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                   
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   Mahanagar Gas Corporate Brochure
                                </div>
                                <a href="assets/images/MGLbrochuresandsm/Mahanagar Gas_Corporate Brochure.pdf" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    MGL PNG Khushiyan Lakhon Ki
                                </div>
                                <a href="https://www.mahanagargas.com/residential-png/png-offers-schemes/khushiyan-lakhon-ki" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   MGL CNG Mahotsav 2.0
                                </div>
                                <a href="https://www.mahanagargas.com/cng/cng-offers-schemes/mgl-mahotsav" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    MGL CNG Retail Outlet Brochure
                                </div>
                                <a href="assets/images/MGLbrochuresandsm/MGL CNG Station Retail outlet_.pdf" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Maha LNG Brochure
                                </div>
                                <a href="assets/images/MGLbrochuresandsm/Maha LNG_Brochure.pdf" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   <i  class="social-icon"><img src="assets/images/client-logo/facebook-icon.png" class="w-100"></i> Facebook
                                </div>
                                <a href="https://www.facebook.com/mglltdofficial" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-angle-right"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                  <i  class="social-icon"><img src="assets/images/client-logo/instagram.png" class="w-100"></i> Instagram 
                                </div>
                                <a href="https://www.instagram.com/mahanagargas?igshid=YmMyMTA2M2Y%3D" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-angle-right"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                  <i  class="social-icon"><img src="assets/images/client-logo/linkedin.png" class="w-100"></i> LinkedIn  
                                </div>
                                <a href="https://www.linkedin.com/company/mglltd/posts/?feedView=all" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-angle-right"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   <i  class="social-icon"><img src="assets/images/client-logo/twitter.png" class="w-100"></i> Twitter   
                                </div>
                                <a href="https://x.com/mahanagargas" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-angle-right"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   <i  class="social-icon"><img src="assets/images/client-logo/youtube.png" class="w-100"></i> YouTube   
                                </div>
                                <a href="https://www.youtube.com/watch?v=XgV0FqIp2Hw" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-angle-right"></i></a>
                            </div>
                        </div>
                         <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   <i  class="social-icon"><img src="assets/images/client-logo/whatsapp.png" class="w-100"></i> WhatsApp    
                                </div>
                                <a href="https://api.whatsapp.com/message/TKTPMXSNNNPLN1?autoload=1&app_absent=0" target="_Blank" class="bluebtn2 d-block">View<i
                                        class="demo-icon uil-angle-right"></i></a>
                            </div>
                        </div>
                    	
                        <!-- <div *ngFor="let i of allData" class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   {{i.Title}} 
                                </div>
                                <a href="{{baseUrl}}{{i.PdfFile}}" target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>