import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';
@Component({
  selector: 'app-dashboard-info-card',
  templateUrl: './dashboard-info-card.component.html',
  styleUrls: ['./dashboard-info-card.component.css']
})
export class DashboardInfoCardComponent {
  address:any[]=[]
  data: any;
   address1:any

  constructor(private pageLoaderService: PageLoaderService, private UserService:UserRegistrationService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.getSessionData()
  }


  getSessionData() {
    // let data = sessionStorage.getItem('UserCredential')
    // if (data)
    //   this.data = JSON.parse(data)
       let newData = sessionStorage.getItem('UserCredential_New');

if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    this.data=JSON.parse(decryptedString)

  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
     var ciphertext = crypto.AES.encrypt(this.data.CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     this.UserService.getUserAddressNew({BPnoCAno:ciphertext,BPno:''}).subscribe(response=>{
        let data2 = JSON.parse(JSON.stringify(response)).details
        this.address=data2
        //console.log(data2)
      })
      this.UserService.getCaNoNew({CAno:ciphertext,BPno:''}).subscribe(response=>{
        let data1 = JSON.parse(JSON.stringify(response)).details

        // this.address=data1
        this.address1 = data1;
        //console.log('this.address',this.address)
      })
  }

}
