import { Component, OnInit } from '@angular/core';
import { CurrentOppService } from 'src/app/Service/current-opp.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-current-opp',
  templateUrl: './current-opp.component.html',
  styleUrls: ['./current-opp.component.css']
})
export class CurrentOppComponent {
  public base = environment.baseUrl
  Currents: any[] = []
  constructor(private service: CurrentOppService) { }
  ngOnInit(): void {
    this.getAllFunction()
  }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getAllFunction() {
    this.service.getAllCareerFUnction().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
        this.Currents=data.filter((x:any)=>{
        return x.IsDeleted != 'true'
      })
     // this.Currents = data
     // console.log(this.Currents)

    })
  }
}
