


<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl modal-video">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    <div class="customer-login-page log-meter-reading">
                        <form [formGroup]="OtpForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
            
                                    <div class="customer-login-page">
                                        <p><i> * Kindly enter <b>OTP</b> number as appearing on your Mobile.</i></p>
                                        <div class="">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="InputOtp"
                                                    placeholder="Enter full name here"
                                                    [ngClass]="{ 'is-invalid': OtpForm.controls['InputOtp'].errors }" id="Otp" />
                                                <div *ngIf="OtpForm.controls['InputOtp'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="OtpForm.controls['InputOtp'].errors.required">Required Field
                                                        </div>
                                                </div>
                                                <label for="InputOtp">Enter OTP<span style="color: crimson;">*</span></label>
                                                </div>
            
                                            <div class="d-flex justify-content-between">
                                                <button [disabled]="btnStatus" style="margin-right: 10px;" (click)="verify()" class="greenbtn2">Verify <i class="demo-icon uil-check"></i>
                                                </button>
                                                <button [disabled]="OtpButtonActive" (click)="resendOtp()"
                                                    class="greenbtn2">{{OtpButtonActive ? this.Minute+":"+this.Sec:
                                                    "Resend"}} <i class="demo-icon uil-arrow-circle-left"></i></button>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>User <span>Registration</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page">User Registration</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-customer-awareness></app-customer-awareness>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

                            <div class="customer-login-page log-meter-reading">
                                <div class="log-meter-text">
                                    <ul>
                                        <li><b>Note:</b> Kindly enter BP and CA number as appearing on your bill.
                                        </li>
                                        <!-- <li>Enter your name as it appears on the bill including space and '.'(dot) Do
                                            not include your title like Mr. / Mrs. / Dr. etc</li> -->
                                    </ul>

                                    
                                </div>
                                
                                <form [formGroup]="UserForm">
                                    <div class="row clearfix">
                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input (change)="getName()" type="number" class="form-control" formControlName="CaNo" placeholder="Enter CA.No. (Contract Account)" onKeyPress="if(this.value.length==12) return false;"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['CaNo'].errors }" id="ca-no" />
                                            <label for="ca-no">CA No(Contract Account)<span style="color: crimson;">*</span></label>    
                                            <div *ngIf="submitted && UserForm.controls['CaNo'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['CaNo'].errors.required">Required Field</div>
                                                <div *ngIf="UserForm.controls['CaNo'].errors.pattern">CA Number should be in digits.</div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input (change)="getName()" type="number" class="form-control" formControlName="BpNo"
                                                placeholder="Enter BP No.(Business Partner)" maxlength="10" onKeyPress="if(this.value.length==10) return false;"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['BpNo'].errors }" id="bp-no" />
                                            <label for="bp-no">BP No (Business Partner) <span style="color: crimson;">*</span></label>
                                            <div *ngIf="submitted && UserForm.controls['BpNo'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['BpNo'].errors.required">Required Field</div>
                                                <div *ngIf="UserForm.controls['BpNo'].errors.pattern">BP Number should be in digits.</div>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="Name"
                                                placeholder="Enter full name here"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Name'].errors }" id="fullname" />
                                            <div *ngIf="submitted && UserForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['Name'].errors.required">Required Field
                                                    </div>
                                            </div>
                                            <label for="fullname">Full Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group form-floating">
                                                <input type="email" class="form-control" formControlName="Email" placeholder="Enter email id here" [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }" id="email-id" />
                                                <label for="email-id">Email ID<span style="color: crimson;">*</span></label>
                                                <div *ngIf="submitted && UserForm.controls['Email'].errors" class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Email'].errors.required">Required Field</div>
                                                    <div *ngIf="UserForm.controls['Email'].errors.pattern">Email must be a valid email address.</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-sm-6 form-group">
                                             <div class="form-floating">
                                            <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control" formControlName="MobileNo" placeholder="Enter Mobile No" maxlength="10"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MobileNo'].errors }" id="MobileNo" (wheel)="disableScroll($event)" (keydown)="preventArrows($event)" />
                                             <label for="MobileNo">Mobile No<span style="color: crimson;">*</span></label>

                                            <div *ngIf="submitted && UserForm.controls['MobileNo'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['MobileNo'].errors.required">Required Field</div>
                                                <div *ngIf="UserForm.controls['MobileNo'].errors.pattern">Mobile number should be in digits.</div>
                                            </div>
                                            </div>   
                                        </div>
                                        <div class="col-sm-6 form-group ">
                                            <div class="form-floating">
                                            
                                            <input autocomplete="off" type="password" class="form-control" formControlName="Password" placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Password'].errors }" id="reg-passoword" />
                                            <label for="reg-passoword">New Password<span style="color: crimson;">*</span></label>
                                            <div *ngIf="submitted && UserForm.controls['Password'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['Password'].errors.required">Required Field</div>
                                                <div *ngIf="UserForm.controls['Password'].errors.minlength">New Password must be at least 8 characters</div>
                                                <div *ngIf="UserForm.controls['Password'].errors.pattern">New Password must be includes caps, small letters, digits, and
                                                    special character</div>
                                            </div>
                                            </div>
                                        </div>

                                       <!--  <div  class="mb-15" *ngIf="nameFlag==true">
                                        <div class="white-box10">
                                        <p class="mb-0"><b><i>Existing Name:{{maskName1}}</i></b></p>
                                        </div>
                                        </div> -->

                                       
                                       <div  class="px-3 py-4"> 
                                <div class="service-label customer-user-registration-page">
                                <div class="mb-10">
                                    <div class="form-check">
                                        <input  formControlName="terms_and_condition" type="checkbox"
                                            id="html" name="fav_language"
                                            class="form-check-input" [ngClass]="{ 'is-invalid': submitted && UserForm.controls['terms_and_condition'].errors }">
                                        <label  for="html" class="">
                                            <a  class="terms-label"><p class="mb-1 mt-1"><b><i>DECLARATION & CONSENT:</i></b></p><p class="mb-0 text-justify">I/We declare the above details are true & correct and authorize MGL to verify the same. I/We the user named above do hereby declare that I shall defend, indemnify and hold harmless MGL from all claims and losses that MGL may sustain or incur as a result, whether directly or indirectly in not providing correct information. </p></a></label>
                                           
                                           
                                    </div>
                                </div>
                                 <div class="mb-10">
                                    <div class="form-check">
                                       
                                        <label  for="html" class="">
                                            <a  class="terms-label"><p class="mb-1"><b><i>CONFIDENTIALITY:</i></b></p><p class="mb-0 text-justify">Information provided to MGL by Consumer shall be used for the purposes enumerated in this terms & conditions and shall be kept confidential unless the same is required to be disclosed (i) for billing or market operation purposes, or (ii) for law enforcement purposes, or (iii) dues if any of Consumer is transferred to debt collection agency; and in such cases the confidentiality obligations shall cease the extent required under the foregoing circumstances.</p></a></label>
                                           
                                           
                                    </div>
                                </div>
                                <div class="mb-0">
                                    <div class="form-check mb-0">
                                       
                                        <label  for="html" class="mb-0">
                                            <a  class="terms-label"><p class="mb-1"><b><i>CONSENT:</i></b></p> <p class="mb-0 text-justify">By registering my mobile number or email address, I voluntarily and explicitly consent to Mahanagar Gas Ltd. (MGL) processing my email ID and mobile number (personal data) for the purpose of sending soft copies of invoices, notifications via SMS, reminders for outstanding gas bill dues, bill payment confirmations, notices regarding stoppage of gas supply, and other relevant information related to MGL’s supply of PNG to me/us.
                                            I understand that my personal data shall be kept confidential and will only be shared with authorized service providers engaged by MGL for the aforementioned purposes. It shall not be used for any other purpose except as required by law.
                                            I am aware that I have the right to withdraw my consent at any time by contacting MGL at support@mahanagargas.com  / (022) 6867 4500 & 6156 4500. However, I acknowledge that withdrawal of consent may limit MGL’s ability to provide service. I am also aware that such withdrawal shall not affect the legality of processing of the personal data based on consent before its withdrawal and that processing will be considered as legitimate.</p></a></label>
                                            <!-- at [insert email/contact details] -->
                                           
                                           
                                    </div>
                                </div>
                            </div>
                             </div>
                            
                                    

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <!-- <label>Verify<span class="text-danger">*</span></label> -->
                                            <div style="background: transparent;border: transparent;"
                                                class="mb-10 mt-10">
                                                <ngx-recaptcha2 #captchaElem
                                                    siteKey="6LcZ3L0lAAAAAIp9NkeAKfkYnUaq4IQqCZyC1sPJ"
                                                    (success)="handleSuccess($event)" [size]="size" [hl]="lang"
                                                    [theme]="theme" [type]="type" formControlName="Captcha">
                                                </ngx-recaptcha2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-20">
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="this.UserForm.reset()"
                                                    (click)="this.UserForm.controls.Captcha.reset()"
                                                    (click)="submitted=false"
                                                    class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>

                                <div class="text-right mt-20">
                                    <p class="mb-0"><b><i>Please note all fields marked <span class="text-danger">*</span> are
                                                compulsory.</i></b></p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>