<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>CNG Rate Card</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Customer Zone</a></li>
                <li class="active" aria-current="page"><a>CNG Rate Card </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <!-- <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Recent Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border mb-30">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>

                            <a href="#" class="greenbtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="customer-slider mt-30 mb-30">
                    <owl-carousel-o [options]="networkOptions">
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">1</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Open all Doors and Windows</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img1.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">2</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Turn off your gas supply by using Meter Control Valve (MCV)</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img2.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">3</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Do not switch on or off any electrical switch</p>
                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img3.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">4</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Extinguish all naked flames</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img4.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <div class="blog-item">
                            <div class="blog-content">
                                <div class="number">5</div>
                                <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                <p class="desc">Call emergency service no 18002669944 (Toll free) / (022)-68759400 /
                                    (022)-24012400 after coming out from the danger area</p>

                            </div>
                            <div class="image-wrap">
                                <a href="#"><img src="assets/images/safty-img5.jpg" alt="Blog"></a>
                            </div>
                        </div>

                    </owl-carousel-o>

                </div> -->

               <app-insta-post></app-insta-post>
               <!--  <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <!-- <p class="mb-10">THE PRICE OF CNG FROM JULY 9<sup>th</sup> 2024 IN YOUR AREA IS AS FOLLOWS:</p> -->
                     <p class="mb-10">THE PRICE OF CNG FROM 9<sup>th</sup> APRIL 2025 IN YOUR AREA IS AS FOLLOWS:</p>
                    <p><span>Revised rates for Compressed Natural Gas (CNG) - Inclusive of Local Taxes</span></p>

                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Location</th>
                                    <th>MRP (Rs. / Kg)</th>
                                     <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Mumbai and its adjoining areas</td>
                                    <td>₹ 79.50/kg</td>
                                    <td>
                                        <a class="greenbtn2" href="assets/images/pdf/CNG Price Breakup.pdf" target="_Blank">Price break-up <i class="demo-icon uil-refresh"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>