import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.css']
})
export class BillingInfoComponent {

  data : any

  constructor(private router: Router,private UserService : UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.UserService.checkLogin()
    this.getSessionData()
  }

  checkLogin(){
    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    // if(!data){
    //   this.router.navigate(['/login'])
    // }

    let newData = sessionStorage.getItem('UserCredential_New');

if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    

  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
  this.router.navigate(['/login'])
 // console.error("User credentials not found in session storage.");
 
}
  }

  getSessionData(){
     let newData = sessionStorage.getItem('UserCredential_New');
     if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
     this.data=JSON.parse(decryptedString)
    } catch (error) {
   // console.error("Decryption Error:", error); 
  }
  } else {
     // console.error("User credentials not found in session storage.");
   }
    // let data = sessionStorage.getItem('UserCredential')
    // if(data)
    // this.data=JSON.parse(data)
    //console.log(this.data)
  }

}
