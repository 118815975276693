import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment'; 

@Component({
  selector: 'app-applyfornametsteponeedit',
  templateUrl: './applyfornametsteponeedit.component.html',
  styleUrls: ['./applyfornametsteponeedit.component.css']
})
export class ApplyfornametsteponeeditComponent {
 public base = environment.baseUrl
   allPost: any[] = [];
   captcha: any;
   CustomerInfoForm!: FormGroup
   submitted = false
   finalSubmit : boolean = false
   loading = false;

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

   NameInitial: any[] = ['Mr.','Mrs.','Miss.','M/s.','Dr.'];
   previouspageData:any;

   id: any;

  constructor(private PostService: SliderService,private el: ElementRef,private datePipe: DatePipe, private FormBuilder: FormBuilder, private PngService: FormServiceService, public UserService: UserRegistrationService,
   public router: Router, private pageLoaderService: PageLoaderService,
   private activatedRoute: ActivatedRoute,) {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
   //console.log(this.id)

       let obj = {'ApplicationNo': this.id}
     this.PngService.getUserinfoforPrint(obj).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
         this.previouspageData = data.details[0]
        //  console.log(this.previouspageData)

          this.CustomerInfoForm.get('NameInitial')?.setValue(this.previouspageData.NameInitial)
   this.CustomerInfoForm.get('Fname')?.setValue(this.previouspageData.Fname)
   this.CustomerInfoForm.get('Mname')?.setValue(this.previouspageData.Mname)
   this.CustomerInfoForm.get('lname')?.setValue(this.previouspageData.lname)
   this.CustomerInfoForm.get('FHusbandName')?.setValue(this.previouspageData.FHusbandName)
   this.CustomerInfoForm.get('Pan')?.setValue(this.previouspageData.Pan)
    this.CustomerInfoForm.get('MobileNo')?.setValue(this.previouspageData.MobileNo)
   this.CustomerInfoForm.get('AltMobileNo')?.setValue(this.previouspageData.AltMobileNo)
   this.CustomerInfoForm.get('Email')?.setValue(this.previouspageData.Email)
   

  })

      // this.activatedRoute.queryParams.subscribe((data: any) => {
      //   this.previouspageData = JSON.parse(data.data);
      //    console.log('this.previouspageData',this.previouspageData);
        
      
      // });

    }


 ngOnInit(): void {
  this.captchaGenerate()
  this.getAllHighlight()

  this.CustomerInfoForm = this.FormBuilder.group({
      // flatNo: [''],
      // floor: [''],
      // plot: [''],
      // wing: [''],
      // Sector: [''],
      // BuildingName: [''],
      // SocietyName: [''],
      // Colony: [''],
      // RoadName: [''],
      // Landkmark: [''],
      // Location: [''],
      // cityDist: [''],
      // Pincode: [''],
      NameInitial: ['', [Validators.required]],
      Fname: ['', [Validators.required]],
      Mname: [''],
      lname: ['', [Validators.required]],
      FHusbandName: [''],
      // Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
        Pan:[''],
      MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]],
      AltMobileNo: ['', [Validators.pattern(/[0-9]{10}/)]],
      Email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      Captcha: ['', [Validators.required]],
      // BPno: [''],
      // CAno: ['']
     })

   



   this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

 }

 ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

 getAllHighlight(){
    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
     this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
     })
    }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
   this.captcha = result
 }


 submitCustomerForm(){

 

   this.submitted = true
   this.loading = true;
    if (this.CustomerInfoForm.get('Captcha')?.value != this.captcha) {
      this.CustomerInfoForm.get('Captcha')?.setValue('')
    }
    if (this.CustomerInfoForm.invalid) {
       this.loading = false;
      return;
    }

  
   

      // this.CustomerInfoForm.get('BPno')?.setValue(this.previouspageData.Partner)
      // this.CustomerInfoForm.get('CAno')?.setValue(this.previouspageData.Contract_Account)

    
     let obj={"NameInitial":this.CustomerInfoForm.get('NameInitial')?.value,"Fname":this.CustomerInfoForm.get('Fname')?.value,
     "Mname":this.CustomerInfoForm.get('Mname')?.value,"lname":this.CustomerInfoForm.get('lname')?.value,
     "FHusbandName":this.CustomerInfoForm.get('FHusbandName')?.value,
     "Pan":this.CustomerInfoForm.get('Pan')?.value,
     "MobileNo":this.CustomerInfoForm.get('MobileNo')?.value,
     "AltMobileNo":this.CustomerInfoForm.get('AltMobileNo')?.value,
     "Email":this.CustomerInfoForm.get('Email')?.value,"ApplicationNo":this.id}

    this.PngService.editCustomerFormone(obj).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      
    //  console.log(response)
      this.alertType = '3rdSubmit'
      if (data.Response) {
        this.alertMsg = data.message+"| Application No." + data.details
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
           this.loading = false;
        this.router.navigate(['/residential-png/customer-zone/name-transfer/apply-for-name-step-two-edit'],
        {queryParams:{data:JSON.stringify(this.id)}})
        }, 1000);
      } else {
         this.loading = false;
        this.alertMsg = data.message
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
      }
    })
    
     

 }

}

