import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-quick-pay',
  templateUrl: './quick-pay.component.html',
  styleUrls: ['./quick-pay.component.css']
})
export class QuickPayComponent {
  data:any;
  logincheckFlag = false;
  constructor(public UserService: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);


    // let data = sessionStorage.getItem('UserCredential')
    let data = '';
  //  console.log(data)
     let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    if(data){
       //console.log('login')
       this.logincheckFlag = true;
     this.data=JSON.parse(data)
    }else{
      this.logincheckFlag = false;
      //console.log('loout')
    }

    
  }

  // getSessionData(){
  //  // this.UserService.checkLogin();
  //   let data = sessionStorage.getItem('UserCredential')
  //   if(data)
  //   this.data=JSON.parse(data)
  //  this.getbillDesk()
   
  // }


    getbillDesk() {
    //console.log( this.data)

      let obj={"CAno":this.data.CaNo,"Contact":this.data.Mobile}
      this.UserService.getbillDesk(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      //console.log(res)
     
      if (res.response == true) {
       
        let ca = res.details.Ciphertext;
        let mobile = res.details.Ciphertext1;
       //console.log(ca,mobile)
        let paymentString = `
      <html>
        <body>
          <form action="https://payments.billdesk.com/MercOnline/mglQuickpayController" method="post" id="payu_form">
          <input  type='hidden' id='txnid' name='customerAccountNo' value='${ca}'/>
          <input  type='hidden' id='amount' name='mobileNo' value='${mobile}'/>
         
         
          </form>
          <script type="text/javascript">document.getElementById("payu_form").submit();</script>
        </body>
      </html>`;


      const winUrl = URL.createObjectURL(
        new Blob([paymentString], { type: "text/html" })
    );
    
    window.location.href = winUrl;
      
      } else {
        
      }



    }, err => {
      //console.log(err)
    })

  }
}
