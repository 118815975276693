import { Component } from '@angular/core';
import { first } from 'rxjs/internal/operators/first';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { Config } from './payment';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-customer-payment-history',
  templateUrl: './customer-payment-history.component.html',
  styleUrls: ['./customer-payment-history.component.css']
})
export class CustomerPaymentHistoryComponent {

  data: any;
  allData: any[] = []

  constructor(private UserService: UserRegistrationService, private CustomerService: DashboardServiceService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.getSessionData()
    this.UserService.checkLogin()
    this.getPaymentHistory()

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  getPaymentHistory() {
     var CaNotext = crypto.AES.encrypt(this.data.CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
    this.CustomerService.getPaymentHistory(CaNotext).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response));
      this.allData = data.details;
      //console.log(this.allData)
    })
  }

  getSessionData() {
    let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    this.data=JSON.parse(decryptedString)
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    // let data = sessionStorage.getItem('UserCredential')
    // if (data)
    //   this.data = JSON.parse(data)
    //console.log(JSON.parse(data!).CaNo)
  }
}
