<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Edit <span>Profile</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Update your contact detail</a></li>
                <!-- <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li> -->
                <li class="active" aria-current="page">Edit Profile</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>

            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

                            <div class="customer-login-page log-meter-reading">

                                <form [formGroup]="UserForm">
                                    <div class="row">


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly type="text" class="form-control" formControlName="Name"
                                                    placeholder="Name"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Name'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && UserForm.controls['Name'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Name'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>



                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" formControlName="Email"
                                                    placeholder="Email"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                                                    id="Email" />
                                                <div *ngIf="submitted && UserForm.controls['Email'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Email'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Email<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                  


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input onKeyPress="if(this.value.length==10) return false;" type="text" class="form-control" formControlName="MobileNo"
                                                    placeholder="MobileNo"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MobileNo'].errors }"
                                                    id="Email" />
                                                <div *ngIf="submitted && UserForm.controls['MobileNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['MobileNo'].errors">
                                                        Required Field</div>
                                                         <div *ngIf="UserForm.controls['MobileNo'].errors.pattern">
                                                    Invalid Mobile No </div>
                                                <div
                                                    *ngIf="UserForm.controls.MobileNo.hasError('maxlength') && UserForm.controls.MobileNo.touched">
                                                    Invalid Mobile No </div>
                                                <div
                                                    *ngIf="UserForm.controls.MobileNo.hasError('minlength') && UserForm.controls.MobileNo.touched">
                                                    Invalid Mobile No</div>
                                                </div>
                                                <label for="Mobile No">Mobile No.<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                             
                             <div  class="px-3 py-4">
                            <div class="service-label customer-user-registration-page">
                                <div class="mb-10">
                                    <div class="form-check">
                                        <input  formControlName="terms_and_condition_update_pro" type="checkbox"
                                            id="html" name="fav_language"
                                            class="form-check-input" [ngClass]="{ 'is-invalid': submitted && UserForm.controls['terms_and_condition_update_pro'].errors }">
                                        <label  for="html" class="">
                                            <a  class="terms-label"><p class="mb-1 mt-1"><b><i>DECLARATION & CONSENT:</i></b></p>
                                                <p  class="mb-0 text-justify">I/We declare the above details are true & correct and authorize MGL to verify the same. I/We the user named above do hereby declare that I shall defend, indemnify and hold harmless MGL from all claims and losses that MGL may sustain or incur as a result, whether directly or indirectly in not providing correct information. </p></a></label>
                                           
                                           
                                    </div>
                                </div>
                                 <div class="mb-10">
                                    <div class="form-check">
                                       
                                        <label  for="html" class="">
                                            <a  class="terms-label"><p class="mb-1"><b><i>CONFIDENTIALITY:</i></b></p>
                                                <p  class="mb-0 text-justify">Information provided to MGL by Consumer shall be used for the purposes enumerated in this terms & conditions and shall be kept confidential unless the same is required to be disclosed (i) for billing or market operation purposes, or (ii) for law enforcement purposes, or (iii) dues if any of Consumer is transferred to debt collection agency; and in such cases the confidentiality obligations shall cease the extent required under the foregoing circumstances.</p></a></label>
                                           
                                           
                                    </div>
                                </div>
                                <div class="mb-10">
                                    <div class="form-check">
                                       
                                        <label  for="html" class="mb-0">
                                            <a  class="terms-label"><p class="mb-1"><b><i>CONSENT:</i></b></p> 
                                                <p class="mb-0 text-justify">By registering my mobile number or email address, I voluntarily and explicitly consent to Mahanagar Gas Ltd. (MGL) processing my email ID and mobile number (personal data) for the purpose of sending soft copies of invoices, notifications via SMS, reminders for outstanding gas bill dues, bill payment confirmations, notices regarding stoppage of gas supply, and other relevant information related to MGL’s supply of PNG to me/us.
                                            I understand that my personal data shall be kept confidential and will only be shared with authorized service providers engaged by MGL for the aforementioned purposes. It shall not be used for any other purpose except as required by law.
                                            I am aware that I have the right to withdraw my consent at any time by contacting MGL at support@mahanagargas.com  / (022) 6867 4500 & 6156 4500. However, I acknowledge that withdrawal of consent may limit MGL’s ability to provide service. I am also aware that such withdrawal shall not affect the legality of processing of the personal data based on consent before its withdrawal and that processing will be considered as legitimate.</p></a></label>
                                            <!-- at [insert email/contact details] -->
                                           
                                           
                                    </div>
                                </div>
                            </div>
                             </div>



                                        <!-- <div class="border-single"></div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Flat No. <span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['FlatNo'].errors}"
                                                    formControlName="FlatNo" class="from-control" type="text" id="name"
                                                    name="name" placeholder="Enter Flat No." >
                                                <div *ngIf="submitted && UserForm.controls['FlatNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['FlatNo'].errors">Flat No. is required
                                                    </div>
                                                </div>
                                            </div>


                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Floor</label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Floor'].errors}"
                                                    formControlName="Floor" class="from-control" type="number" id="name"
                                                    name="name" placeholder="Enter Floor" >
                                                <div *ngIf="submitted && UserForm.controls['Floor'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Floor'].errors">Floor is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Plot No.<span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['PlotNo'].errors}"
                                                    formControlName="PlotNo" class="from-control" type="number"
                                                    id="name" name="name" placeholder="Enter Plot No." >

                                                <div *ngIf="submitted && UserForm.controls['PlotNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['PlotNo'].errors">Plot No. is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Wing</label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Wing'].errors}"
                                                    formControlName="Wing" class="from-control" type="text" id="name"
                                                    name="name" placeholder="Enter Wing" >
                                                <div *ngIf="submitted && UserForm.controls['Wing'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Wing'].errors">Wing is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Building Name<span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['BuildingName'].errors}"
                                                    formControlName="BuildingName" class="from-control" type="text"
                                                    id="name" name="name" placeholder="Enter Building Name" >
                                                <div *ngIf="submitted && UserForm.controls['BuildingName'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="UserForm.controls['BuildingName'].errors">Building Name
                                                        required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Society Name</label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['SociatyName'].errors}"
                                                    formControlName="SociatyName" class="from-control" type="text"
                                                    id="name" name="name" placeholder="Enter Your Society Name"
                                                    >
                                                <div *ngIf="submitted && UserForm.controls['SociatyName'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['SociatyName'].errors">Society Name
                                                        required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Colony </label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Colony'].errors}"
                                                    formControlName="Colony" class="from-control" type="text" id="name"
                                                    name="name" placeholder="Enter Your Colony" >
                                                <div *ngIf="submitted && UserForm.controls['Colony'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Colony'].errors">Colony required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Road Name</label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['RoadName'].errors}"
                                                    formControlName="RoadName" class="from-control" type="text"
                                                    id="name" name="name" placeholder="Enter Your Road Name"
                                                    >
                                                <div *ngIf="submitted && UserForm.controls['RoadName'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['RoadName'].errors">Road Name required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Landmark <span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                               
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['LandMark'].errors}"
                                                    formControlName="LandMark" class="from-control" type="text"
                                                    id="name" name="name" placeholder="Enter Your Landmark" >
                                                <div *ngIf="submitted && UserForm.controls['LandMark'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['LandMark'].errors">Landmark required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Location <span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Location'].errors}"
                                                    formControlName="Location" class="from-control" type="text"
                                                    id="name" name="name" placeholder="Enter Your Location" >
                                                <div *ngIf="submitted && UserForm.controls['Location'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Location'].errors">Location required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>City/District <span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                                
                                                <input readonly
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['CityDistrict'].errors}"
                                                    formControlName="CityDistrict" class="from-control" type="text"
                                                    id="name" name="name" placeholder="Enter Your City/District"
                                                    >
                                                <div *ngIf="submitted && UserForm.controls['CityDistrict'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['CityDistrict'].errors">City/District
                                                        required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Pincode <span class="text-danger">*</span></label>
                                            <div class="mb-20 cu-login-input2 mt-10">
                                               
                                                <input readonly onKeyPress="if(this.value.length==6) return false;"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Pincode'].errors}"
                                                    formControlName="Pincode" class="from-control" type="number"
                                                    id="name" name="name" placeholder="Enter Your Pincode" >
                                                <div *ngIf="submitted && UserForm.controls['Pincode'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Pincode'].errors">Pincode required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                                <div class="text-right mt-20">
                                    <ul>
                                        <li><i><b>Note:</b> Kindly enter Address as appearing on your bill.</i></li>
                                    </ul>

                                    <p class="mt-10 mb-0"><b><i> Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>

                                <div *ngIf="alertType == 'secSubmit1'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>




                </div>
            </div>
        </div>
    </div>

     <div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl modal-video">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    <div class="customer-login-page log-meter-reading">
                        <form [formGroup]="OtpForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
            
                                    <div class="customer-login-page">
                                        <p> <b>Enter OTP</b></p>
                                        <div class="">
                                            <p><i> * Kindly enter <b>OTP</b> number as appearing on your Registered Mobile.</i></p>
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="InputOtp"
                                                    placeholder="Enter full name here"
                                                    [ngClass]="{ 'is-invalid': OtpSubmitted && OtpForm.controls['InputOtp'].errors }" id="Otp" />
                                                <div *ngIf="OtpSubmitted && OtpForm.controls['InputOtp'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="OtpSubmitted && OtpForm.controls['InputOtp'].errors.required">Required Field
                                                        </div>
                                                </div>
                                                <label for="InputOtp">Enter OTP<span style="color: crimson;">*</span></label>
                                                </div>
                                            <div class="mt-20">
                                            <div class="d-flex justify-content-between">
                                                <button [disabled]="btnStatus" style="margin-right: 10px;" (click)="verify()" class="{{OtpButtonActive ? 'greenbtn2' : 'greybtn'}}">Verify <i class="demo-icon uil-check"></i>
                                                </button>
                                                <button [disabled]="OtpButtonActive" (click)="resendOtp()"
                                                    class="{{OtpButtonActive ? 'greybtn' : 'greenbtn2'}}">{{OtpButtonActive ? this.Minute+":"+this.Sec:
                                                    "Resend"}} <i class="demo-icon uil-arrow-circle-left"></i></button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>