import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { DatePipe } from '@angular/common';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-billing-cycle',
  templateUrl: './billing-cycle.component.html',
  styleUrls: ['./billing-cycle.component.css']
})
export class BillingCycleComponent implements OnInit {

  data : any
  normal1: any;
  normal2: any;
  normal3: any;
  access1: string = '';
  access2: string = '';
  access3: string = '';
  
  constructor(public datepipe: DatePipe, private service: DashboardServiceService, private UserService : UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.UserService.checkLogin()
    this.getSessionData()
  }

  getSessionData(){
  //   let data = sessionStorage.getItem('UserCredential')
  //   if(data)
  //   this.data=JSON.parse(data)
  //  // console.log(this.data)
	// this.getBillInfo();

    let newData = sessionStorage.getItem('UserCredential_New');

if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    this.data=JSON.parse(decryptedString)
    this.getBillInfo()

  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
  }

	getBillInfo(){
    this.service.getBillingCycle({ CAno: this.data.CaNo }).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      //09/01/2023
      
     // console.log(data.bill_date)
      const temp: string[] = data.bill_date.split("/")
      const date = new Date();
      date.setDate(Number(temp[0]));
      date.setMonth(Number(temp[1])-1);
      date.setFullYear(Number(temp[2]));
      
      //let temp = this.datepipe.transform(new Date(data.bill_date), 'yyyy-MM-dd')
      var ndate1 = this.datepipe.transform(new Date(date.setMonth(date.getMonth())), 'dd MMM yyyy');
      var ndate2 = this.datepipe.transform(new Date(date.setMonth(date.getMonth()+4)), 'dd MMM yyyy');
      var ndate3 = this.datepipe.transform(new Date(date.setMonth(date.getMonth()+4)), 'dd MMM yyyy');
      const ndate22 = [ndate1, ndate2, ndate3]; 
      this.sortByMonth(ndate22);
      var nd1 = new Date(ndate22[0]!);
      nd1.setFullYear(new Date().getFullYear())
      ndate1 = this.datepipe.transform(new Date(nd1.setMonth(nd1.getMonth())), 'dd MMM yyyy');
      ndate2 = this.datepipe.transform(new Date(nd1.setMonth(nd1.getMonth()+4)), 'dd MMM yyyy');
      ndate3 = this.datepipe.transform(new Date(nd1.setMonth(nd1.getMonth()+4)), 'dd MMM yyyy');
      
      var n1date1 = new Date(ndate1!);
      var n1date2 = new Date(ndate2!);
      var n1date3 = new Date(ndate3!);
      var normal1 = this.datepipe.transform(new Date(n1date1.setDate(n1date1.getDate()-10)), 'dd MMM yyyy');
      var normal2 = this.datepipe.transform(new Date(n1date2.setDate(n1date2.getDate()-10)), 'dd MMM yyyy');
      var normal3 = this.datepipe.transform(new Date(n1date3.setDate(n1date3.getDate()-10)), 'dd MMM yyyy');
     
      this.normal1 = normal1 + " - " + ndate1;
      this.normal2 = normal2 + " - " + ndate2;
      this.normal3 = normal3 + " - " + ndate3;

      const date1 = new Date();
      date1.setDate(Number(temp[0]));
      date1.setMonth(Number(temp[1])+1);
      date1.setFullYear(Number(temp[2]));
      var adate1 = this.datepipe.transform(new Date(date1.setMonth(date1.getMonth())), 'dd MMM yyyy');
      var adate2 = this.datepipe.transform(new Date(date1.setMonth(date1.getMonth()+4)), 'dd MMM yyyy');
      var adate3 = this.datepipe.transform(new Date(date1.setMonth(date1.getMonth()+4)), 'dd MMM yyyy');
      const adate22 = [adate1, adate2, adate3];
      this.sortByMonth(adate22)
      var ad1 = new Date(adate22[0]!);
      ad1.setFullYear(new Date().getFullYear())
      adate1 = this.datepipe.transform(new Date(ad1.setMonth(ad1.getMonth())), 'dd MMM yyyy');
      adate2 = this.datepipe.transform(new Date(ad1.setMonth(ad1.getMonth()+4)), 'dd MMM yyyy');
      adate3 = this.datepipe.transform(new Date(ad1.setMonth(ad1.getMonth()+4)), 'dd MMM yyyy');
      
      var a1date1 = new Date(adate1!);
      var a1date2 = new Date(adate2!);
      var a1date3 = new Date(adate3!);
      var access1 = this.datepipe.transform(new Date(a1date1.setDate(a1date1.getDate()-10)), 'dd MMM yyyy');
      var access2 = this.datepipe.transform(new Date(a1date2.setDate(a1date2.getDate()-10)), 'dd MMM yyyy');
      var access3 = this.datepipe.transform(new Date(a1date3.setDate(a1date3.getDate()-10)), 'dd MMM yyyy');
     
      this.access1 = access1 + " - " + adate1;
      this.access2 = access2 + " - " + adate2;
      this.access3 = access3 + " - " + adate3;
    });
  }

  sortByMonth(arr: any) {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
   // console.log(arr)          
    arr.sort(function(a: string, b: string){
        return months.indexOf(a.substring(3, 6))
             - months.indexOf(b.substring(3, 6));
    });
  }
}

