import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from "@angular/router"
import { environment } from 'src/environments/environment';
import { FaqServiceService } from './FAQ/faq-service.service';
import { AreaResp } from '../models/area';
import { map } from 'rxjs';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {
  printNameTransfer:any
  ApplicationNo : any

  loginStatus:boolean =false
  baseUrl:string=environment.baseUrl
  loggedIn: boolean = false
  otpStatus : boolean =false

   loggedIn1: boolean = false
   loginStatus1:boolean =false

   loggedIn2: boolean = false
   loginStatus2:boolean =false
  
  constructor(private http : HttpClient,private FaqService: FaqServiceService, private router: Router) { 
    console.log('asdlf servie',this.printNameTransfer)
  }


  get_reg_name(data:any){
    return this.http.post(this.baseUrl+'reg/get_reg_name',data)
  }

   getbillDesk(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/getbillDesk',data)
        }

  getStockData(){
    return this.http.get(this.baseUrl+'getStockData')
  }

  checkLogin(){
    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    let data = ''
        let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    if(!data){
      this.router.navigate(['/residential-png/customer-zone/customer-login'])
    }else{
      this.loginStatus=true
    }
  }

  checkLogin1(){
    //let data1 = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential1')))
     let data11 = '';
    let newData = sessionStorage.getItem('UserCredential1');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data11=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
  


    if(!data11){
      this.router.navigate(['/cng/cng-offers-schemes/retrofitters-login'])
    }else{
      this.loginStatus1=true
    }
  }


   checkLogin2(){
    // let data2 = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential2')))
    let data2 = '';
    let newData = sessionStorage.getItem('UserCredential2');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data2=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    if(!data2){
      this.router.navigate(['/cng/cng-offers-schemes/oem-login'])
    }else{
      this.loginStatus2=true
    }
  }


  registerUser(data:any){
    return this.http.post(this.baseUrl+'reg/insert_reg',data)
  }

  login(data:any){

    return this.http.post(this.baseUrl+'reg/userLogin',data)
  }

   loginwithOTP(data:any){

    return this.http.post(this.baseUrl+'reg/loginwithOTP',data)
  }

  retrofitterslogin(data:any){

    return this.http.post(this.baseUrl+'reg/retrofitterslogin',data)
  }

   oemlogin(data:any){

    return this.http.post(this.baseUrl+'reg/oemlogin',data)
  }

  checkOtp(data:any){
    return this.http.post(this.baseUrl+'reg/checkOtp',data)
  }

  checkLoginOtp(data:any){
    return this.http.post(this.baseUrl+'reg/checkLoginOtp',data)
  }

  updateOtpToDb(data:any){
    return this.http.post(this.baseUrl+'reg/resendOtp',data)
  }

 


sendOtp(otp: any, mobileNumber: any) {
   this.http.post(this.baseUrl+'reg/send_Reg_Otp',{otp: otp, mobileNumber: mobileNumber}).subscribe(response=>{
    return alert("Otp Sent")
  })
}

  getUserAddress(data:any){
    return this.http.post(this.baseUrl+'reg/getUserAddress',data)
  }

   getUserAddressNew(data:any){
    return this.http.post(this.baseUrl+'reg/getUserAddressNew',data)
  }

  updateUserAddress(data:any){
    return this.http.post(this.baseUrl+'reg/updateAddress',data)
  }

  ChangePass(data:any){
    return this.http.post(this.baseUrl+'reg/changeUserPass',data)
  }

  //***********Change api for vapt activity start*****//

   changeUserPass_New(data:any){
    return this.http.post(this.baseUrl+'reg/changeUserPass_New',data)
  }

  //***********Change api for vapt activity end*****//

  getCaNo(data:any){
    return this.http.post(this.baseUrl+'reg/getCaNo',data)
  }

  getCaNoNew(data:any){
    return this.http.post(this.baseUrl+'reg/getCaNoNew',data)
  }
  
  forgotPassword(cano: any, email: any){
    return this.http.post<AreaResp>(this.baseUrl + 'status/forgotPassword', {cano: cano, email: email})
    .pipe(map(user => {
      return user;
    }));
  }

  getNameTransfer(data:any){
    return this.http.post(this.baseUrl+'reg/getNameTransfer',data)
  }

   getUserReginfo(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserReginfo',data)
  }

    getUserReginfoInstitution(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserReginfoInstitution',data)
  }

   getUserReginfoTest(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserReginfoTest',data)
  }

   openEnach1(data:any){
    return this.http.post(this.baseUrl+'NachMandate',data)
  }

   get_NachMandate_status(data:any){
    return this.http.post(this.baseUrl+'get_NachMandate_status',data)
  }

  getAllCsr(){
    return this.http.get(this.baseUrl+'csr/getAllCsr')
  }

  getpendingAmout(data:any){
    
    return this.http.post(this.baseUrl+'getQRPay',data)
  }

   
  
  getUserInfoID(data: any) {
    return this.http.post(this.baseUrl + 'reg/getUserInfoID', {id: data})
  }

  resetUserPass(data: any) {
    return this.http.post(this.baseUrl + 'reg/resetUserPass', data)
  }

   registerNachCancel(data:any){
    return this.http.post(this.baseUrl+'RegNachMandateCancel',data) 
  }

  mglNetworklist(){

    return this.http.post(this.baseUrl+'mgl_network/mglNetworklist',{})
  }

  getUserAppnum(data:any){
    return this.http.post(this.baseUrl+'transaction/getUserAppnum',data)
  }


 postCampaigns(data:any){
        return this.http.post<any>(this.baseUrl+'campaigns/postCampaigns',data)
        }


  //***************Nach Mandate Cancel functionality start*************//
    
     insertNachcancel(data:any){
        return this.http.post(this.baseUrl+'insertNachcancel',data) 
      }
    //***************Nach Mandate Cancel functionality end*************//   





    //**********for app********//

    get_mgl_domestic_app_data(data:any){
    
    return this.http.post(this.baseUrl+'payutransaction/get_mgl_domestic_app_data',data)
  }



   app_user_reg(data:any){
    return this.http.post(this.baseUrl+'reg/app_user_reg',data)
  }

   check_app_user_reg_otp(data:any){
    return this.http.post(this.baseUrl+'reg/check_app_user_reg_otp',data)
  }

  get_name_transfer_app_data(data:any){
    return this.http.post(this.baseUrl+'get_name_transfer_app_data',data)
  }



  //**************For CSC api*********


       get_token_issuing(data:any){
        return this.http.post<any>(this.baseUrl+'cscpayments/get_token_issuing',data)
        }

         get_connect_data(data:any){
          // console.log(data)
          let requestOptions:any = {'authorization': String(data)};
          // console.log(requestOptions)
        return this.http.post<any>(this.baseUrl+'cscpayments/get_connect_data',{},{headers: new HttpHeaders(requestOptions)})
        }

         get_payment_encryption(data:any){
        return this.http.post<any>(this.baseUrl+'cscpayments/get_payment_encryption',data)
        }

}
