import { Component, OnInit } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-residential-png-service-section',
  templateUrl: './residential-png-service-section.component.html',
  styleUrls: ['./residential-png-service-section.component.css']
})
export class ResidentialPngServiceSectionComponent {
  data:any={
    CaNo:''
  }

  stock:any={
    CurrentPrice:'',
    Changes:'',
    BseCurrentPrice:'',
    BseChange:''
  }
  NseColors:any
  BseColors:any
  

  constructor(private Service : UserRegistrationService,
    private activatedRoute: ActivatedRoute,
    public router: Router,){}

  ngOnInit(): void {
    //this.getStockData()
    //console.log(this.stock)

    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    // if (data) {
    //   this.data.CaNo = JSON.parse(data).CaNo
    //   //console.log(this.data.CaNo)
      
    // }
    
  }

   gotoNach(){
    this.router.navigate(['/residential-png/nach-online'],
    {queryParams:{data:'Nach'}})
  }

  gotoENach(){
    this.router.navigate(['/residential-png/nach-online'],
    {queryParams:{data:'ENach'}})
  }
  
  getStockData(){
    this.Service.getStockData().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      this.stock=data
      //console.log(data)
      if(data.Changes>0){
        
        this.NseColors = 'green'

      }else{
        this.NseColors = 'red'
      }

      if((data.BseChange).replace(/[(),%]/g, "")>0){
        
        this.BseColors = 'green'

      }else{
        this.BseColors = 'red'
      }

      //console.log((data.BseChange).replace(/[(),%]/g, "")>0)
      
    })
  }

}
