<!-- Menu Start -->
<div class="menu-area menu-sticky" id="navbar">
    <div class="container custom">
        <div class="row-table">
            <div class="col-cell header-logo">
                <div class="logo-area">
                    <a [routerLink]="['/']">
                        <img src="./assets/images/logo.png" alt="logo">
                    </a>
                </div>
            </div>
            <div class="col-cell">
                <div class="rs-menu-area">
                    <div class="main-menu">
                        <nav class="rs-menu hidden-md">
                            <ul class="nav-menu">
                                <!-- <li><a [routerLink]="['/']">Home </a></li> -->

                                <li class="menu-item-has-children">
                                    <a>Residential PNG </a>
                                    <ul class="sub-menu">
                                        <li class="menu-item-has-children dropdown-icon">
                                            <a>Customer Zone</a>

                                            <ul class="sub-menu">
                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-care/customer-care-overview']">Overview</a>
                                                </li>
                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-zone/customer-login']">Customer Login
                                                    </a>
                                                    <!-- <ul class="sub-menu" *ngIf="txt">
                                                        <li><a>View Your Bill</a> </li>
                                                        <li><a>Generate Your Bill</a></li>
                                                        <li><a [routerLink]="['/residential-png/customer-zone/online-payment']">Payment</a></li>
                                                    </ul> -->
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon" *ngIf="txt">
                                                    <a>Modes of Bill Payment
                                                    </a>
                                                    <ul class="sub-menu">
                                                        <li><a [routerLink]="['/payment-link']">Online Payment</a>
                                                        </li>
                                                       <!--  <li><a [routerLink]="['/residential-png/nach-online']">E-NACH/NACH</a></li> -->
                                                      <!--   <li><a href="https://paytm.com/gas-bill-payment?product_id=29970331&recharge_number=100111488">UPI AutoPay</a></li> -->
                                                    </ul>


                                                </li>

                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">Update Your Contact Details

                                                    </a>
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Name Transfer</a>
                                                    <ul class="sub-menu">
                                                        <li><a [routerLink]="['/residential-png/customer-zone/name-transfer']">Apply for Name Transfer </a> </li>
                                                        <li><a [routerLink]="['/residential-png/customer-zone/name-transfer/name-transfer-status']">Know your Application Status </a></li>
                                                    </ul>
                                                </li>
                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-zone/consumer-safty-checklist']">Consumer Safety
                                                        Checklists </a>
                                                </li>
                                                <li class="last-item menu-item-has-children">
                                                    <a class="popup-border" (click)="openPopup('BX8OZLb4k18')">Conservation tips for Domestic usage</a>
                                                </li>

                                            </ul>

                                        </li>
                                        <li class="menu-item-has-children dropdown-icon">
                                            <a>Domestic Connection Request</a>
                                            <ul class="sub-menu">


                                                          <li><a (click)="close()" [routerLink]="['residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request']">Apply For PNG Connection</a> </li>
                                                      
                                                        <!-- <li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check']">Connection Application Status</a></li> -->
                                                         <li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/domestic-and-institution-application_check']">Connection Application Status</a></li>
                                                         <li><a href="https://payments.billdesk.com/MercOnline/mgl/MGLBPDetails.jsp" target="_blank">Pay your Balance Registration Charges</a></li>

                                              <!--   <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Domestic PNG Registration
                                                    </a>
                                                    <ul class="sub-menu">
                                                        <li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']">Apply for PNG Connection</a> </li>
                                                        <li><a [routerLink]="['/residential-png/customer-zone/domestic-connection']">New PNG Connection Request</a></li>
                                                        <li><a>Balance Payment Option</a></li>
                                                        <li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check']">Connection Application Status</a></li>
                                                       
                                                    </ul>
                                                </li> -->
                                                <li>
                                                    <a [routerLink]="['/residential-png/domestic-connection-request/png-rate-card']">PNG Rate card</a>
                                                </li>

                                                <li>
                                                    <a [routerLink]="['/residential-png/domestic-connection-request/tech-guide-for-png-installation']">Technical
                                                        Guidelines for PNG Installation</a>
                                                </li>
                                                <li>
                                                    <a target="_blank"
                                                        href="https://www.mahanagargas.com:3000/regitration-form-terms-and-condition_0d27e650ee.pdf">Terms
                                                        & Condition of Domestic Users</a>
                                                </li>
                                                <li>
                                                    <a
                                                        [routerLink]="['/residential-png/domestic-connection-request/png-network']">PNG Network</a>
                                                </li>
                                            </ul>

                                        </li>
                                        <li class="menu-item-has-children">
                                            <a [routerLink]="['/residential-png/view-authorized-personnel']">View Authorized Personnel</a>
                                        </li>
                                         <li class="menu-item-has-children dropdown-icon">
                                            <a>Gas Geysers</a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <a href="assets/images/GasGeysersGuidelines/Gas Geysers (Instantaneous Water Heaters) - Guidelines for Installation - with comments - 13 09 24 - Clean Copy.docx" target="_blank">Guidelines</a>
                                                </li>
                                            </ul>

                                        </li>
                                       <!--  <li class="menu-item-has-children dropdown-icon">
                                            <a>Gas Geysers</a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <a target="_blank"
                                                        href="https://www.mahanagargas.com:3000/Gas-geyser-Guidelines-Annexure-I.pdf">Guidelines</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/residential-png/gas-geysers/rate-card']">Rate Card</a>
                                                </li>
                                            </ul>

                                        </li> -->
                                        <li class="menu-item-has-children dropdown-icon">
                                            <a>Policies & Procedures</a>
                                            <ul class="sub-menu">

                                                <li>
                                                    <a [routerLink]="['/residential-png/policies-and-procedures/important-policy']">Important Policies</a>
                                                   <!--  <ul class="sub-menu">
                                                        

                                                    </ul> -->
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a >Aftersales
                                                        Services</a>
                                                    <ul class="sub-menu">
                                                        <li><a [routerLink]="['/residential-png/policies-and-procedures/aftersales-services/png-service-charges']">Rate Card</a> </li>
                                                        <li><a [routerLink]="['/residential-png/policies-and-procedures/aftersales-services/service-provider']">Service provider's list</a></li>

                                                    </ul>
                                                </li>
                                                
                                            </ul>

                                        </li>
                                        <li>
                                            <a [routerLink]="['/residential-png/if-you-smell-gas']">If You Smell Gas</a>
                                        </li>

                                        <li class="menu-item-has-children dropdown-icon">
                                            <a>Customer Support</a>
                                            <ul class="sub-menu">
                                                <!-- <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-care/customer-care-overview']">Overview</a>
                                                </li> -->
                                                <li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/png-overview']">Overview</a>
                                                </li>
                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-care/walk-in-center']">Walk in Centers</a>
                                                </li>
                                                <!-- <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-care/customer-support']">Customer Support</a>
                                                </li> -->
                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-care/feedback-inquiry-form']">Feedback/Enquiry</a>
                                                </li>
                                                <li class="last-item menu-item-has-children">
                                                    <a [routerLink]="['/residential-png/customer-care/dropbox']">Drop Box</a>
                                                </li>
                                                
                                            </ul>

                                        </li>
                                        <li>
                                            <a (click)="this.FaqService.filterFaq('PNG FAQ')"
                                                (click)="this.FaqService.title='PNG FAQ'"
                                                [routerLink]="['/faq-list']">FAQ</a>
                                        </li>
                                        <li class="menu-item-has-children dropdown-icon">
                                            <a>Download Forms</a>

                                            <ul class="sub-menu">
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Registration Form</a>
                                                    <ul class="sub-menu">
                                                        <li><a  href="assets/images/pdf/MGL Registeration Form Full Payment Scheme.pdf" target="_blank">Full Payment</a> </li>
                                                        <li><a  href="assets/images/pdf/Fixed Daily Charges.pdf" target="_blank">FDC Scheme</a></li>
                                                        <li><a  href="assets/images/pdf/Equated Monthly Installement Scheme.pdf" target="_blank">EMI Scheme</a></li>
                                                         <!-- <li><a  href="assets/images/pdf/Equated Monthly Installement Scheme.pdf" target="_blank">Super Easy EMI</a></li> -->
                                                    </ul>
                                                </li>
                                                <!-- <li><a href="assets/images/pdf/Registeration Form- Normal (1).pdf" target="_blank">Registration Form</a> </li> -->
                                                <li><a href="assets/images/pdf/Transfer Form (6) (1).pdf" target="_blank">Name Transfer Form</a></li>

                                            </ul>
                                        </li>

                                        <li >
                                            <a [routerLink]="['/view-your-bill']">View Your Bill</a>

                                            
                                        </li>

                                         <li class="menu-item-has-children dropdown-icon">
                                            <a>PNG Offers/Schemes</a>

                                            <ul class="sub-menu">
                                                <li><a [routerLink]="['/residential-png/png-offers-schemes/png-scheme-offers']">PNG Scheme/Offers</a> </li>
                                                <!-- <li><a [routerLink]="['/residential-png/png-offers-schemes/khushiyan-lakhon-ki']">Khushiyan Lakhon Ki</a> </li> -->
                                                <li><a [routerLink]="['/residential-png/png-offers-schemes/khushiyan-lakhon-ki']">Khushiyan Lakhon Ki</a> 
                                                  <!--  <ul class="sub-menu">
                                                <li><a target="_blank" href="https://apps.mahanagargas.com/KLK/KLK.aspx">Winner’s List</a> </li>
                                                </ul> -->
                                                </li>
                                                <li><a [routerLink]="['/faq-list']">FAQs</a></li>

                                            </ul>
                                        </li>

                                    </ul>

                                </li>

                                <li class="menu-item-has-children">
                                    <a>CNG</a>
                                    <ul class="sub-menu">
                                        <li >
                                            <a [routerLink]="['/cng/overview/overview-enviro-benifits']">Overview</a>
                                           
                                        </li>
                                        <li class="dropdown-icon">
                                            <a>Customer Zone</a>
                                            <ul class="sub-menu">
                                                <li><a [routerLink]="['/cng/customer-zone/cng-rate-card']">CNG Rate Card</a></li>
                                                <li><a [routerLink]="['/cng/customer-zone/saving-calculator']">Saving Calculator</a></li>
                                                <li><a [routerLink]="['/cng/customer-zone/cng-locator']">CNG Locator</a></li>
                                                <!--  <li><a target="_blank" href="https://www.mahanagargas.com:3000/Leaflet MGL-TEZ Etoken.pdf">MGL Tez</a></li> -->
                                                 <li><a (click)="close()" [routerLink]="['/cng/customer-zone/mgl-tez']">MGL Tez</a></li>
                                                <li><a [routerLink]="['/cng/customer-zone/car-care']">Car Maintenance</a></li>
                                                <li><a [routerLink]="['/cng/customer-zone/cng-testing-center']">Cylinders Testing
                                                        Station</a>
                                                </li>
                                                <!-- <li><a [routerLink]="['/cng/customer-zone/cng-conversion-enquiry']">Conversion Enquiry
                                                        Form</a></li> -->
                                                <li><a [routerLink]="['/cng/customer-zone/cng-kit']">CNG Kit Supplier</a></li>
                                                <li><a (click)="this.FaqService.filterFaq('CNG FAQ')"
                                                        (click)="this.FaqService.title='CNG FAQ'"
                                                        [routerLink]="['/faq-list']">FAQs</a>
                                                </li>


                                            </ul>
                                        </li>

                                        <li class="dropdown-icon">
                                            <a>CNG Offers/Schemes</a>
											<ul class="sub-menu">
												
                                               <!--  <li class="dropdown-icon">
                                                    <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/mgl-mahotsav']">MGL CNG Mahotsav</a>
                                                    <ul class="sub-menu">
                                                         <li class="dropdown-icon">
                                                    <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-status']">OEMs Application Status</a>
                                                  
                                                </li>
                                                 <li class="dropdown-icon">
                                                 <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitment']">Retrofitters</a>
                                                   <ul class="sub-menu">
                                                  <li *ngIf="!this.UserService.loginStatus1" class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-login']">Retrofitter login</a></li>
                                                    <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-details']">Retrofitters Passenger/Car segment</a></li>
                                                   <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-status']">Retrofitter Application Status</a></li>
                                                   <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-booking-intimation']">Retrofitter Booking Intimation</a></li>
                                                    <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-booking-intimation-status']">Retrofitter Booking Intimation Status</a></li>
                                                     <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-bill-processing-status']">Retrofitter Bill Processing Status</a></li>
                                            </ul>
                                          </li>
                                                       
                                                     
                                                       
                                                       <li class="dropdown-icon">
                                                         <a>FAQs</a>
                                                        <ul class="sub-menu">
                                                          <li class="sub-menu"><a  [routerLink]="['/cng/cng-offers-schemes/retrofitment-faqs']">Retrofitment FAQs</a></li>
                                                         
                                                       </ul>
                                                       </li>
                                                    
                                                   </ul>
                                                </li> -->
                                             
                      

                                                <li class="dropdown-icon">
                                                    <a  (click)="close()" [routerLink]="['/cng/cng-offers-schemes/mgl-mahotsav']">MGL CNG Mahotsav 2.0</a>
                                                    <ul class="sub-menu">
                                                       <!--   <li class="dropdown-icon">
                                                    <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-status']">OEMs Application Status</a>
                                                   
                                                </li> -->
                                                 <li class="dropdown-icon">
                                                 <a>Retrofitters</a>
                                                   <ul class="sub-menu">
                                                  <li *ngIf="!this.UserService.loginStatus1" class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/retrofitters-login']">Retrofitter login</a></li>
                                                    <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-2.0-retrofitters-dashboard']">Retrofitters Dashboard</a></li>
                                                  
                                            </ul>
                                        </li>
                                        <li class="dropdown-icon">
                                                 <a>OEM</a>
                                                   <ul class="sub-menu">
                                                  <li *ngIf="!this.UserService.loginStatus2" class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/oem-login']">OEM Login</a></li>
                                                    <li class="sub-menu"><a [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-2.0-oem-dashboard']">OEM Dashboard</a></li>
                                                  
                                            </ul>
                                        </li>
                                        <li class="last-item menu-item-has-children">
                                        <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-2.0-oem-faqs']">FAQs</a>
                                       
                                    </li>

                                    

                                                       
                                                     
                                                       
                                                      
                                                     
                                                   </ul>
                                                </li>
                                                
											</ul>
                                        </li>
                                        <li class="dropdown-icon">
                                            <a>Dealer Zone</a>
                                            <ul class="sub-menu">
                                                 <!-- <li><a [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">Apply for New CNG Retail Outlets</a></li> -->
                                                 <li class="dropdown-icon">
                                                    <a>Apply for New CNG Retail Outlets</a>
                                                    <ul class="sub-menu">
                                                         <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">English</a></li>
                                                         <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/marathi-apply-for-cng-oulet']">Marathi</a></li>
                                                        
                                                       
                                                    </ul>
                                                    </li>
                                                  <li><a  [routerLink]="['/cng/dealer-zone/process-of-loi-issuance']">Process of LoI issuance</a></li>
                                                   <li><a  [routerLink]="['/cng/dealer-zone/faqs']">FAQs</a></li>
                                                    <li class="dropdown-icon">
                                                    <a>Retail Policy</a>
                                                    <ul class="sub-menu">
                                                         <li class="sub-menu"><a target="_blank" href="https://www.mahanagargas.com:3000/retail-policy.pdf">Retail Policy</a></li>
                                                        <li class="dropdown-icon">
                                                    <a>Dealer to Full DODO CNG Station Model</a>
                                                    <ul class="sub-menu">
                                                         <li class="sub-menu"><a href="assets/images/pdf/Addendum II Full Dealer Owned Dealer Operated Model.pdf"  target="_blank">Addendum II Full DODO CNG Station</a></li>
                                                        <li class="sub-menu"><a href="assets/images/pdf/FULL DODO CNG STATION ADDENDUM.pdf"  target="_blank">Full DODO CNG Station Addendum</a></li>
                                                        <li class="sub-menu"><a href="assets/images/pdf/Full DODO_MGL_RFP.PDF"  target="_blank">RFP for setting up CNG Station</a></li>
                                                        <li class="sub-menu"><a  href="assets/images/pdf/Full_DODO_MGL_RFP_new.pdf" target="_blank">Pre-bid clarifications</a></li>
                                                        <li class="sub-menu"><a  [routerLink]="['/cng/dealer-zone/steel-pipe-line-network-layout']">Steel pipeline network layout</a></li>
                                                    </ul>
                                                    </li>
                                                       
                                                    </ul>
                                                    </li>
                                                     <li class="dropdown-icon">
                                                    <a>Requirement of Open Plot</a>
                                                    <ul class="sub-menu">
                                                      <!--    <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/reqmt-open-slot']">GA3</a></li> -->
                                                        <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/open-plot-near-usar']">Usarghar</a></li>
                                                        <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/open-plot-near-jitegaon']">Jitegaon</a></li>
                                                       
                                                    </ul>
                                                    </li>
                                                     <li class="dropdown-icon">
                                                    <a>List of Documents Required</a>
                                                    <ul class="sub-menu">
                                                        <li class="sub-menu"><a  [routerLink]="['cng/dealer-zone/list-of-documents-required']">List of Documents Required</a></li>
                                                            <li class="dropdown-icon">
                                                    <a>Download Forms</a>
                                                    <ul class="sub-menu">
                                                         <li class="sub-menu"><a href="assets/images/pdf/Application Form_New CNG Retail Outlet.pdf"  target="_blank">Application Form of New CNG Retail Outlet</a></li>
                                                        <li class="sub-menu"><a href="assets/images/pdf/Undertaking Format_Applicant.docx"  target="_blank">Undertaking Format Applicant</a></li>
                                                        <li class="sub-menu"><a href="assets/images/pdf/Undertaking Format_Land Owner.doc"  target="_blank">  Undertaking Format Land Owner</a></li>
                                                    </ul>
                                                    </li>
                                                      
                                                       
                                                    </ul>
                                                    </li>
                                                  <!--    <li><a [routerLink]="['/cng/dealer-zone/mobile-unit']">Composite Dispensing Unit</a></li>

                                                  <li class="dropdown-icon">
                                                    <a>Feedback</a>
                                                    <ul class="sub-menu">
                                                         <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/give-feedback']">Feedback</a></li>
                                                        <li class="sub-menu"><a [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/cng-view-complaint-status']">Complaint Status</a></li>
                                                       </ul>
                                                    </li> -->
											
                                               
                                               
                                           

                                              

                                                
                                                 
                                                 

                                            </ul>
                                        </li>
                                        <li><a [routerLink]="['/cng/customer-zone/cng-conversion-enquiry']">Conversion Enquiry Form</a></li>
                                    </ul>
                                </li> 
                                <li class="menu-item-has-children">
                                    <a>Industrial & Commercial </a>
                                    <ul class="sub-menu">
                                        <li class="dropdown-icon"><a>Commercial PNG</a>
                                            <ul class="sub-menu">
                                                <li class="dropdown-icon"><a>Overview</a>
                                                    <ul class="sub-menu">
                                                        <li><a  [routerLink]="['/commercial-png-application']">Applications</a></li>
                                                        <li><a [routerLink]="['/industrial-and-commercial/commercial-png/overview/benefits']">Benefits</a>
                                                        </li>
                                                    </ul>

                                                </li>
                                                <li class="dropdown-icon"><a>Apply for Commercial Connection</a>
                                                    <ul class="sub-menu">
                                                        <!-- <li><a [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply for new
                                                                connection</a>
                                                        </li> -->
                                                        <li><a href="https://apps.mahanagargas.com/IC_Contract_Comm_B/IC_Contract_Comm_B/" target="_blank">Apply for new
                                                                connection</a>
                                                        </li>
                                                        <li><a [routerLink]="['/industrial-and-commercial/Commercial-png/apply-for-commercial-connection/how-to-apply']">How to Apply</a>
                                                        </li>
                                                        <li><a [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/document']">Documents Required</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                               <!--  <li><a href="assets/images/pdf/PNG Price for Commercial customer from July2023.pdf" target="_Blank">Tariff Card</a></li> -->
                                                   <li><a [routerLink]="['/industrial-and-commercial/commercial-png/commercial-tariff-card']">Tariff Card</a></li>
                                                <li><a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a></li>
												<!-- <li>
													<a (click)="this.FaqService.filterFaq('Commercial FAQ')"
														(click)="this.FaqService.title='Commercial FAQ'"
														[routerLink]="['/faq-list']">FAQs</a>
												</li> -->
                                                <li>
                                                    <a [routerLink]="['/industrial-and-commercial/commercial-png/faq-list']">FAQs</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown-icon"><a>Industrial PNG</a>
                                            <ul class="sub-menu">
                                                <li class="dropdown-icon"><a>Overview</a>
                                                    <ul class="sub-menu">
                                                        <li><a [routerLink]="['/industrial-and-commercial/industrial-png/overview/types-of-industries']">Type of
                                                                Industries</a></li>
                                                    </ul>
                                                </li>
                                                <li class="dropdown-icon">
                                                    <a>Apply for Industrial Connection</a>
                                                    <ul class="sub-menu">
                                                        <!-- <li><a [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply for new
                                                                connection</a></li> -->
                                                        <li><a href="https://apps.mahanagargas.com/IC_Contract/IC_Contract/login.aspx" target="_blank">Apply for new
                                                                connection</a></li>

                                                        <li>
                                                            <a [routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/how-to-apply-ind']">How to Apply</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/ind-document']">Documents Required</a>
                                                        </li>
                                                    </ul>
                                                </li>

<!--                                                 <li>
                                                    <a href="assets/images/pdf/PNG Price for Industrial customer from July2023.pdf" target="_blank">Tariff Card</a>
                                                </li> -->
                                                 <li>
                                                    <a [routerLink]="['/industrial-and-commercial/industrial-png/industrial-tariff-card']">Tariff Card</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a>
                                                </li>
                                                <li>
                                                    <a class="popup-border" (click)="openPopup('GhPW-HEVcGQ')">Conservation Tips for Industrial usage</a>
                                                </li>
												<!-- <li>
													<a (click)="this.FaqService.filterFaq('Industrial FAQ')"
														(click)="this.FaqService.title='Industrial FAQ'"
														[routerLink]="['/faq-list']">FAQs</a>
												</li> -->
                                                 <li>
                                                    <a [routerLink]="['/industrial-and-commercial/industrial-png/faq-list']">FAQs</a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <a [routerLink]="['/industrial-and-commercial/conservation-tips-for-industrial-usage/clien-tele']">Clientele</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/industrial-and-commercial/ic-bill']">View Your Bill</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/industrial-and-commercial/contact-us']">Contact Us</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="menu-item-has-children">
                                    <a>MGL Corporate </a>
                                    <ul class="sub-menu">
                                        <li><a [routerLink]="['/MGL-corporate/about-MGL']">About MGL</a></li>
                                        <li><a [routerLink]="['/MGL-corporate/vision']">Vision</a></li>
                                           <li><a [routerLink]="['/MGL-corporate/leadership']">Leadership</a></li>
                                        <li class="dropdown-icon"><a>Contracts & Procurements</a>
                                            <ul class="sub-menu">
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Tenders</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/active-tender']">Active Tenders
                                                                (New)</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/e-tender']">E-Tender (SAP-SRM)</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/empanelment-notice']">Empanelment Notice</a>
                                                        </li>
                                                         <li>
                                                            <a [routerLink]="['/MGL-corporate/contracts-and-procurements-tenders-expression-of-interest']"> Expression of Interest</a>
                                                        </li>
                                                        <li class="last-item menu-item-has-children dropdown-icon">
                                                            <a> Archives</a>
                                                            <ul class="sub-menu">
                                                                <li>
                                                                    <a [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/archives/tender']">Tenders</a>
                                                                </li>
                                                                <li>
                                                                    <a [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/archives/empanelment']"> Empanelment</a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a> MGL Statutory Details</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a target="_blank" href="assets/images/pdf/MGL GST REGISTRTAION CERTIFICATE_.pdf">GSTIN</a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="assets/images/pdf/MGL PAN CARD SCANNED COPY.pdf"> PAN</a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="assets/images/pdf/MGL Bank AC Details.pdf"> Bank A/C Details</a>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a> Important T&C of Tenders</a>
                                                    <ul class="sub-menu">
                                                        <li class="last-item menu-item-has-children dropdown-icon">
                                                            <a>General Conditions of Contract</a>
                                                            <ul class="sub-menu">
                                                                <li>
                                                                    <a target="_blank" href="assets/images/pdf/GCC  for Works.pdf">Works</a>
                                                                </li>
                                                                <li>
                                                                    <a target="_blank" href="assets/images/pdf/GCC for Materials.pdf"> Goods</a>
                                                                </li>
                                                                <li>
                                                                    <a target="_blank" href="assets/images/pdf/GCC FOR CONSUTANCY SERVICES.pdf"> Consultancy</a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/contracts-and-procurements/important-T&C-of-tenders/instruction-to-bidders']"> Instruction
                                                                to bidders</a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="assets/images/pdf/Code of Ethics.pdf"> Code of Ethics</a>
                                                        </li>
                                                        

                                                    </ul>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="assets/images/pdf/five-year-annual-procurement-plan-materials.pdf"> 5 Years Annual Procurement Plan - Materials</a>
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Downloads (Forms/Formats)</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a href="assets/images/pdf/EMD format.pdf" target="_blank">EMD Format</a>
                                                        </li>
                                                        <li class="last-item menu-item-has-children dropdown-icon">
                                                            <a [routerLink]="['/MGL-corporate/contracts-and-procurements/downloads/security-deposit']">Security Deposit
                                                                Formats</a>
                                                            <ul class="sub-menu">
                                                                <li>
                                                                    <a href="assets/images/pdf/SD for Works.pdf" target="_blank">Works</a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/images/pdf/SD for Materials.pdf" target="_blank">Materials</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a href="assets/images/pdf/MGL-Vendor Registration Form1.pdf" target="_blank"> Vendor Registration Form</a>
                                                        </li>
                                                        <li>
                                                            <a href="assets/images/pdf/ONLINE E PAYMENT FORM.pdf" target="_blank"> Online Payment Form</a>
                                                        </li>
                                                        <li>
                                                            <a href="assets/images/pdf/Mobilisation Advance Format.pdf" target="_blank"> Mobilisation Advance Format</a>
                                                        </li>
                                                        <li>
                                                            <a href="assets/images/pdf/Non disclosure Agreement.pdf" target="_blank"> Non Disclosure Agreement</a>
                                                        </li>
                                                        <li>
                                                            <a href="assets/images/pdf/Bid Security Declaration Form.pdf" target="_blank">Bid Security Declaration Form</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Inventory Management</a>
                                                    <ul class="sub-menu">
                                                        <li><a>Scrap Disposal</a></li>
                                                        <li><a>List of Surplus Items available for sale CGD/OEMs</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/contracts-and-procurements/bidders-on-holiday']">Bidders On Holiday</a>
                                                </li>
                                                  <li>
                                                    <a target="_blank" href="assets/images/pdf/Code of Conduct Policy for Business Partners.pdf">Code of Conduct Policy for Business Partners</a>
                                                    </li>
                                                    <li>
                                                    <a target="_blank" href="assets/images/pdf/Sustainable Supply chain and Responsible Sourcing Policy.pdf">Sustainable Supply chain and Responsible Sourcing Policy</a>
                                                                </li>
                                                <!-- <li><a>Others</a></li> -->
                                            </ul>
                                        </li>
                                        <li class="dropdown-icon"><a class="last-item menu-item-has-children">Investors</a>

                                            <ul class="sub-menu">
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Financial Results</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/financial-results/quarterly-result']">Quarterly Results</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/financial-results/audited-result']">Audited Results</a>
                                                        </li>
                                                         <!-- <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/financial-results/subsidiary-financials']">Subsidiary Financials</a>
                                                        </li> -->
                                                        <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Subsidiary Financials</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/financial-results/subsidiary-financials/unison-enviro-private-limited']">Unison Enviro Private Limited</a>
                                                        </li>
                                                         <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/financial-results/subsidiary-financials/mahanagar-lng-private-limited']">Mahanagar LNG Private Limited</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/annual-report']">Annual Reports</a>
                                                </li>
                                                 
                                                 <li>
                                                    <a target="_blank" href="assets/images/pdf/ESG Factbook 2022-23.pdf">ESG</a>
                                                        </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/annual-general-meeting']">Annual General Meetings</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/corporate-governance']">Corporate Governance</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/policies']">Policies</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/investor-guide']">Investor Guide</a>
                                                </li>
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Dividend</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/dividend/unclaimed-dividend']">Unclaimed Dividend</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/investors/dividend/dividend-policy']">Dividend Policy</a>
                                                        </li>
                                                        <!--<li>
                                                            <a target="_blank" href="assets/images/pdf/_DividendDistributionPolicy_f4049de0be.pdf">Dividend Declaration</a>
                                                        </li>-->
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/investor-update-and-presentation']">Investor Updates & Presentations</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/shareholding-pattern']">Shareholding Pattern</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/other-filings-with-stock-exchanges']">Other filings with Stock Exchanges</a>
                                                </li>
                                                 <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/quarterly-corporate-governance']">Quarterly Corporate Governance</a>
                                                </li>
                                                 <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/regulation-46-of-sebi']">Regulation 46 of SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015</a>
                                                </li>
                                                 <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/scheme-of-merger']">Scheme of Merger</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/investors/moa-and-aoa']">MOA & AOA</a>
                                                </li>
                                               

                                            </ul>


                                        </li>
                                        <li class="last-item menu-item-has-children dropdown-icon"><a >CSR </a>

                                            <ul class="sub-menu">
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/csr/csr-policy']">CSR Policies</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/csr/csr-initiative']">CSR Initiatives</a>
                                                </li>
                                                 <li>
                                                    <a [routerLink]="['/MGL-corporate/csr/csr-project']">CSR Projects</a>
                                                </li>
                                                <li>
                                                    <a href="assets/images/pdf/_csr-projects-2022-23_8d99969b42.DOCX" target="_blank">CSR Project Proposal format</a>
                                                </li>
                                                <!-- <li>
                                                    <a target="_blank" href="https://mahanagargas.com:3000/mgl-sparsh.pdf">CSR Sparsh</a>
                                                </li> -->
                                            </ul>

                                        </li>
                                        <li class="dropdown-icon"><a>Safety, Health and Environment </a>

                                            <ul class="sub-menu">
                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Policies</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/safety-health-and-environment/policies/hse-policy']">MGL HSE Policy</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/safety-health-and-environment/policies/mgl-quality-policy']">MGL Quality Policy</a>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/safety-health-and-environment/environmental-compliance']">Environmental Compliance</a>

                                                </li>

                                                <li class="last-item menu-item-has-children dropdown-icon">
                                                    <a>Safety & Sustainability</a>
                                                    <ul class="sub-menu">
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/our-approach-to-sustainability']">Our Approach to Sustainability</a>
                                                        </li>
                                                        <li>
                                                            <a [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/safety-as-core-value']">Safety As Core Value</a>
                                                        </li>
														<li>
                                                            <a [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/mgl-spirit']">MGL Spirit statement</a>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/safety-health-and-environment/stc-training']">Safety and Technical Competency (STC) Training</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-feature']">Safety Features</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/safety-health-and-environment/mgl-life-savers']">MGL Life Saving Rules</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/safety-health-and-environment/natural-gas-data-sheet']">Natural Gas: Material Safety Data Sheet</a>
                                                </li>
                                               <li>
                                                    <a [routerLink]="['/MGL-corporate/safety-health-and-environment/campaigns']">Campaigns</a>
                                                </li> 

                                            </ul>

                                        </li>

                                        <li><a [routerLink]="['/MGL-corporate/asset-integrity']">Asset Integrity</a>
                                        </li>

                                        <li class="last-item menu-item-has-children dropdown-icon"><a>Careers</a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/career/life-Mgl']">Life @ MGL</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/career/current-opp']">Current Opportunities</a>
                                                </li>

                                            </ul>
                                        </li>

                                        <li><a [routerLink]="['/MGL-corporate/awards']">Awards</a>
                                        </li>

                                        <li class="last-item menu-item-has-children dropdown-icon"><a>Media</a>
                                            <ul class="sub-menu">
                                                <li><a [routerLink]="['/MGL-corporate/media/news']">News</a></li>
                                                <li class="dropdown-icon">
                                                    <a>Gallery</a>
                                                    <ul class="sub-menu">
                                                        <li><a [routerLink]="['/MGL-corporate/media/gallery/images']">Images</a></li>
                                                        <li><a [routerLink]="['/MGL-corporate/media/gallery/video-gallary']">Videos</a></li>
                                                    </ul>
                                                </li>
                                                  <li><a [routerLink]="['/MGL-corporate/media/newsletters']">Newsletters</a></li>
                                                   <li><a [routerLink]="['/MGL-corporate/media/brochure']">Brochure</a> </li>

                                            </ul>
                                        </li>

                                        <li class="dropdown-icon"><a>Contact Us</a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <a [routerLink]="['/MGL-corporate/Contact-Us/registered-and-corporate-office-address']">Registered & Corporate Office Address</a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li><a [routerLink]="['/MGL-corporate/MGL-sahayogi']">MGL Sahayogi- Dial before Dig</a>
                                        </li>

                                    <!--      <li class="last-item menu-item-has-children dropdown-icon"><a >Expression of Interest </a>

                                            <ul class="sub-menu">
                                                <li>
                                                    <a target="_blank" href="https://www.mahanagargas.com:3000/Hindi - Invite for EOI_28.pdf">CBG – Hindi</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.mahanagargas.com:3000/English - Invite for EOI_28.pdf">CBG – English</a>
                                                </li>
                                               
                                                
                                            </ul>

                                        </li> -->
                                        <!--24-03-2025  <li><a [routerLink]="['/MGL-corporate/expression-of-interest']">Expression of Interest</a>
                                        </li> -->
										

                                    </ul>
                                </li>
                            </ul> <!-- //.nav-menu -->
                        </nav>
                    </div> <!-- //.main-menu -->
                </div>
            </div>
            <div class="mobile-user-login">
                    <div class="dropdown">
                              <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                               <i class="demo-icon uil-user"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login']">My Account</a></li>
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">Update Contact Details</a></li>
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/change-password']">Change Password</a></li>
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/log-complaint']">Log Complaint</a></li>
                                <li *ngIf="!this.UserService.loginStatus"><a (click)="login()" class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login']">User Login</a></li>
                                 
                                <li *ngIf="!this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/user-registration']">User Registration</a></li>
                                <li><hr class="dropdown-divider"></li> 
                                <li *ngIf="this.UserService.loginStatus"><a (click)="login()" class="dropdown-item">Logout</a></li>
                                 <li *ngIf="this.UserService.loginStatus1"><a (click)="login1()" class="dropdown-item">Retrofitters Logout</a></li>
                                      <li *ngIf="this.UserService.loginStatus2"><a (click)="login2()" class="dropdown-item">OEM Logout</a></li>
                              </ul>
                 </div> 
             </div>
            <div class="expand-btn-inner">
                    
            <div class="col-cell website-search">
                


                    <ul>
                         <li><!--USER DROPDOWN CODE-->
                            <!--<div class="dropdown">
                              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                               <i class="demo-icon uil-user"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a  class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login']">User Login</a></li>
                                <li><a class="dropdown-item" [routerLink]="['/user-registration']">User Registration</a></li>
                              </ul>
                            </div>-->
                            <!--USER DROPDOWN CODE-->
                         </li>
                        <li class="search-parent position-relative">
                            <div class="search-toggle {{ResponsiveSeach ? 'opened' : 'closed'}}">
                                <button (click)="openSearch()" class="search-icon icon-search"> <i class="demo-icon uil-search"></i></button>
                                <button (click)="openSearch()" class="search-icon icon-close"><i class="demo-icon uil-multiply"></i></button>
                              </div>
                            <div class="search-container {{ResponsiveSeach ? 'opened' : ''}}">
                                <form [formGroup]="userForm">
                                    <input #inputs formControlName="inputs" type="text" name="q" id="search-terms"
                                        placeholder="Search terms..." list="browsers"/>
                        
                                        <datalist id="browsers">
                                            <option *ngFor="let i of routerUrl" value="{{i.data.name}}">
                                        
                                          </datalist>
                                    <button (click)="navigateTo(inputs.value)" type="submit" name="submit" value="Go" class="search-icon"><i
                                            class="demo-icon uil-search"></i></button>
                                </form>
                          </div>
                        </li>
                        <li class="humburger">
                            <a id="nav-expander" class="nav-expander bar mobile-menu" (click)="open()">
                                <!-- <i class="demo-icon uil-align-center-alt"></i> -->
                             <div class="bar">
                                    <span class="dot1"></span>
                                    <span class="dot2"></span>
                                    <span class="dot3"></span>
                                    <span class="dot4"></span>
                                    <span class="dot5"></span>
                                    <span class="dot6"></span>
                                    <span class="dot7"></span>
                                    <span class="dot8"></span>
                                    <span class="dot9"></span>
                                </div> 
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Menu End -->

<!-- Canvas Mobile Menu start -->
<nav class="right_menu_togle mobile-navbar-menu" id="mobile-navbar-menu">
    <div class="close-btn">
        <a (click)="close()" id="nav-close2" class="nav-close">
            <div class="line">
                <span class="line1"></span>
                <span class="line2"></span>
            </div>
        </a>
    </div>
    <ul class="nav-menu" [ngStyle]="{'display':mobileMenuActive?'block' : 'none'}">
        <li class="menu-item-has-children">
            <a>Residential PNG </a>
            <ul class="sub-menu">
                <li class="menu-item-has-children">
                    <a>Customer Zone</a>

                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/customer-care/customer-care-overview']">Overview</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/customer-zone/customer-login']">Customer Login
                            </a>
                            <!-- <ul class="sub-menu" *ngIf="txt">
                                <li><a>View Your Bill</a> </li>
                                <li><a>Generate Your Bill</a></li>
                                <li><a (click)="close()" [routerLink]="['/residential-png/customer-zone/online-payment']">Payment</a></li>
                            </ul> -->
                        </li>
                        <li class="last-item menu-item-has-children" *ngIf="txt">
                            <a>Modes of Bill Payment
                            </a>
                            <ul class="sub-menu">
                                <li><a (click)="close()" [routerLink]="['/payment-link']">Online Payment</a>
                                </li>
                               <!--  <li><a (click)="close()" [routerLink]="['/residential-png/nach-online']">E-NACH/NACH</a></li> -->
                               <!--  <li><a href="https://paytm.com/gas-bill-payment?product_id=29970331&recharge_number=100111488">UPI AutoPay</a></li> -->
                            </ul>


                        </li>

                        <li>
                            <a (click)="close()" [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">Update Your Contact Details</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a>Name Transfer</a>
                            <ul class="sub-menu">
                                <li><a (click)="close()" [routerLink]="['/residential-png/customer-zone/name-transfer']">Apply for Name Transfer </a> </li>
                                <li><a (click)="close()" [routerLink]="['/residential-png/customer-zone/name-transfer/name-transfer-status']">Know your Application Status </a></li>
                            </ul>
                        </li>
                        <li>
                            <a (click)="close()" [routerLink]="['/residential-png/customer-zone/consumer-safty-checklist']">Consumer Safety
                                Checklists </a>
                        </li>
                        <li>
                            <a class="popup-border" (click)="openPopup('BX8OZLb4k18')">Conservation tips for Domestic usage</a>
                        </li>

                    </ul>

                </li>
                <li class="menu-item-has-children">
                    <a>Domestic Connection Request</a>
                    <ul class="sub-menu">

                       <!--  <li class="last-item menu-item-has-children">
                            <a>Domestic PNG Registration
                            </a>
                            <ul class="sub-menu">
                                <li><a (click)="close()" [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']">Apply for PNG Connection</a> </li>
                                <li><a (click)="close()" [routerLink]="['/residential-png/customer-zone/domestic-connection']">New PNG Connection Request</a></li>
                                <li><a>Balance Payment Option</a></li>
                                <li><a>Connection Application Status</a></li>
                                <li><a (click)="close()" [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/png-overview']">Overview</a>
                                </li>
                            </ul>
                        </li> -->
                        
                                                          <li><a (click)="close()" [routerLink]="['residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request']">Apply For PNG Connection</a> </li>
                                                      
                                                        <!-- <li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check']">Connection Application Status</a></li> -->
                                                        <li><a (click)="close()" [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/domestic-and-institution-application_check']">Connection Application Status</a></li>
                                                         <li><a href="https://payments.billdesk.com/MercOnline/mgl/MGLBPDetails.jsp" target="_blank">Pay your Balance Registration Charges</a></li>
                        <li>
                            <a (click)="close()" [routerLink]="['/residential-png/domestic-connection-request/png-rate-card']">PNG Rate card</a>
                        </li>

                        <li>
                            <a (click)="close()" [routerLink]="['/residential-png/domestic-connection-request/tech-guide-for-png-installation']">Technical
                                Guidelines for PNG Installation</a>
                        </li>
                        <li>
                            <a (click)="close()" target="_blank"
                                href="https://www.mahanagargas.com:3000/regitration-form-terms-and-condition_0d27e650ee.pdf">Terms
                                & Condition of Domestic Users</a>
                        </li>
                    </ul>

                </li>
                <li>
                    <a [routerLink]="['/residential-png/view-authorized-personnel']">View Authorized Personnel</a>
                </li>
                   <li class="menu-item-has-children">
                    <a>Gas Geysers</a>
                    <ul class="sub-menu">
                        <li><a (click)="close()" href="assets/images/GasGeysersGuidelines/Gas Geysers (Instantaneous Water Heaters) - Guidelines for Installation - with comments - 13 09 24 - Clean Copy.docx" target="_blank">Guidelines</a>
                        </li>
                        
                    </ul>

                </li>
              <!--   <li class="menu-item-has-children">
                    <a>Gas Geysers</a>
                    <ul class="sub-menu">
                        <li><a (click)="close()" target="_blank" href="https://www.mahanagargas.com:3000/Gas-geyser-Guidelines-Annexure-I.pdf">Guidelines</a>
                        </li>
                        <li><a (click)="close()" [routerLink]="['/residential-png/gas-geysers/rate-card']">Rate Card</a></li>
                    </ul>

                </li> -->
                <li class="menu-item-has-children">
                    <a>Policies & Procedures</a>
                    <ul class="sub-menu">

                        <li>
                            <a (click)="close()" [routerLink]="['/residential-png/policies-and-procedures/important-policy']">Important Policies</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a >Aftersales
                                Services</a>
                            <ul class="sub-menu">
                                <li><a (click)="close()" [routerLink]="['/residential-png/policies-and-procedures/aftersales-services/png-service-charges']">Rate Card</a> </li>
                                <li><a (click)="close()" [routerLink]="['/residential-png/policies-and-procedures/aftersales-services/service-provider']">Service provider's list</a></li>

                            </ul>
                        </li>
                    </ul>

                </li>
                <li>
                    <a (click)="close()" [routerLink]="['/residential-png/if-you-smell-gas']">If You Smell Gas</a>
                </li>

                <li class="menu-item-has-children">
                    <a>Customer Care</a>
                    <ul class="sub-menu">

                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/customer-care/walk-in-center']">Walk in Centers</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/customer-care/customer-support']">Customer Support</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/customer-care/feedback-inquiry-form']">Feedback/Enquiry</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/customer-care/dropbox']">Drop Box</a>
                        </li>
                    </ul>

                </li>
                <li class="menu-item-has-children">
                    <a (click)="close()" (click)="this.FaqService.filterFaq('PNG FAQ')"
                        (click)="this.FaqService.title='PNG FAQ'"
                        [routerLink]="['/faq-list']">FAQ</a>
                </li>
               

                  <li class="menu-item-has-children">
                                            <a>Download Forms</a>

                                            <ul class="sub-menu">
                                                <li class="last-item menu-item-has-children">
                                                    <a>Registration Form</a>
                                                    <ul class="sub-menu">
                                                        <li><a (click)="close()" href="assets/images/pdf/MGL Registeration Form Full Payment Scheme.pdf" target="_blank">Full Payment</a> </li>
                                                        <li><a (click)="close()" href="assets/images/pdf/Fixed Daily Charges.pdf" target="_blank">FDC Scheme</a></li>
                                                        <li><a (click)="close()" href="assets/images/pdf/Equated Monthly Installement Scheme.pdf" target="_blank">EMI Scheme</a></li>
                                                         <!-- <li><a  href="assets/images/pdf/Equated Monthly Installement Scheme.pdf" target="_blank">Super Easy EMI</a></li> -->
                                                    </ul>
                                                </li>
                                                <!-- <li><a href="assets/images/pdf/Registeration Form- Normal (1).pdf" target="_blank">Registration Form</a> </li> -->
                                                <li><a (click)="close()" href="assets/images/pdf/Transfer Form (6) (1).pdf" target="_blank">Name Transfer Form</a></li>

                                            </ul>
                                        </li>

                 <li class="menu-item-has-children">
                    <a>PNG Offers/Schemes</a>
                    <ul class="sub-menu">

                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/png-offers-schemes/png-scheme-offers']">PNG Scheme/Offers</a>
                        </li>
                         <!-- <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/png-offers-schemes/khushiyan-lakhon-ki']">Khushiyan Lakhon Ki</a>
                        </li> -->
                         <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/residential-png/png-offers-schemes/khushiyan-lakhon-ki']">Khushiyan Lakhon Ki</a>
                            <ul class="sub-menu">
                                 <li class="last-item menu-item-has-children">
                           <a (click)="close()" target="_blank" href="https://apps.mahanagargas.com/KLK/KLK.aspx">Winner’s List</a>
                        </li>
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/faq-list']">FAQs</a>
                        </li>
                       
                    </ul>

                </li>

            </ul>

        </li>
        <li class="menu-item-has-children">
            <a>CNG</a>
            <ul class="sub-menu">
                <li>
                    <a>Overview</a>

                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/overview/overview-enviro-benifits']">Overview +
                                Environmental Benefits</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a>Customer Zone</a>

                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-rate-card']">CNG Rate Card</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/saving-calculator']">Saving Calculator</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-locator']">CNG Locator</a>
                        </li>
                        <!--  <li><a (click)="close()" target="_blank" href="https://www.mahanagargas.com:3000/Leaflet MGL-TEZ Etoken.pdf">MGL Tez</a></li> -->
                         <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/mgl-tez']">MGL Tez</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/car-care']">Car Maintenance</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-testing-center']">Cylinders Testing
                                Station</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-conversion-enquiry']">Conversion Enquiry
                                Form</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-kit']">CNG Kit Supplier</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" (click)="this.FaqService.filterFaq('CNG FAQ')"
                                (click)="this.FaqService.title='CNG FAQ'"
                                [routerLink]="['/faq-list']">FAQs</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a>CNG Offers/Schemes</a>
                    <ul class="sub-menu">
                        <!-- <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/savings-ki-shubh-shuruaat']">Savings ki Shubh Shuruaat</a>
                        </li> -->
                       
						
                       

                        <!-- <li>
                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/mgl-mahotsav']">MGL CNG Mahotsav</a>
                                <ul class="sub-menu">
                                      <li class="last-item menu-item-has-children">
                                        <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-status']">OEMs Application Status</a>
                                      
                                     </li>
                                     <li class="last-item menu-item-has-children">
                                        <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitment']">Retrofitters</a>
                                        <ul class="sub-menu">
                                            <li *ngIf="!this.UserService.loginStatus1" class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-login']">Retrofitter login</a>
                                            </li>
                                             <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-details']">Retrofitters Passenger/Car segment</a>
                                            </li>
                                             <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-status']">Retrofitter Application Status</a>
                                            </li>
                                            <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-booking-intimation']">Retrofitter Booking Intimation</a>
                                            </li>
                                             <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-booking-intimation-status']">Retrofitter Booking Intimation Status</a>
                                            </li>
                                             <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-bill-processing-status']">Retrofitter Bill Processing Status</a>
                                            </li>
                                        </ul>
                                    </li>
                                     <li  class="last-item menu-item-has-children">
                                     <a>FAQs</a>
                                        <ul class="sub-menu">
                                          <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitment-faqs']">Retrofitment FAQs</a>
                                         </li>
                                          
                                        </ul>
                                     </li>
                      
                       
                           
                      
                                </ul>
                        </li> -->

                        <!--  <li class="last-item menu-item-has-children">
                            <a>Savings ki Shubh Shuruaat</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/savings-ki-shubh-shuruaat-Brochure']">New Uploads</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                     <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/application-status']">Application Status</a>
                                 </li>
                            </ul>
                        </li> -->

                     <li>
                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/mgl-mahotsav']">MGL CNG Mahotsav 2.0</a>
                                <ul class="sub-menu">
                                     <!--  <li class="last-item menu-item-has-children">
                                        <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-oems-new-vehicles-status']">OEMs Application Status</a>
                                       
                                     </li> -->
                                     <li class="last-item menu-item-has-children">
                                        <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitment']">Retrofitters</a>
                                        <ul class="sub-menu">
                                            <li *ngIf="!this.UserService.loginStatus1" class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/retrofitters-login']">Retrofitter login</a>
                                            </li>
                                             <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-2.0-retrofitters-dashboard']">Retrofitters Dashboard</a>
                                            </li>
                                            
                                        </ul>
                                    </li>
                                    <li class="last-item menu-item-has-children">
                                        <a>OEM</a>
                                        <ul class="sub-menu">
                                            <li *ngIf="!this.UserService.loginStatus2" class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/oem-login']">OEM Login</a>
                                            </li>
                                             <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-2.0-oem-dashboard']">OEM Dashboard</a>
                                            </li>
                                            
                                        </ul>
                                    </li>

                                    <li class="last-item menu-item-has-children">
                                        <a (click)="close()" [routerLink]="['/cng/cng-offers-schemes/cng-mahotsav-2.0-oem-faqs']">FAQs</a>
                                       
                                    </li>
                                    
                      
                                </ul>
                        </li>

                    </ul>
                </li>
                <li>
                    <a>Dealer Zone</a>
                    <ul class="sub-menu">
                         <!-- <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">Apply for New CNG Retail Outlets</a>
                             </li> -->
                             <li><a>Apply for New CNG Retail Outlets</a>
                            <ul class="sub-menu">
                               
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">English</a>
                                </li>
                                 <li class="last-item menu-item-has-children">
                                    <a (click)="close()"  [routerLink]="['/cng/dealer-zone/marathi-apply-for-cng-oulet']">Marathi</a>
                                </li>
                             
                              
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/dealer-zone/process-of-loi-issuance']">Process of LoI issuance</a>
                             </li>
                              <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/dealer-zone/faqs']">FAQs</a>
                             </li>
                             <li><a>Retail Policy</a>
                            <ul class="sub-menu">
                               
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()"  target="_blank" href="https://www.mahanagargas.com:3000/retail-policy.pdf">Retail Policy</a>
                                </li>
                                <li><a>Dealer to Full DODO CNG Station Model</a>
                            <ul class="sub-menu">
                               
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Addendum II Full Dealer Owned Dealer Operated Model.pdf"  target="_blank">Addendum II Full DODO CNG Station</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/FULL DODO CNG STATION ADDENDUM.pdf"  target="_blank">Full DODO CNG Station Addendum</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Full DODO_MGL_RFP.PDF"  target="_blank">RFP for setting up CNG Station</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()"  href="assets/images/pdf/Full_DODO_MGL_RFP_new.pdf" target="_blank">Pre-bid clarifications</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/cng/dealer-zone/steel-pipe-line-network-layout']">Steel pipeline network layout</a>
                                </li>
                            </ul>
                        </li>
                              
                            </ul>
                        </li>
                          <li><a>Requirement of Open Plot</a>
                            <ul class="sub-menu">
                               
                               <!--  <li class="last-item menu-item-has-children">
                                    <a (click)="close()"[routerLink]="['/cng/dealer-zone/reqmt-open-slot']">GA3</a>
                                </li> -->
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/cng/dealer-zone/open-plot-near-usar']">Usarghar</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()"  [routerLink]="['/cng/dealer-zone/open-plot-near-jitegaon']">Jitegaon</a>
                                </li>
                            </ul>
                        </li>
                        <li><a>List of Documents Required</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['cng/dealer-zone/list-of-documents-required']">List of Documents Required</a>
                                </li>
                                  <li><a>Download Forms</a>
                            <ul class="sub-menu">
                               
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Application Form_New CNG Retail Outlet.pdf"  target="_blank">Application Form of New CNG Retail Outlet</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Undertaking Format_Applicant.docx"  target="_blank">Undertaking Format Applicant</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()"  href="assets/images/pdf/Undertaking Format_Land Owner.doc"  target="_blank">Undertaking Format Land Owner</a>
                                </li>
                            </ul>
                        </li>
                            </ul>
                        </li>
                        <!--  <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/cng/dealer-zone/mobile-unit']">Composite Dispensing Unit</a>

                        </li>
                       
                          <li><a>Feedback</a>
                            <ul class="sub-menu">
                               
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/give-feedback']">Feedback</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/cng-view-complaint-status']">Complaint Status</a>
                                </li>
                              
                            </ul>
                        </li> -->
						</ul>
                </li>
                <li>
                    <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-conversion-enquiry']">Conversion Enquiry Form</a>
                </li>
            </ul>
        </li>
        <li class="menu-item-has-children">
            <a>Industrial & Commercial </a>
            <ul class="sub-menu">
                <li><a>Commercial PNG</a>
                    <ul class="sub-menu">
                        <li><a>Overview</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()"
                                        [routerLink]="['/commercial-png-application']">Applications</a>

                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/overview/benefits']">Benefits</a>

                                </li>
                            </ul>

                        </li>
                        <li><a>Apply for Commercial Connection</a>
                            <ul class="sub-menu">
                                <!-- <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply for new
                                        connection</a>
                                </li> -->
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="https://apps.mahanagargas.com/IC_Contract_Comm_B/IC_Contract_Comm_B/" target="_blank">Apply for new
                                        connection</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/Commercial-png/apply-for-commercial-connection/how-to-apply']">How to Apply</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/document']">Documents Required</a>
                                </li>
                            </ul>
                        </li>
                        <!-- <li><a (click)="close()" href="assets/images/pdf/PNG Price for Commercial customer from July2023.pdf" target="_Blank">Tariff Card</a></li> -->
                          <li><a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/commercial-tariff-card']" >Tariff Card</a></li>
                        <li><a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a></li>
						<!-- <li class="last-item menu-item-has-children">
                            <a (click)="close()" (click)="this.FaqService.filterFaq('Commercial FAQ')"
                                (click)="this.FaqService.title='Commercial FAQ'"
                                [routerLink]="['/faq-list']">FAQs</a>
                        </li> -->
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/faq-list']">FAQs</a>
                        </li>
                    </ul>
                </li>
                <li><a>Industrial PNG</a>
                    <ul class="sub-menu">
                        <li><a>Overview</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/industrial-png/overview/types-of-industries']">Type of
                                        Industries</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a>Apply for Industrial Connection</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="https://apps.mahanagargas.com/IC_Contract/IC_Contract/login.aspx" target="_blank">Apply for new connection</a>
                                </li>
                               <!--  <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply for new
                                        connection</a>
                                </li> -->
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/how-to-apply-ind']">How to Apply</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/ind-document']">Documents Required</a>
                                </li>
                            </ul>
                        </li>

                      <!--   <li>
                            <a (click)="close()" href="assets/images/pdf/PNG Price for Industrial customer from July2023.pdf" target="_blank">Tariff Card</a>
                        </li> -->
                         <li>
                            <a (click)="close()" [routerLink]="['/industrial-and-commercial/industrial-png/industrial-tariff-card']">Tariff Card</a>
                        </li>
                        <li>
                            <a (click)="close()" [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a>
                        </li>
                        <li>
                            <a class="popup-border" (click)="openPopup('GhPW-HEVcGQ')">Conservation Tips for Industrial usage</a>
                        </li>
						<!-- <li class="last-item menu-item-has-children">
                            <a (click)="close()" (click)="this.FaqService.filterFaq('Industrial FAQ')"
                                (click)="this.FaqService.title='Industrial FAQ'"
                                [routerLink]="['/faq-list']">FAQs</a>
                        </li> -->
                         <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/industrial-and-commercial/industrial-png/faq-list']">FAQs</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/conservation-tips-for-industrial-usage/clien-tele']">Clientele</a>

                </li>
                <li>
                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/ic-bill']">View Your Bill</a>
                </li>
                <li>
                    <a (click)="close()" [routerLink]="['/industrial-and-commercial/contact-us']">Contact Us</a>
                </li>
            </ul>
        </li>
        <li class="menu-item-has-children">
            <a>MGL Corporate </a>
            <ul class="sub-menu">
                <li><a (click)="close()" [routerLink]="['/MGL-corporate/about-MGL']">About MGL</a></li>
                <li><a [routerLink]="['/MGL-corporate/vision']">Vision</a></li>
                 <li><a (click)="close()" [routerLink]="['/MGL-corporate/leadership']">Leadership</a></li>
                <li><a>Contracts & Procurements</a>
                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a>Tenders</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/active-tender']">Active Tenders
                                        (New)</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/e-tender']">Active Tenders
                                        (New)</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/empanelment-notice']"> Empanelment Notice</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements-tenders-expression-of-interest']">Expression of Interest</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a> Archives</a>
                                    <ul class="sub-menu">
                                        <li class="last-item menu-item-has-children">
                                            <a  (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/archives/tender']">Tenders</a>
                                        </li>
                                        <li class="last-item menu-item-has-children">
                                            <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/archives/empanelment']"> Empanelment</a>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a> MGL Statutory Details</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" target="_blank" href="assets/images/pdf/MGL GST REGISTRTAION CERTIFICATE_.pdf">GSTIN</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" target="_blank" href="assets/images/pdf/MGL PAN CARD SCANNED COPY.pdf"> PAN</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" target="_blank" href="assets/images/pdf/MGL Bank AC Details.pdf"> Bank A/C Details</a>
                                </li>

                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a> Important T&C of Tenders</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a>General Conditions of Contract</a>
                                    <ul class="sub-menu">
                                        <li class="last-item menu-item-has-children">
                                            <a (click)="close()" target="_blank" href="assets/images/pdf/GCC  for Works.pdf">Works</a>
                                        </li>
                                        <li class="last-item menu-item-has-children">
                                            <a (click)="close()" target="_blank" href="assets/images/pdf/GCC for Materials.pdf"> Goods</a>
                                        </li>
                                        <li class="last-item menu-item-has-children">
                                            <a (click)="close()" target="_blank" href="assets/images/pdf/GCC FOR CONSUTANCY SERVICES.pdf"> Consultancy</a>
                                        </li>

                                    </ul>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/important-T&C-of-tenders/instruction-to-bidders']"> Instruction
                                        to bidders</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" target="_blank" href="assets/images/pdf/Code of Ethics.pdf"> Code of Ethics</a>
                                </li>
                               

                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" target="_blank" href="assets/images/pdf/five-year-annual-procurement-plan-materials.pdf"> 5 Years Annual Procurement Plan - Materials</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a>Downloads (Forms/Formats)</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/EMD format.pdf" target="_blank">EMD Format</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/downloads/security-deposit']">Security Deposit
                                        Formats</a>
                                    <ul class="sub-menu">
                                        <li class="last-item menu-item-has-children">
                                            <a (click)="close()" href="assets/images/pdf/SD for Works.pdf" target="_blank">Works</a>
                                        </li>
                                        <li class="last-item menu-item-has-children">
                                            <a (click)="close()" href="assets/images/pdf/SD for Materials.pdf" target="_blank">Materials</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/MGL-Vendor Registration Form1.pdf" target="_blank"> Vendor Registration Form</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/ONLINE E PAYMENT FORM.pdf" target="_blank"> Online Payment Form</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Mobilisation Advance Format.pdf" target="_blank"> Mobilisation Advance Format</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Non disclosure Agreement.pdf" target="_blank"> Non Disclosure Agreement</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" href="assets/images/pdf/Bid Security Declaration Form.pdf" target="_blank">Bid Security Declaration Form</a>
                                </li>
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a>Inventory Management</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a>Scrap Disposal</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a>List of Surplus Items available for sale CGD/OEMs</a>
                                </li>
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/contracts-and-procurements/bidders-of-holiday']">Bidders of Holiday</a>
                        </li>
                         <li class="last-item menu-item-has-children">
                                            <a (click)="close()" target="_blank" href="assets/images/pdf/Code of Conduct Policy for Business Partners.pdf">Code of Conduct Policy for Business Partners</a>
                                        </li>
                                         <li class="last-item menu-item-has-children">
                                            <a (click)="close()" target="_blank" href="assets/images/pdf/Sustainable Supply chain and Responsible Sourcing Policy.pdf">Sustainable Supply chain and Responsible Sourcing Policy</a>
                                        </li>
                        <!-- <li class="last-item menu-item-has-children">
                            <a>Others</a>
                        </li> -->
                    </ul>
                </li>
                <li><a>Investors</a>

                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a>Financial Results</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/investors/financial-results/quarterly-result']">Quarterly Results</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-Corporate/Investors/Financial-Results/audited-result']">Audited Results</a>
                                </li>
                                <!-- <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/investors/financial-results/subsidiary-financials']">Subsidiary Financials</a>
                                </li> -->
                                <li class="last-item menu-item-has-children">
                            <a>Subsidiary Financials</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/investors/financial-results/subsidiary-financials/unison-enviro-private-limited']">Unison Enviro Private Limited</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/investors/financial-results/subsidiary-financials/mahanagar-lng-private-limited']">Mahanagar LNG Private Limited</a>
                                </li>
                                
                            </ul>
                        </li>
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/annual-report']">Annual Reports</a>
                        </li>
                        
                         <li class="last-item menu-item-has-children">
                            <a (click)="close()" target="_blank" href="assets/images/pdf/ESG Factbook 2022-23.pdf">ESG</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/annual-general-meeting']">Annual General Meetings</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/corporate-governance']">Corporate Governance</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/policies']">Policies</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/investor-guide']">Investor Guide</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a>Dividend</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/investors/dividend/unclaimed-dividend']">Unclaimed Dividend</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/investors/dividend/dividend-policy']">Dividend Policy</a>
                                </li>
                                <!--<li class="last-item menu-item-has-children">
                                    <a target="_blank" href="assets/images/pdf/_DividendDistributionPolicy_f4049de0be.pdf">Dividend Declaration</a>
                                </li>-->
                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/investor-update-and-presentation']">Investor Updates & Presentations</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/shareholding-pattern']">Shareholding Pattern</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a [routerLink]="['/MGL-corporate/investors/other-filings-with-stock-exchanges']">Other filings with Stock Exchanges</a>
                        </li>
                         <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/quarterly-corporate-governance']">Quarterly Corporate Governance</a>
                        </li>
                         <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/regulation-46-of-sebi']">Regulation 46 of SEBI (Listing Obligations and Disclosure Requirements) Regulations, 2015</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/scheme-of-merger']">Scheme of Merger</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/investors/moa-and-aoa']">MOA & AOA</a>
                        </li>

                    </ul>
                </li>
                <li><a>CSR </a>

                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/csr/csr-policy']">CSR Policies</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/csr/csr-initiative']">CSR Initiatives</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/csr/csr-project']">CSR Projects</a>
                        </li>
                         <li>
                         <a href="assets/images/pdf/_csr-projects-2022-23_8d99969b42.DOCX" target="_blank">CSR Project Proposal format</a>
                        </li>
                        <!-- <li class="last-item menu-item-has-children">
                            <a (click)="close()" target="_blank" href="https://mahanagargas.com:3000/mgl-sparsh.pdf">CSR
                                Sparsh</a>
                        </li> -->
                    </ul>

                </li>
                <li><a>Safety, Health and Environment </a>

                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a>Policies</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/policies/hse-policy']">MGL HSE Policy</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/policies/mgl-quality-policy']">MGL Quality Policy</a>
                                </li>

                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/environmental-compliance']">Environmental Compliance</a>

                        </li>

                        <li class="last-item menu-item-has-children">
                            <a>Safety & Sustainability</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/our-approach-to-sustainability']">Our Approach to Sustainability</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/safety-as-core-value']">Safety As Core Value</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/mgl-spirit']">MGL Spirit statement</a>
                                </li>
                            </ul>
                        </li>

                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/stc-training']">Safety and Technical Competency (STC) Training</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/safety-feature']">Safety Features</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/mgl-life-savers']">MGL Life Saving Rules</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/natural-gas-data-sheet']">Natural Gas: Material Safety Data Sheet</a>
                        </li>
                          <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/safety-health-and-environment/campaigns']">Campaigns</a>
                        </li>

                    </ul>

                </li>

                <li><a (click)="close()" [routerLink]="['/MGL-corporate/asset-integrity']">Asset Integrity</a>
                </li>

                <li><a>Careers</a>
                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/career/life-Mgl']">Life @ MGL</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/career/current-opp']">Current Opportunities</a>
                        </li>
                        
                    </ul>
                </li>

                <li><a (click)="close()" [routerLink]="['/MGL-corporate/awards']">Awards</a>
                </li>

                <li><a>Media</a>
                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/media/news']">News</a>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a>Gallery</a>
                            <ul class="sub-menu">
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/media/gallery/images']">Images</a>
                                </li>
                                <li class="last-item menu-item-has-children">
                                    <a (click)="close()" [routerLink]="['/MGL-corporate/media/gallery/video-gallary']">Videos</a>
                                </li>

                            </ul>
                        </li>
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/media/newsletters']">Newsletters</a>
                        </li>
                         <li class="last-item menu-item-has-children"><a (click)="close()" [routerLink]="['/MGL-corporate/media/brochure']">Brochure</a> </li>

                    </ul>
                </li>

                <li><a>Contact Us</a>
                    <ul class="sub-menu">
                        <li class="last-item menu-item-has-children">
                            <a (click)="close()" [routerLink]="['/MGL-corporate/Contact-Us/registered-and-corporate-office-address']">Registered & Corporate Office Address</a>
                        </li>
                    </ul>
                </li>

                <li><a (click)="close()" [routerLink]="['/MGL-corporate/MGL-sahayogi']">MGL Sahayogi- Dial before Dig</a>
                </li>
                <!-- 24-03-2025 <li><a (click)="close()" [routerLink]="['/MGL-corporate/expression-of-interest']">Expression of Interest</a>
                                        </li> -->


                <!--   <li class="last-item menu-item-has-children dropdown-icon"><a >Expression of Interest </a>

                                            <ul class="sub-menu">
                                                <li>
                                                    <a target="_blank" href="https://www.mahanagargas.com:3000/Hindi - Invite for EOI_28.pdf">CBG – Hindi</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.mahanagargas.com:3000/English - Invite for EOI_28.pdf">CBG – English</a>
                                                </li>
                                               
                                                
                                            </ul>

                                        </li> -->


            </ul>
        </li>

    </ul> <!-- //.nav-menu -->
    <div class="canvas-contact">
        <div class="address-area">
            <div class="address-list">
                <div class="info-icon">
                    <i class="demo-icon uil-calling"></i>
                </div>
                <div class="info-content">
                    <h4 class="title"> Emergency No: 1800 266 9944 / MGL Sahayogi: 1800 2100 2100</h4>
                    <!-- <em> 1800 266 9944 / 6867 4500</em> -->
                </div>
            </div>
            <div class="cng-rate-mobileview mt-15 mb-15">
                <p><a href="JavaScript:void(0)">CNG Rate: <!--{{CngPrice}}--> ₹ 78/Kg</a></p>
                <p>PNG Rate: ₹ 48/SCM</p>
            </div>
            <div class="quick-pay-btn">
                <a (click)="close()" [routerLink]="['/quick-bill-pay']"><i class="demo-icon uil-lightbulb-alt"></i> Quick Bill Pay</a>
                <a (click)="close()" [routerLink]="['/cng/customer-zone/cng-locator']"><i class="demo-icon uil-location-pin-alt"></i> CNG Locator</a>
                <select (change)="redirecturl(lang.value)" #lang (change)="selected=lang.value" class="form-select" aria-label=".form-select-lg example">
                    <option disabled >Select Language</option>
                     <option selected>English</option>
                    <!-- <option *ngFor="let i of langauge" value="{{i.url}}" hidden="{{selected == i ? 'hidden':''}}">{{i.lang}}</option> -->
                    
                </select> 
                
            </div>
           
            
            
        </div>
    </div>
</nav>
<!-- Canvas Menu end -->

<div class="modal fade videogallery {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div (click)="this.closeModel()" class="modal-dialog modal-xl modal-video">

        <div class="modal-content">
            <button (click)="this.closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    
                    <iframe  [src]="safeSrc" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>