<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>PNG Rate Card</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Domestic Connection Request</a></li>
                <li class="active" aria-current="page"><a >PNG Rate Card
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <app-insta-post></app-insta-post>
                <!-- <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Recent Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border mb-30">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/blog/inner/style2/1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                </div>
                            </div>

                            <a href="#" class="greenbtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="customer-slider mt-30 mb-30">
                    <owl-carousel-o [options]="networkOptions">
                        <ng-template carouselSlide>
                            <div  class="blog-item">
                                <div class="blog-content">
                                    <div class="number">1</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Open all Doors and Windows</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img1.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div  class="blog-item">
                                <div class="blog-content">
                                    <div class="number">2</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Turn off your gas supply by using Meter Control Valve (MCV)</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img2.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">3</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Do not switch on or off any electrical switch</p>
                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img3.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="blog-item">
                                <div class="blog-content">
                                    <div class="number">4</div>
                                    <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                    <p class="desc">Extinguish all naked flames</p>

                                </div>
                                <div class="image-wrap">
                                    <a href="#"><img src="assets/images/safty-img4.jpg" alt="Blog"></a>
                                </div>
                            </div>
                        </ng-template>
                        <div class="blog-item">
                            <div class="blog-content">
                                <div class="number">5</div>
                                <h3 class="blog-title"><a href="#">If you Smell Gas</a></h3>
                                <p class="desc">Call emergency service no 18002669944 (Toll free) / (022)-68759400 /
                                    (022)-24012400 after coming out from the danger area</p>

                            </div>
                            <div class="image-wrap">
                                <a href="#"><img src="assets/images/safty-img5.jpg" alt="Blog"></a>
                            </div>
                        </div>

                    </owl-carousel-o>

                </div> -->


               <!--  <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section mb-lg-0 mb-30">
				
				<h6>THE DOMESTIC PNG PRICE WITH EFFECT FROM APRIL 9<sup>th</sup> 2025.</h6>
                    
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>AREA</th>
                                    <th>MRP (₹./SCM)</th>
                                     <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mumbai and its adjoining areas</td>
                                    <td>₹ 49.00 </td>
                                    <td>
                                        <a class="greenbtn2" href="assets/images/pdf/PNG Price Breakup.pdf" target="_Blank">Price break-up <i class="demo-icon uil-refresh"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--  <div class="text-right" style="margin-bottom: 10px;">
                    <a class="greenbtn2" href="assets/images/pdf/PNG Price Breakup.pdf" target="_Blank">Price break-up <i class="demo-icon uil-refresh"></i></a> 
                    </div> -->
                    
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SR. NO</th>
                                    <th>PARTICULARS</th>
                                    <th>CHARGE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Application Charges</td>
                                    <td>₹  750 + 18% GST (9%CGST + 9%SGST)</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Security Deposit for connection</td>
                                    <td>₹  5000/- per registration (₹ 4500/- at the time of registration and balance ₹ 500/- to be paid in first bill.)</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Gas Consumption Security Deposit*</td>
                                   <!--  <td>₹  750/-</td> -->
                                    <td>₹  1000/-</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Re-Connection Charges (PNG Supply is disconnected from inside with Red Lock)</td>
                                    <td>₹  1,628/- + 18% GST (9%CGST + 9%SGST)</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Re-Connection Charges (In case PNG Supply is disconnected from outside of the customers  premise)</td>
                                    <td>Rs.4953.64/-  </td>
                                    <!-- <td>Rs.4230/-  </td> -->
                                </tr>
                                <tr>
                                    <td rowspan="3">6</td>
                                    <td rowspan="3">Permanent Disconnection Charges</td>
                                  
                                    
                                </tr>
                                <tr>
                                    <!-- <td>1.Rs.1800/- for Dadar to Bhayandar, Powai Chandivali,<br>
                                    The contract is valid from 01.09.2023 to 31.08.2024. PD charges will also include +18% GST per connection additionally.</td> -->
                                    <td>1.Rs.1690/- for Dadar to Bhayander, Powai Chandivali,,<br>
                                    The contract is valid from 15.08.2024 to 31.07.2026 .PD charges will also include +18% GST per connection additionally.</td>
                                </tr>
                                  <tr>
                                    <!-- <td>2.Rs.900/- for Navi Mumbai, Kalyan, Dombivali, Ambernath, Badlapur, Raigad, Kalamboli, Panvel, Taloja, Thane.<br>
                                        The contract is valid from 16.07.2022 to 15.07.2024. PD charges will also include +18% GST per connection additionally.</td> -->
                                        <td>2. Rs.1400/- for Navi Mumbai,Kalyan,Dombivali,Ambernath,Badalapur,Raigad,Kalamboli,Panvel,Taloja,Thane, Mulund to CSMT & Elphinstone road to Charchgate.<br>
                                        The contract is valid from 15.08.2024 to 12.08.2026 .PD charges will also include +18% GST per connection additionally.</td>
                                </tr>
                                 <!--  <tr>
                                    <td>3.Rs.743.50/- for Mulund to CSMT & Elphinstone Road to Churchgate.<br>
                                    The contract is valid from 16.07.2022 to 15.07.2024. PD charges will also include +18% GST per connection additionally.</td>
                                </tr> -->
                                <tr>
                                    <td>7</td>
                                    <td>Name Transfer Charges</td>
                                    <td>₹  350 + 18% GST (9%CGST + 9%SGST)</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Cheque Bouncing Charges</td>
                                    <td>₹  200/-</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Minimum Monthly charges</td>
                                    <td>₹ 50 + 18% GST (9%CGST + 9%SGST) per month is charged. Bills are raised on Bimonthly basis.</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Delayed Payment Charges</td>
                                    <td>₹  100/- (for the payment not realized by MGL till the due date)</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Interest charges on Delayed Payment</td>
                                    <td>An interest of 18% p.a for the amount unpaid beyond 10 working days from the due date till the date of settlement of the payment or part thereof.</td>
                                </tr>
                               <!--  <tr>
                                    <td>12</td>
                                    <td>Extra Copper charges beyond 5 meters</td>
                                    <td>₹  159.10/- per metre + 18% GST (9%CGST + 9%SGST)</td>
                                </tr> -->
                                 <!-- <tr>
                                    <td>12</td>
                                    <td>Extra Copper charges beyond 5 meters</td>
                                  
                                    <td>a.Copper tube (straight)-₹275/-per metre + 18% GST (9%CGST + 9%SGST) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    b.Copper tube (coil)- ₹325/-per metre + 18% GST (9%CGST + 9%SGST)</td>
                                  
                                    
                                </tr> -->
                                
                                <tr>
                                
                                <td rowspan="3">12</td>
                                 <td rowspan="3">Extra Copper charges beyond 5 meters</td>
                                
                                 
                                </tr>
                                <tr>
                                    <td>a.Copper tube (straight)-₹275/-per metre + 18% GST (9%CGST + 9%SGST)</td>
                                </tr>
                                <tr>
                                    <td>b.Copper tube (coil)- ₹325/-per metre + 18% GST (9%CGST + 9%SGST)</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Domestic Meter checking charges</td>
                                    <td>₹ 401/-</td>
                                </tr>
                                 <!--  <tr>
                                    <td>14</td>
                                    <td>Mumbai and its adjoining areas</td>
                                    <td>₹ ₹ 48.00/-</td>
                                </tr> -->
                               
                            </tbody>
                        </table>
                    </div>

                    
                   <!--  <div class="text-right">
                    <a class="greenbtn2" href="assets/images/pdf/PNG Price Breakup.pdf" target="_Blank">Price break-up <i class="demo-icon uil-refresh"></i></a> 
                    </div> -->

                    <div class="mt-30 mb-30">
                        <div class="light-blue-bg">
                        <h6 class="font-size16">* Gas Consumption Security Deposit</h6>
                        <div class="blue-tick-list">
                            <ul>
                                <li>Every customer is required to furnish Security Deposit (SD) ₹. 1000- towards gas consumption at the time of Application submission (or during the year when such demand is raised)</li>
                                <li>If gas consumption goes over and above the gas consumption security deposit with MGL, the same shall be revised periodically and will be mentioned in subsequent bills. The request for differential Security Deposit will be raised in the bills of May and June every year as onetime charges.</li>
                                <li>The differential SD shall be levied in multiples of ₹. 100/-</li>
                                <li>Failure to pay the revised SD ( additional) will be considered as default and will be liable to disconnection</li>
                                <li>In case of disconnection, charges for disconnection and reconnection shall apply prior to reconnection</li>
                            </ul>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>