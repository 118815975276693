import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-withoutloginlogmeterreading',
  templateUrl: './withoutloginlogmeterreading.component.html',
  styleUrls: ['./withoutloginlogmeterreading.component.css']
})
export class WithoutloginlogmeterreadingComponent {
 alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'
  BillingData:any

  options:any[]=[]
  data:any
  UserForm!:FormGroup
  submitted:boolean = false
  isShow: boolean = false;
  isformShow: boolean = false;
  SearchForm!:FormGroup
  searchsubmitted:boolean = false;
  customerDate:any;
  
  constructor(private Service :DashboardServiceService,private pageLoaderService: PageLoaderService, private FormBuilder:FormBuilder,private router: Router) { }

  ngOnInit(): void {

    this.options=['Assesed reading','Bill not recieved since commencement of gas','Bill recieved on actual consumption but reading differs',
    'Flat generally not occupied','Not available at home during the office hours','Others']

    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    let data = ''

     let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=JSON.parse(decryptedString)
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    
    this.UserForm=this.FormBuilder.group({
      Email:['',[Validators.required,Validators.email]],
      name:['',[Validators.required]],
      PreMeterReading:[''],
      MeterReadingWindow:[''],
      PreMeterReadingDate:[''],
      MeterFrom:['',[Validators.required]],
      MeterTo:['',[Validators.required]],
      Reading:[''],
      BPno:[''],
      CAno:[''],
      Reading1:['',[Validators.required]],
      Reading2:['',[Validators.required]],
      Reading3:['',[Validators.required]],
      Reading4:['',[Validators.required]],
      Reading5:['',[Validators.required]],
      Reading6:['',[Validators.required]],
      Reading7:['',[Validators.required]],
      Reading8:['',[Validators.required]],
	  Phone: [''],
      Reason:['',[Validators.required]],
      Datess:['',[Validators.required]],
      RegName: ['']
    })

    this.SearchForm=this.FormBuilder.group({
     BPno:['',[Validators.required]],
     
    })
    
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  getBillinginfo(){
     this.searchsubmitted=true
     if(this.SearchForm.invalid){
      return
    }

          this.Service.getUsernameforlogmeter({BPnoCAno:this.SearchForm.get('BPno')?.value}).subscribe(response=>{
               let custdata = JSON.parse(JSON.stringify(response))

               if(custdata.Response){
                   this.customerDate = custdata.details;
                   this.Service.getCurrentBill1({BPno:this.SearchForm.get('BPno')?.value}).subscribe(response=>{
                  let data = JSON.parse(JSON.stringify(response))

              if(data.Response){
          this.BillingData = data.details[0]
          this.UserForm.get('PreMeterReading')?.setValue(this.BillingData.closing)
        //this.UserForm.get('MeterReadingWindow')?.setValue(this.BillingData.n_mr_date1 +' to' + this.BillingData.n_mr_date2)
         this.UserForm.get('PreMeterReadingDate')?.setValue(this.BillingData.desp_date)
         
		const temp1 = this.BillingData.n_mr_date1.split('/');
		const temp2 = this.BillingData.n_mr_date2.split('/');
		var date1 = new Date(temp1[2]+"-"+temp1[1]+"-"+temp1[0]);
		var date2 = new Date(temp2[2]+"-"+temp2[1]+"-"+temp2[0]);
		var date3 = new Date();
		date2.setDate(date2.getDate() + 1);
		this.isShow = date1.getTime() <= date3.getTime() && date2.getTime() >= date3.getTime();
         //console.log(data)
		this.UserForm.get('MeterFrom')?.setValue(temp1[0]+"-"+temp1[1]+"-"+temp1[2])
         this.UserForm.get('MeterTo')?.setValue(temp2[0]+"-"+temp2[1]+"-"+temp2[2])
         this.isformShow = true;

         }else{

            this.alertType='firstSubmit'
			this.alertMsg = data.message
			this.alertColor = 'rgba(4, 183, 107, 0.2)'
			this.alertTextColor = '#038b51'
			this.alertBorder = '1px solid #04a862;'

			setTimeout(() => {
			  this.alertType=''
			 //this.SearchForm.reset()
			  this.searchsubmitted=false;
			  this.isformShow = false;
			}, 3000);

         }
  
        
    // const temp1 = this.BillingData.n_mr_date1.split('/');
    // const temp2 = this.BillingData.n_mr_date2.split('/');
    // var date1 = new Date(temp1[2]+"-"+temp1[1]+"-"+temp1[0]);
    // var date2 = new Date(temp2[2]+"-"+temp2[1]+"-"+temp2[0]);
    // var date3 = new Date();
    // this.isShow = date1 >= date3 && date2 <= date3 
      })
  

               }else{
                    this.alertType='firstSubmit'
             this.alertMsg = custdata.message
             this.alertColor = 'rgba(4, 183, 107, 0.2)'
             this.alertTextColor = '#038b51'
               this.alertBorder = '1px solid #04a862;'

             setTimeout(() => {
          this.alertType=''
         this.SearchForm.reset()
          this.searchsubmitted=false;
          this.isformShow = false;
        }, 2000);


               }

          })

   

  }

  reset(){
    this.options=[]
    this.UserForm.reset()
    this.SearchForm.reset()
     this.searchsubmitted=false;
    this.submitted=false
    setTimeout(() => {
      this.options=['Assesed reading','Bill not recieved since commencement of gas','Bill recieved on actual consumption but reading differs',
    'Flat generally not occupied','Not available at home during the office hours']
      
    }, 200);
  }

  submit(){

    this.submitted=true
    
    var GivenDate = this.UserForm.get('Datess')?.value;
    var CurrentDate = new Date();
    

    if(new Date(GivenDate) > CurrentDate){
      this.UserForm.get('Datess')?.reset()
    }

    this.UserForm.get('Reading')?.setValue(""+this.UserForm.get('Reading1')?.value + this.UserForm.get('Reading2')?.value +
    this.UserForm.get('Reading3')?.value + this.UserForm.get('Reading4')?.value + this.UserForm.get('Reading5')?.value +
    this.UserForm.get('Reading6')?.value + this.UserForm.get('Reading7')?.value + this.UserForm.get('Reading8')?.value)

  //  console.log(this.UserForm.get('Reading')?.value)
     if(this.UserForm.invalid){
      return
    }

   

    const date = new Date();

   

    //console.log(this.UserForm.get('MeterFrom')?.value)

    var startDate = this.UserForm.get('MeterFrom')?.value.split("-");
     var toDate = this.UserForm.get('MeterTo')?.value.split("-");
    //console.log(startDate)
    var startDate1 =startDate[2]+"-"+startDate[1]+"-"+startDate[0]
    var toDate1 =toDate[2]+"-"+toDate[1]+"-"+toDate[0]

const start = new Date(startDate1)
const end = new Date(toDate1);

 // console.log(date)
 //  console.log(start)
 //   console.log(end)

    // if (date >= start && date <= end) {

       this.UserForm.get('BPno')?.setValue(this.customerDate[0].Partner)
    this.UserForm.get('CAno')?.setValue(this.customerDate[0].Contract_Account)
  this.UserForm.get('Phone')?.setValue(this.customerDate[0].Mobile_No)
  this.UserForm.get('RegName')?.setValue(this.customerDate[0].Name)
    this.Service.postMeterReading(this.UserForm.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.Response){
        this.alertType='submit'
        this.alertMsg = data.message
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {
          this.alertType=''
          this.UserForm.reset()
          this.submitted=false
          this.SearchForm.reset()
           this.searchsubmitted=false;
        }, 3000);
      }
    })

  
// } else {
//      this.alertType='windowsubmit'
//         this.alertMsg = "PLEASE CHECK METER READING WINDOW"
//         this.alertColor = 'rgba(4, 183, 107, 0.2)'
//         this.alertTextColor = '#038b51'
//         this.alertBorder = '1px solid #04a862;'

//         setTimeout(() => {
//          this.alertType='';
//         }, 5000);
// }

 // this.UserForm.get('BPno')?.setValue(this.customerDate[0].Partner)
 //    this.UserForm.get('CAno')?.setValue(this.customerDate[0].Contract_Account)
 //  this.UserForm.get('Phone')?.setValue(this.customerDate[0].Mobile_No)
 //  this.UserForm.get('RegName')?.setValue(this.customerDate[0].Name)
 //    this.Service.postMeterReading(this.UserForm.value).subscribe(response=>{
 //      let data = JSON.parse(JSON.stringify(response))

 //      if(data.Response){
 //        this.alertType='submit'
 //        this.alertMsg = data.message
 //        this.alertColor = 'rgba(4, 183, 107, 0.2)'
 //        this.alertTextColor = '#038b51'
 //        this.alertBorder = '1px solid #04a862;'

 //        setTimeout(() => {
 //          this.alertType=''
 //          this.UserForm.reset()
 //          this.submitted=false
 //          this.SearchForm.reset()
 //           this.searchsubmitted=false;
 //        }, 3000);
 //      }
 //    })

    // let data = sessionStorage.getItem('UserCredential')
    // if (data)
    //   this.data = JSON.parse(data)

   

    // this.router.navigate(['/user-dashboard'])
  }

}
