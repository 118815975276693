
<!-- <span class="form-inline">
    <select class="form-control" #selectedLang (change)="switchLang(selectedLang.value)">
        <option *ngFor="let language of translate.getLangs()" [value]="language"
            [selected]="language === translate.currentLang">
            {{ language }}
        </option>
    </select>
</span> -->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1 >{{'Customer Login' | translate}} </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">{{'Home' | translate}}</a></li>
                <li class=""><a href="#">{{'Residential PNG ' | translate}}</a></li>
                <li class=""><a href="#">{{'Customer Zone' | translate}}</a></li>
                <li class="active" aria-current="page">{{'Customer Login' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
               <app-customer-awareness></app-customer-awareness>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1"> 
                <div class="light-blue-bg mb-lg-0 mb-30 ">
                    <form [formGroup]="UserForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">

                                <div class="customer-login-page">
                                    <div class="log-meter-text">
                                        <ul>
                                         <!--  <li>* Log your Meter Reading without registering, <a [routerLink]="['/residential-png/customer-zone/log-meter-reading']"
                                                >Click here</a>
                                            </li> -->
                                             <li>* Log your Meter Reading without registering, <a style="color:blue;" [routerLink]="['/residential-png/customer-zone/without-registering-log-meter-reading']"
                                                ><u>Click here</u></a>
                                            </li>
                                            <li > * Kindly enter <a href="#">BP</a> or <a href="#">CA</a> number as
                                                appearing on your bill.</li>
                                        </ul>
                                    </div>
                                    
                                    <div *ngIf="alertType == 'login'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info mb-3" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                            </p>
                                        </div>
                                    </div>

                                      <div *ngIf="alertMsgNotfoundFlag == true">
                                     <div *ngIf="alertType == 'login1'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info mb-3" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                <li>BP/CA not registered in MGL website. Please click here <a style="color:blue;" [routerLink]="['/user-registration']"
                                                ><u>Register Now</u></a>
                                            </li>
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                    <div class="">
                                        <div class="form-floating mb-3">
                                         <input autocomplete="off" [ngClass]="{'is-invalid':submitted && UserForm.controls['BPnoCAno'].errors}" formControlName="BPnoCAno" class="form-control" type="text" onKeyPress="if(this.value.length==12) return false;" id="uname" name="name" placeholder="Enter your BP No./ CA No.">
                                          <label for="uname">Enter your BP No/ CA No</label>
										  <div *ngIf="submitted && UserForm.controls['BPnoCAno'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['BPnoCAno'].errors">Required Field</div>
                                                 <div *ngIf="UserForm.controls['BPnoCAno'].errors.pattern">
                                                    Invalid BP No/ CA No </div>
                                                <div
                                                    *ngIf="UserForm.controls.BPnoCAno.hasError('maxlength') && UserForm.controls.BPnoCAno.touched">
                                                    Invalid BP No/ CA No </div>
                                                <div
                                                    *ngIf="UserForm.controls.BPnoCAno.hasError('minlength') && UserForm.controls.BPnoCAno.touched">
                                                    Invalid BP No/ CA No </div>
                                              </div>
                                        </div>

                                        <!-- <div class="mb-20 form-floating ">
                                            <input [ngClass]="{'is-invalid':submitted && UserForm.controls['BPnoCAno'].errors}" formControlName="BPnoCAno" class="from-control" type="number" onKeyPress="if(this.value.length==10) return false;" id="name"
                                                name="name" placeholder="Enter your BP No./ CA No.">
                                                <label for="name">Enter your BP No./ CA No.</label>
                                            <div *ngIf="submitted && UserForm.controls['BPnoCAno'].errors" class="invalid-feedback">
                                                <div style="margin-left: 50px;" *ngIf="UserForm.controls['BPnoCAno'].errors">BPno/CAno required</div>
                                              </div>
                                        </div> -->
                                        
                                        <div class="form-floating mb-3">
                                          <input autocomplete="off" [ngClass]="{'is-invalid':submitted && UserForm.controls['Password'].errors}" type="password" class="form-control" id="floatingPassword" placeholder="Password" formControlName="Password">
                                          <label for="floatingPassword">Password</label>
                                          <div *ngIf="submitted && UserForm.controls['Password'].errors"
                                                class="invalid-feedback">
                                                <div 
                                                    *ngIf="UserForm.controls['Password'].errors">Required Field</div>
                                            </div>
                                        </div>

                                        <div class="row">
                                        <div class="col-lg-5 col-md-5 col-sm-12">
                                            <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                            <div class="pt-20 md-pt-0">
                                                <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                               <div (copy)="false" class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                aria-hidden="true"></i> </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-7 col-sm-12">
                                            
                                            

                                            <div class="form-floating">
                                                <input autocomplete="off" [ngClass]="{'is-invalid':submitted && UserForm.controls['Captcha'].errors}" type="text" class="form-control" id="Captcha" placeholder="Captcha" formControlName="Captcha">
                                                <label for="Captcha">Captcha</label>
                                                <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                      class="invalid-feedback">
                                                      <div
                                                          *ngIf="UserForm.controls['Captcha'].errors">Required Field</div>
                                                  </div>
                                            </div>
                                        </div>

                                        
                                    </div>




                                        

                                       

                                        <!-- <div class="mb-20 cu-login-input2">
                                            <input
                                                [ngClass]="{'is-invalid':submitted && UserForm.controls['Password'].errors}"
                                                formControlName="Password" class="from-control" type="password"
                                                id="name" name="name" placeholder="Enter Password" required="">
                                            <div *ngIf="submitted && UserForm.controls['Password'].errors"
                                                class="invalid-feedback">
                                                <div style="margin-left: 27px;"
                                                    *ngIf="UserForm.controls['Password'].errors">Password required</div>
                                            </div>
                                        </div> -->

                                        <div class="mt-20 mb-20 text-right">
                                            <a style="color:blue;" [routerLink]="['/forgot-password']" class="register-now"><u>Forgot Password?</u></a>
                                        </div>
                                        <!--  <div class="row" style="margin-bottom: 20px;">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button [disabled]="loading" class="greenbtn2" (click)="submit()">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                Login
                                                <i class="demo-icon uil-arrow-right"></i>
                                            </button>
                                    </div>
                                     <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button  class="greenbtn2" [routerLink]="['/residential-png/customer-zone/customer-login-with-otp']">
                                                 Login with Mobile OTP
                                                <i class="demo-icon uil-arrow-right"></i>
                                            </button>
                                    </div>
                                </div> -->
                                        <div class="mb-20">
                                          
											<button [disabled]="loading" class="greenbtn2" (click)="submit()">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                Login
                                                <i class="demo-icon uil-arrow-right"></i>
                                            </button>		
                                        </div>
                                        <div class="mb-20 text-center">
                                            If you don’t have an account,<a style="color:blue;" [routerLink]="['/user-registration']"
                                                class="register-now"><u>Register Now!</u></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </form>


                </div>
            </div>
        </div>
    </div>


    <!-- modernizr js -->
    <script src="../../../assets/js/modernizr-2.8.3.min.js"></script>
    <!-- jquery latest version -->
    <script src="../../../assets/js/jquery.min.js"></script>
    <!-- Bootstrap v4.4.1 js -->
    <script src="../../../assets/js/bootstrap.min.js"></script>
    <!-- op nav js -->
    <script src="../../../assets/js/jquery.nav.js"></script>
    <!-- isotope.pkgd.min js -->
    <script src="../../../assets/js/isotope.pkgd.min.js"></script>
    <!-- owl.carousel js -->
    <script src="../../../assets/js/owl.carousel.min.js"></script>
    <!-- wow js -->
    <script src="../../../assets/js/wow.min.js"></script>
    <!-- Skill bar js -->
    <script src="../../../assets/js/skill.bars.jquery.js"></script>
    <!-- imagesloaded js -->
    <script src="../../../assets/js/imagesloaded.pkgd.min.js"></script>
    <!-- waypoints.min js -->
    <script src="../../../assets/js/waypoints.min.js"></script>
    <!-- counterup.min js -->
    <script src="../../../assets/js/jquery.counterup.min.js"></script>
    <!-- magnific popup js -->
    <script src="../../../assets/js/jquery.magnific-popup.min.js"></script>
    <!-- Nivo slider js -->
    <script src="../../../assets/inc/custom-slider/js/jquery.nivo.slider.js"></script>
    <!-- contact form js -->
    <script src="../../../assets/js/contact.form.js"></script>
    <!-- main js -->
    <script src="../../../assets/js/main.js"></script>
    <script type="text/javascript">
        $('.search-toggle').addClass('closed');

        $('.search-toggle .search-icon').click(function (e) {
            if ($('.search-toggle').hasClass('closed')) {
                $('.search-toggle').removeClass('closed').addClass('opened');
                $('.search-toggle, .search-container').addClass('opened');
                $('#search-terms').focus();
            } else {
                $('.search-toggle').removeClass('opened').addClass('closed');
                $('.search-toggle, .search-container').removeClass('opened');
            }
        });

    </script>