<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>CNG Retail Outlets Status
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class="active" aria-current="page"><a href="#">CNG Retail Outlets Status

                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
               <!--  <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
            	 <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                            <div class="customer-login-page">
                                <div class="row">
                                    <form [formGroup]="searchRetailForm">
                                        <div class="row">
                                            <div class="col-sm-12 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control"
                                                        formControlName="ApplicationNo"
                                                        placeholder="Application No."
                                                        [ngClass]="{ 'is-invalid': submitted && searchRetailForm.controls['ApplicationNo'].errors }"
                                                        id="ApplicationNo" />
                                                    <div *ngIf="submitted && searchRetailForm.controls['ApplicationNo'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="searchRetailForm.controls['ApplicationNo'].errors.required">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="ApplicationNo">Application No.<span
                                                            style="color: crimson;">*</span></label>
                                                </div>

                                            </div>
                                           
                                            
                                          
                                        </div>

                                        <div class="row y-middle">
                                            <div class="col-lg-6">
                                                <div (copy)="false" class="">
                                                    <div (copy)="false" class="captcha-text">
                                                        {{this.captcha}}
                                                        <button style="background: transparent;border: none;"
                                                            (click)="captchaGenerate()"><i class="fa fa-repeat"
                                                                aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && searchRetailForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted && searchRetailForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="searchRetailForm.controls['Captcha'].errors.required">
                                                            Required Field</div>

                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="mt-15">
                                        <div class="row">
                                            <div class="col-3 col-lg-3 col-md-3 col-sm-12">
                                                <button (click)="submit()" class="greenbtn2">Search <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                          
                                        </div>
                                        </div>
                                    </form>

                                    

                                    <div *ngIf="alertType == 'searchPro'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}" class="alert alert-danger mt-2" role="alert">
                                   {{alertMsg}}
                                     </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</div>