import { Component } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PayuService } from 'src/app/Service/payu.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-pay-uunloadpaymentsuccess',
  templateUrl: './pay-uunloadpaymentsuccess.component.html',
  styleUrls: ['./pay-uunloadpaymentsuccess.component.css']
})
export class PayUunloadpaymentsuccessComponent {
 id: any;
  amt:any;
   data:any;
   walletData:any;
  constructor(public datePipe: DatePipe, public UserService: UserRegistrationService, private route: ActivatedRoute,
    public payuService: PayuService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.amt = this.route.snapshot.paramMap.get('amt');
    this.amt = this.amt*100;
  //  console.log( this.id)
     // let data = sessionStorage.getItem('UserCredential')
    let data = ''
    // console.log(data)

     let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
     
    if(data){
       this.data=JSON.parse(data)
     
         this.findpayUcreatewalletdata();
     
    }
  } 

   findpayUcreatewalletdata() {
     let obj={"MobileNo":this.data.Mobile}
     this.payuService.checkwallet(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         this.walletData = res.details.LstWallet_Response;
      
         this.unLoadBalance()
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }

   unLoadBalance(){

    // console.log('enter')
      var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
     //console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
 //console.log(text3)
 //console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;

 

  

      let obj={"clientTxnId":this.id,"requestDateTime":savetime,
    "urn":this.walletData[0].urn,"transactionAmount":this.amt,
    "subWalletId":this.walletData[0].subwallet_id,"sourceAccount":this.walletData[0].account_number}
     this.payuService.wallet_amt_Unload_encrypt(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.wallet_amt_Unload(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }

    wallet_amt_Unload(data:any) {
    let obj={"token":data}
     this.payuService.wallet_amt_Unload(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      this.wallet_amt_Unload__decrypt(res.details.token)
      
      } else {
        alert(res.message)
      }
     }, err => {
      //console.log(err)
    })

  }


   wallet_amt_Unload__decrypt(data:any) {
    let obj={"token":data}
     this.payuService.wallet_amt_Unload__decrypt(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
       //console.log(res.details);
        if(res.details.responseMessage=="CARD TEMPORARY BLOCK"){
          alert(res.details.responseMessage)
        }else if(res.details.responseMessage=="DUPLICATE REQUEST"){
           alert(res.details.responseMessage)
        }else if(res.details.responseMessage=="UNLOAD AMOUNT SHOULD BE LESS THAN AVAILABLE BALANCE"){
           alert(res.details.responseMessage)
        }
       
      
      } else {
         alert(res.details.responseMessage)
      }
     }, err => {
      //console.log(err)
    })

  }
} 
