import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute,NavigationEnd } from '@angular/router';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';

import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { SliderService } from 'src/app/Service/slider.service';


@Component({
  selector: 'app-applyfornameforapp',
  templateUrl: './applyfornameforapp.component.html',
  styleUrls: ['./applyfornameforapp.component.css']
})
export class ApplyfornameforappComponent {
  hideValidation:boolean =false
  captcha: any
  stopTimer: boolean = false
  submitStatus: boolean = false
  display: string = 'none'
  Popup: any = ''
  address: any[] = []
  ShowOtp: boolean = false
  OtpButtonActive: boolean = false
  ShowUserForm: boolean = false
  ShowGo: boolean = false
  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'
  UserData: any = []
  submitted = false
  OtpSubmitted = false
  CustomerformSubmited = false
  allFaq: any[] = []
  AllReason: any[] = []
  Otp: any
  Minute: any = 1
  Sec: any = 10
  ValidationForm!: FormGroup
  checkOtpForm!: FormGroup
   existingCustName:any;FlatNo:any;Floor:any;Wing:any;PlotNo:any;
   BuildingName:any;Colony:any;RoadName:any;Location:any;
   LandMark:any;City:any;Pin:any;OutstandingAmt:any;
   PaymentStatus:any;
   OutstandingAmt1:any;
   // PaymentStatus:any;
   paybuttonFlag:any;
   loading = false;
   ApplicationNoID:any;
    activeLink:any;
    activeLink2:any;

    apiCallFalg = false;
    
  constructor(private PostService: SliderService,private el: ElementRef,private datePipe: DatePipe, private FormBuilder: FormBuilder, private PngService: FormServiceService, public UserService: UserRegistrationService, public router: Router, private pageLoaderService: PageLoaderService,
    private activeroute:ActivatedRoute) { 
   


  }

  ngOnInit(): void {
    //this.UserService.checkLogin()

    this.router.events.subscribe(() => {
      const routeParams = this.activeroute.snapshot.params;
      const currentRoute = this.router.url.split("=");
      this.activeLink = decodeURIComponent(currentRoute[1]); // Add logic if needed to handle route parameters
      this.activeLink2 = this.activeLink.replace(" ", "+");
    //  console.log('Active Route:', currentRoute, 'Params:', routeParams,currentRoute[1]);
      if(this.apiCallFalg==false){
        this.get_name_transfer_app_data()
      }
      
    });

    this.captchaGenerate()
    this.ValidationForm = this.FormBuilder.group({
      BPno: [''],
      CAno: [''],
      BPnoCAno: ['', [Validators.required]],
      MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]]
    })

    this.checkOtpForm = this.FormBuilder.group({
      Otp: ['', Validators.required]
    })

   
    

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }


  gotoStepone(){
    this.apiCallFalg = true;
    this.UserData.OutstandingAmt =this.OutstandingAmt1
    this.UserData.otpmobileno = this.ValidationForm.get('MobileNo')?.value
    //console.log('this.UserData',this.UserData)
    // alert("Goto Level one")
    this.router.navigate(['/name-transfer/apply-for-name-step-one-for-app'],
        {queryParams:{data:JSON.stringify(this.UserData)}})

  }


 

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }

  openPopup() {
    this.Popup = 'show'
    this.display = 'block'
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
    this.stopTimer = true
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  clearAlert() {
    this.alertType = ''
  }

  verifyOtp() {

    this.OtpSubmitted = true

    if (this.checkOtpForm.invalid) {
      return
    }
    this.alertType = 'secSubmit'
    if (this.Otp != this.checkOtpForm.get('Otp')?.value) {

      this.alertMsg = "OTP Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
    } else {
      this.alertMsg = "OTP Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
      this.ShowGo = true
      setTimeout(() => {
        this.clearAlert()
        this.ShowOtp = false
        this.closeModel()
        this.ShowUserForm = true
      }, 1000);
    }
  }

  otpTimer() {

    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {

      sec--;



      if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0
        clearInterval(count)
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);

    var test = () => {
      if (this.stopTimer) {
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp = ''
        this.submitStatus = true
        clearInterval(count)
      }
      this.Minute = minute
      this.Sec = sec
    }
  }

  OtpGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
   // console.log(result)
    this.Otp = result
    this.UserService.sendOtp(this.Otp, this.ValidationForm.get('MobileNo')?.value)
    this.otpTimer()
    this.OtpButtonActive = true
    this.submitStatus = false
  }




 

  submit() {
  if (this.ValidationForm.get('BPno')?.value == null) {
      this.ValidationForm.get('BPno')?.setValue('')
    }

    if (this.ValidationForm.get('CAno')?.value == null) {
      this.ValidationForm.get('CAno')?.setValue('')
    }

    this.ValidationForm.get('CAno')?.setValidators([Validators.required])
    this.ValidationForm.get('BPno')?.setValidators([Validators.required])

    this.submitted = true
   // console.log(this.ValidationForm.value)

    if (this.ValidationForm.get('BPno')?.value != '' && this.ValidationForm.get('CAno')?.value == '') {
      this.ValidationForm.get('CAno')?.clearValidators()
      this.ValidationForm.get('CAno')?.updateValueAndValidity()
      this.ValidationForm.get('CAno')?.setValue('.')
    }

    if (this.ValidationForm.get('CAno')?.value != '' && this.ValidationForm.get('BPno')?.value == '') {
      this.ValidationForm.get('BPno')?.clearValidators()
      this.ValidationForm.get('BPno')?.updateValueAndValidity()
      this.ValidationForm.get('BPno')?.setValue('.')
    }

    if (this.ValidationForm.invalid) {
      return
    }

   this.UserService.getUserAddress(this.ValidationForm.value).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      //console.log(data)
      
      
      if (data.Response) {
         this.UserData = data.details[0];

       this.UserService.getNameTransfer(this.ValidationForm.value).subscribe(response2 =>{
          let data2 = JSON.parse(JSON.stringify(response2))

          if(data2.Response==false){

        //     this.OtpGenerate();
        //    this.openPopup();

        // this.alertType = 'firstSubmit'
        // this.alertMsg = "OTP Sent"
        // this.alertColor = 'rgba(4, 183, 107, 0.2)'
        // this.alertTextColor = '#038b51'
        // this.alertBorder = '1px solid #04a862;'

        //this.UserService.getUserAddress(this.ValidationForm.value).subscribe(response => {
        //  let data = JSON.parse(JSON.stringify(response)).details

      

          
          
         this.existingCustName=this.UserData.Name;
        // this.stopTimer = false


          let obj = {"ca_no":this.ValidationForm.get('BPnoCAno')?.value};
        this.UserService.getpendingAmout(obj).subscribe(response => {
          let Amydata = JSON.parse(JSON.stringify(response))
          console.log('Pending amt',response)
         if(Amydata==null){
           this.OutstandingAmt1 = 'NIL';
            this.OutstandingAmt = 0;
            this.paybuttonFlag = true;
          }else{
            if (Amydata.NETPAY==0) {
            this.OutstandingAmt1 = 'NIL';
            this.OutstandingAmt = 0;
            this.paybuttonFlag = true;
          }else{
            this.OutstandingAmt1 = Amydata.NETPAY.toFixed(2);
            this.OutstandingAmt = Amydata.NETPAY.toFixed(2);
            this.paybuttonFlag = false;
          }
          }
          this.ShowUserForm = true
         
          

        })

        //this.openPopup()
        // this.ShowOtp = true
        setTimeout(() => {
          this.clearAlert()
        }, 3000);

           }else {

              this.alertType = 'firstSubmit'
            this.alertMsg = "Your Application Already In Process!"
            this.alertColor = 'rgba(255, 94, 109, 0.2)'
            this.alertTextColor = '#c24753'
            this.alertBorder = '1px solid #eb5664;'
            setTimeout(() => {
              this.clearAlert()
            }, 3000);

            

          
          }
           })
      } else {

        this.alertType = 'firstSubmit'
        this.alertMsg = "BP No / CA No Not Found"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.clearAlert()
        }, 5000);
        

        
      }
    })
  }




  get_name_transfer_app_data() {
    let obj = {"token":this.activeLink2}
   this.UserService.get_name_transfer_app_data(obj).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
       //console.log(data.details.value.replace("\"",""))
      if (data.response==true) {
           let formData = JSON.parse(data.details.value);
console.log(formData);
        // let formData = data.details.value.replace("\","")
        // console.log(formData)
        console.log(formData.BP_CANo)

        this.ValidationForm.get('BPnoCAno')?.setValue(formData.BP_CANo)
        this.ValidationForm.get('MobileNo')?.setValue(formData.Mobile)
        this.submit()
         
      } else {

        this.alertType = 'firstSubmit'
        this.alertMsg = "BP No / CA No Not Found"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.clearAlert()
        }, 5000);
        

        
      }
    })
  }


  
}

