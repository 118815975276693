import { AfterViewInit, Component, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FaqServiceService } from './Service/FAQ/faq-service.service';
import { OnInit, HostListener, Inject } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DisableRightClickService } from './DisableRightClickService';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class AppComponent implements OnInit, AfterViewInit {
 // title = 'MahanagarGas_Website';
  location: Location;
  headerFooter:any;
  rightMenu:any;

  constructor(@Inject(DOCUMENT) private document: Document, public FaqService: FaqServiceService, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object, private rightClickDisable: DisableRightClickService,
    private router: Router,
    private meta: Meta,private title: Title) {

     this.meta.addTags([
{name: 'description', content:"description', content: 'Enjoy secure, efficient PNG and CNG services with Mahanagar Gas. Sign up today for reliable gas supply for homes, kitchens, and industries."},
{name: 'keywords', content: "png gas connection, cng service, png registration, Mahanagar Gas"},
{name: 'description', content:"Discover the versatility of PNG with Mahanagar Gas, serving household cooking and commercial kitchens with reliable gas solutions."},
{name: 'keywords', content: "piped Natural Gas, natural gas prices, PNG provider"},
{name: 'description', content:"Make the switch to cost-effective, eco-friendly CNG with Mahanagar Gas. Enjoy pocket-friendly fuel that’s good for the environment."},
{name: 'keywords', content: "Compressed natural Gas, CNG Provider"},
{name: 'description', content:"Trust Mahanagar Gas for reliable CNG and PNG services, a leader in natural gas distribution with convenient, customer-focused solutions."},
{name: 'keywords', content: "Cng Png  Distribution Company,  Natural Gas Distribution Company, leading Natural Gas Distribution"},
{name: 'description', content:"Have questions about PNG and CNG? Contact MGL for expert assistance and clear answers on gas services tailored to your needs."},
{name: 'keywords', content: "Png Cng Service, CNG station near me"},
{name: 'description', content:"Explore MGL’s prestigious awards for excellence in natural gas distribution and join us in creating a sustainable, greener future."},
{name: 'keywords', content: "Natural Gas Distribution Company, Domestic industrial Gas"},
{name: 'description', content:"Discover Mahanagar Gas' industry-leading natural gas services for businesses. Learn about our conservation efforts and premium clientele."},
{name: 'keywords', content: "Natural Gas, Cng & Png services,"},
{name: 'description', content:"Mahanagar Gas offers clean, efficient commercial PNG services. Explore how our natural gas solutions can power your business."},
{name: 'keywords', content: "Piped Natural Gas,  PNG application, PNG Services Provider"},
{name: 'description', content:"Access eco-friendly CNG and PNG services with Mahanagar Gas. Log in via the customer portal for seamless account management."},
{name: 'keywords', content: "Residential png gas connection, Png Domestic Application"},
{name: 'description', content:"Secure a reliable PNG gas connection or CNG service with Mahanagar Gas. Visit our website for easy registration and build a greener future."},
{name: 'keywords', content: "png gas connection, cng service, png registration, Mahanagar Gas"},
{name: 'description', content:"Explore MGL’s Annual Report for insights into our achievements and vision as a leading natural gas distributor."},
{name: 'keywords', content: "Mahanagar Gas report"},
{name: 'description', content:"Simplify your residential PNG connection transfer with Mahanagar Gas. Visit our customer zone for easy name transfers and seamless processes."},
{name: 'keywords', content: "MGL, Residential PNG ,Png Domestic Application"},
{name: 'description', content:"Transfer your residential PNG connection name easily at Mahanagar Gas Customer Zone. Apply now for a seamless, hassle-free process."},
{name: 'keywords', content: "Application Form Png, MGL, Residential PNG,"},
{name: 'description', content:"Check your residential PNG name transfer status quickly at Mahanagar Gas Customer Zone. Access updates for a smooth transaction process."},
{name: 'keywords', content: "MGL, Residential PNG,  Application status"},
{name: 'description', content:"Ensure your residential PNG safety with Mahanagar Gas' Piped Natural Gas Checklist. Trust us for secure, reliable home energy solutions."},
{name: 'keywords', content: "png gas, piped natural gas, Residential PNG Safety"},
{name: 'description', content:"Register for a Domestic PNG connection with Mahanagar Gas and enjoy cost-effective, eco-friendly, and reliable energy for your home."},
{name: 'keywords', content: "png connection, natural gas service provider"},
{name: 'description', content:"Apply online for piped natural gas and track your application status easily with Mahanagar Gas. Enjoy a smooth, hassle-free experience."},
{name: 'keywords', content: "Residential PNG, Domestic Connection Request , mgl connection status"},
{name: 'description', content:"Explore residential PNG solutions with Mahanagar Gas rate cards. Enjoy the convenience of efficient, clean energy for your home."},
{name: 'keywords', content: "Piped Natural Gas prices , Residential PNG rate"},
{name: 'description', content:"Discover the ultimate guide for residential PNG installation with Mahanagar Gas. Switch to cleaner, efficient energy for your household today."},
{name: 'keywords', content: "PNG, Guidelines for PNG Installation , technical Guidelines"},
// {name: 'description', content:"Discover the convenience of Residential PNG with Mahanagar Gas. Access our registration form and terms & conditions."},
// {name: 'keywords', content: "mgl registration form, Mahanagar gas term & condition"},
{name: 'description', content:"Enjoy the benefits of residential PNG with Mahanagar Gas. Check our network coverage and experience reliable, seamless energy service."},
{name: 'keywords', content: "MGL PNG Network , gas network of residential png , png network near me"},
{name: 'description', content:"Login now to the Authorized Person portal for residential PNG connections with Mahanagar Gas."},
{name: 'keywords', content: "Piped Natural Gas,  Png client login"},
{name: 'description', content:"Stay compliant and informed with Mahanagar Gas’ residential PNG guidelines. Ensure smooth and secure service for your home."},
{name: 'keywords', content: "Important Policies of Mahanagar Gas, Billing Policy of mgl,PNG Safety Policy"},
{name: 'description', content:"Experience hassle-free after-sales services and competitive charges with Mahanagar Gas for a reliable piped natural gas solution."},
{name: 'keywords', content: "Residential PNG Service Charges, Piped Natural Gas, labour charges"},
{name: 'description', content:"Trust Mahanagar Gas for expert after-sales service on PNG, CNG, and piped natural gas. Your reliable energy provider."},
{name: 'keywords', content: "Piped Natural Gas (PNG) Service Provider , area distribution of PNG"},
{name: 'description', content:"Enjoy the convenience and safety of PNG with Mahanagar Gas. Hassle-free, 24/7 energy supply for your home."},
{name: 'keywords', content: "Safety measures for gas usage, how to use png,"},
{name: 'description', content:"Explore Mahanagar Gas’ sitemap for easy access to Residential PNG, Domestic Connection Requests, and application status updates."},
{name: 'keywords', content: "Residential PNG, Domestic Connection Request,  Application status"},
{name: 'description', content:"MGL terms and conditions, including policies related to the network,  privacy policy. Learn about how we protect your information and ensure a secure service."},
{name: 'keywords', content: "Mahanagar Gas Ltd terms and conditions, MGL PNG network"},
{name: 'description', content:"Empowering underprivileged youth with skills and opportunities for a brighter future through CSRL's work readiness program"},
{name: 'keywords', content: "Mgl Blog Unnati"},
{name: 'description', content:"Partnership between MGL and B-LNG accelerates LNG station expansion, fueling cleaner, sustainable transport solutions."},
{name: 'keywords', content: "Mahanagar Gas, Baidyanath LNG"},
{name: 'description', content:"From Remote Learning to Mask Production. Discover how MGL Disha project and social media are driving education and safety initiatives in the face of the pandemic"},
{name: 'keywords', content: "MGL Disha project"},
{name: 'description', content:"MGL Arogya's vital role in the fight against COVID-19, providing ventilators and healthcare support"},
{name: 'keywords', content: "MGL Arogya"},
{name: 'description', content:"MGL Aarogya: A Lifeline for Health & Humanity, Transforming Lives through Compassionate Healthcare Initiatives"},
{name: 'keywords', content: "MGL Arogya"},
{name: 'description', content:"Register with Mahanagar Gas, your trusted PNG provider, using our convenient user registration form. Join our network today!"},
{name: 'keywords', content: "Png Connection, Png network, Png Provider, Mgl user online registration"},
{name: 'description', content:"Explore MGL's CNG & PNG services and learn about the Chairman of MGL on our Corporate Leadership. Discover sustainable energy solutions"},
{name: 'keywords', content: "Cng & Png services , Chairman of mgl"},
{name: 'description', content:"Stay updated with Mahanagar Gas, the leading Natural Gas Distribution Company, and learn about CNG, PNG Services through our newsletters"},
{name: 'keywords', content: "Natural Gas Distribution Company, CNG, PNG Service Newsletter"},
{name: 'description', content:"MGL's 2022 Awards & Achievements in Natural Gas Distribution and Domestic Industrial Gas."},
{name: 'keywords', content: "Natural Gas Distribution Company, Domestic industrial Gas"},
{name: 'description', content:"Calculate your CNG savings with Mahanagar Gas, the leading CNG gas supplier. Use our CNG Savings Calculator for cost-effective solutions."},
{name: 'keywords', content: "cng gas supplier, CNG services Leading CNG Company"},
{name: 'description', content:"MGL's 2023 Awards & Achievements in Natural Gas Distribution and Domestic Industrial Gas."},
{name: 'keywords', content: "Natural Gas Distribution Company, Domestic industrial Gas"},
{name: 'description', content:"Optimize Your CNG Vehicle's Performance with Expert Car Care Services by Mahanagar Gas"},
{name: 'keywords', content: "CNG Car Care Tips, Services by  Mahanagar Gas"},
{name: 'description', content:"Contact Mahanagar Gas for Residential PNG Gas Connection - Your Trusted Customer Care Partner"},
{name: 'keywords', content: "Residential png gas connection, Customer Care, Mahanagar Gas"},
{name: 'description', content:"Explore MGL's Corporate CSR Policy in Leading CNG & PNG Services. Commitment to a Sustainable Future"},
{name: 'keywords', content: "Cng & Png services , Chairman of mgl"},
{name: 'description', content:"MGL takes a step to reduce costs with lowered CNG and Domestic PNG prices. Stay informed on our CNG & PNG network updates"},
{name: 'keywords', content: "Price of png, price of cng"},
{name: 'description', content:"MGL Arogya's : Free Eye Check-up Camp for Auto Rickshaw Drivers. Learn more about this initiative."},
{name: 'keywords', content: "MGL Arogya"},
{name: 'description', content:"Explore exciting career opportunities at Mahanagar Gas Limited. Join us and shape your future with MGL. Check our current openings today"},
{name: 'keywords', content: "career in mgl, jobs in mgl"},
{name: 'description', content:"Discover Mahanagar Gas, your trusted PNG provider. Learn about asset integrity at Mahanagar Gas for safe and reliable natural gas distribution."},
{name: 'keywords', content: "Png Connection, Png network, Png Provider, Mgl user online registration"},
{name: 'description', content:"Mahanagar Gas, CNG and PNG services. Convenient IC Bill payment online. Your trusted PNG provider."},
{name: 'keywords', content: "Cng & Png services, Png Provider, IC Bill Online"},
{name: 'description', content:"MGL's Saksham Cycle Rally promotes a greener lifestyle, health, and fuel conservation. Join the movement for a sustainable future."},
{name: 'keywords', content: "Residential png gas connection, Png Cng Service"},
{name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Due to Rising Gas Costs. MGL continues to offer significant savings and convenience for its valued customers."},
{name: 'keywords', content: "png gas connection, CNG and Domestic PNG customers"},
{name: 'description', content:"25 Years of MGL: Celebrating a Legacy of Clean Energy. Explore MGL's journey and the release of a Special Postage Stamp, Special Cover, and Commemorative Volume"},
{name: 'keywords', content: "Environment-Friendly Natural Gas Distributor In Mumbai |"},
{name: 'description', content:"Discover Corporate Governance at MGL, led by our Chairman, ensuring top-tier CNG & PNG services in Mumbai. Learn more on our page"},
{name: 'keywords', content: "Cng & Png services , Chairman of mgl"},
{name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Discover the impact and continued benefits of our services"},
{name: 'keywords', content: "CNG and Domestic PNG in Mumbai , Price of CNG"},
{name: 'description', content:"Conveniently pay your gas bills online with MGL's secure QR payment platform"},
{name: 'keywords', content: "Bills Payment Online, Pay Gas Bills Online"},
{name: 'description', content:"Change Password for Customer Login at Mahanagar Gas. Secure and update your login credentials easily on our dedicated page."},
{name: 'keywords', content: "Change Password, Change Password of Customer Login"},
{name: 'description', content:"MGL Tez: Your Fueling Solution for CNG Commercial Vehicles. Discover how our app streamlines CNG refueling at bus depots."},
{name: 'keywords', content: "Cng Fuel Supply to B.E.S.T, CNG facility in BEST bus depots, MGL TEZ"},
{name: 'description', content:"How MGL's CSR initiatives uplift lives through assistive aids, education, and holistic development, reaching thousands across Mumbai and Raigad"},
{name: 'keywords', content: "MGL Saksham"},
{name: 'description', content:"Discover MGL's commitment to education through initiatives supporting out-of-school children, early childhood education, and Anganwadi workers"},
{name: 'keywords', content: "MGL Vidya"},
{name: 'description', content:"FAQS : Get answers to common questions and information about PNG CNG services with Mahanagar Gas."},
{name: 'keywords', content: "PNG CNG Services"},
{name: 'description', content:"MGL Investor Policies: Explore Our Commitment to CNG and PNG Supply. Visit our page for valuable insights"},
{name: 'keywords', content: "CNG,PNG Supplier, Mgl Investor Policies"},
{name: 'description', content:" MGL Sahayogi: Our commitment to protecting MGL's assets and ensuring the safety of our PNG gas supply. Learn more at Mahanagar Gas Limited."},
{name: 'keywords', content: "Leading PNG Gas Supplier  MGL Sahayogi"},
{name: 'description', content:"MGL rewards 'MGL CNG Chalate Raho' scheme participants with Rs. 5 lakhs. A memorable day with Managing Director, Mr. Sanjib Datta"},
{name: 'keywords', content: "MGL CNG Chalate Raho, leading Cng Gas Supplier"},
{name: 'description', content:"Leading CNG supplier in Mumbai, Navi Mumbai & Thane at Mahanagar Gas. Explore CNG gas, kits, and more for your needs"},
{name: 'keywords', content: "Leading CNG Supplier, CNG Gas IN Mumbai, Navi Mumbai & Thane"},
{name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Discover the impact and continued benefits of our services"},
{name: 'keywords', content: "CNG and Domestic PNG in Mumbai , Price of CNG"},
{name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Due to Rising Gas Costs. MGL continues to offer significant savings and convenience for its valued customers."},
{name: 'keywords', content: "png gas connection, CNG and Domestic PNG customers"},
// {name: 'description', content:"MGL initiates PNG supply at Raj Bhavan, extending the reach of cleaner energy to a prestigious location in South Mumbai, enhancing convenience and sustainability."},
{name: 'description', content:"MGL begins PNG supply at Raj Bhavan, bringing cleaner energy to South Mumbai, enhancing convenience and sustainability at this prestigious location."},
{name: 'keywords', content: "PNG supplier | PNG supply at Raj Bhavan"},
// {name: 'description', content:"Bridging Education Gaps: MGL's Commitment to Mainstreaming Out of School Children. Explore our blog for insights on education initiatives by MGL"},
{name: 'description', content:"Bridging Education Gaps: MGL’s Commitment to Mainstreaming Out of School Children. Explore our blog for insights on MGL’s education initiatives and impact."},
{name: 'keywords', content: "Mainstreaming of Out of School Children, MGL blog"},
// {name: 'description', content:"Empowering Women Through Skill Development: MGL Hunar's Impactful Initiative in Raigad District, Maharashtra"},
{name: 'description', content:"Empowering Women Through Skill Development: MGL Hunar’s Impactful Initiative in Raigad District, Maharashtra, Enhancing Opportunities for Women’s Growth."},
{name: 'keywords', content: "MGL Hunar, Blog"},
// {name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Due to Rising Gas Costs. MGL continues to offer significant savings and convenience for its valued customers."},
{name: 'description', content:"MGL Adjusts CNG and Domestic PNG Rates Amid Rising Gas Costs, While Continuing to Provide Significant Savings and Convenience for Valued Customers."},
{name: 'keywords', content: "png gas connection, CNG and Domestic PNG customers"},
// {name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Discover the impact and continued benefits of our services"},
{name: 'description', content:"Important Gas Price Update: MGL Adjusts CNG and Domestic PNG Prices in Mumbai. Learn about the impact and ongoing benefits of our services for customers."},
{name: 'keywords', content: "CNG and Domestic PNG in Mumbai , Price of CNG"},
// {name: 'description', content:"Unlock Savings with MGL CNG Mahotsav: Get INR 19,999 Benefits for New CNG Cars and Up to 5 Lakhs Incentive for CNG Commercial Vehicles."},
{name: 'description', content:"Unlock Savings with MGL CNG Mahotsav: Get INR 19,999 Benefits for New CNG Cars and Up to INR 5 Lakhs Incentive for CNG Commercial Vehicles."},
{name: 'keywords', content: "MGL CNG Mahotsav, cng gas Offer by mgl"},
// {name: 'description', content:"Find the Nearest MGL CNG Station with Our CNG Locator. Locate convenient CNG gas stations for eco-friendly refueling"},
{name: 'description', content:"Find the Nearest MGL CNG Station with Our CNG Locator. Easily locate convenient, eco-friendly refueling stations for your vehicle."},
{name: 'keywords', content: "Nearest MGL CNG Station | Cng gas Station"},
// {name: 'description', content:"Unlock Savings with MGL Hat-trick Yojana: Get up to Rs 40,000 grant on CNG Commercial Vehicle adoption. Join the green revolution now"},
{name: 'description', content:"Unlock Savings with MGL Hat-trick Yojana: Get Up to Rs 40,000 Grant on CNG Commercial Vehicle Adoption. Join the Green Revolution Today!"},
{name: 'keywords', content: "CNG PNG Gas Leading Company, MGL Hat-trick Yojana"},
// {name: 'description', content:"MGL Cardiology Ward at Nair Hospital A Step Towards Quality Healthcare for All. MGL's CSR initiative MGL Aarogya strengthens the hospital's cardiac care infrastructure"},
{name: 'description', content:"MGL Cardiology Ward at Nair Hospital: A Step Toward Quality Healthcare for All. MGL's CSR Initiative, MGL Aarogya, Strengthens Cardiac Care Infrastructure."},
{name: 'keywords', content: "MGL Cardiology Ward, News"},
// {name: 'description', content:"MGL and BMC Join Forces for a Greener Mumbai: Compressed Biogas Plant Set to Transform Waste into Green Energy, Aligning with Sustainable Development Goals."},
{name: 'description', content:"MGL and BMC Partner for a Greener Mumbai: Compressed Biogas Plant to Convert Waste into Green Energy, Supporting Sustainable Development Goals."},
{name: 'keywords', content: "Compressed Biogas Plants in Mumbai,  MGL News"},
{name: 'description', content:"Seamless and Convenient: Get Your Domestic PNG Connection for Home with MGL. Enjoy safe and reliable natural gas supply"},
{name: 'keywords', content: "PNG Connection For Home, Domestic PNG Connection"},
//{name: 'description', content:"Explore Mahanagar Gas' impactful CSR initiatives in their blog. Stay updated on MGL's community efforts and corporate social responsibility initiatives"},
{name: 'description', content:"Explore the latest blogs from Mahanagar Gas Ltd for valuable insights, updates, and news on natural gas, sustainability, and more. Stay informed with MGL!"},
{name: 'keywords', content: "Mgl Blog Unnati, Mahanagar Gas, CSR Initiative"},
// {name: 'description', content:"Mahanagar Gas Limited Project Vikas initiative, fostering holistic development across six villages, emphasizing sanitation, infrastructure, and livelihood."},
{name: 'description', content:"Mahanagar Gas to undertake holistic development of 6 villages under the Project Vikas initiative, enhancing infrastructure and sustainability in rural areas."},
{name: 'keywords', content: "Project Vikas initiative, Mahanagar Gas"},
// {name: 'description', content:"MGL partners with Baidyanath LNG, fostering LNG infrastructure across Maharashtra, aiming to accelerate cleaner fuel adoption in long-haul transport"},
{name: 'description', content:"Mahanagar Gas Partners with Baidyanath LNG to Expand CNG Network and Strengthen LNG Infrastructure"},
{name: 'keywords', content: "Mahanagar Gas, Baidyanath LNG"},
// {name: 'description', content:"Latest milestone as they inaugurate the Savroli City Gate Station, fortifying gas supply to benefit CNG and PNG consumers in Khopoli and neighboring areas"},
{name: 'description', content:"Mahanagar Gas Opens New City Gate Station at Savroli to Enhance Gas Distribution Network"},
{name: 'keywords', content: "Largest City Gas Distribution companies in India,  City Gate Station,"},
// {name: 'description', content:" MGL's Investor Guide. Your go-to resource for insights into our role as a leading Natural Gas Distribution Company"},
{name: 'description', content:"MGL's Investor Guide offers valuable insights into our role as a leading Natural Gas Distribution Company, providing key resources and updates for investors."},
{name: 'keywords', content: "Natural Gas Distribution Company"},
// {name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'description', content:"Stay updated with Mahanagar Gas Limited's latest CNG and Domestic PNG price changes across its network, ensuring transparency and convenience for all customers."},
{name: 'keywords', content: "Price of png, price of cng"},
// {name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'description', content:"Get the latest updates on CNG and Domestic PNG prices from Mahanagar Gas Limited, ensuring transparency and convenience across its extensive network."},
{name: 'keywords', content: "Price of png, price of cng"},
// {name: 'description', content:"MGL takes a step to reduce costs with lowered CNG and Domestic PNG prices. Stay informed on our CNG & PNG network updates"},
{name: 'description', content:"MGL Reduces CNG and Domestic PNG Prices to Lower Costs. Stay updated on the latest CNG & PNG network developments."},
{name: 'keywords', content: "Price of png, price of cng"},
// {name: 'description', content:"MGL introduces a pioneering solution to reduce CNG refuelling wait times with the first Mobile Refuelling Unit in India"},
{name: 'description', content:"MGL Launches India's First Mobile CNG Refuelling Unit to Reduce Wait Times and Enhance Convenience"},
{name: 'keywords', content: "India's leading Natural Gas Distribution Companies"},
{name: 'description', content:"MGL's Expression of Interest for Supply of Compressed Biogas. Join us in advancing sustainable energy solutions. Learn more and participate today!"},
{name: 'keywords', content: "Supply of Compressed Biogas"},
// {name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'description', content:"Stay informed with Mahanagar Gas Limited's latest updates on CNG and Domestic PNG prices, ensuring transparency and convenience across its extensive network."},
{name: 'keywords', content: "Price of png, price of cng"},
// {name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'keywords', content: "Price of png, price of cng"},
// {name: 'description', content:"Mahanagar Gas Limited latest updates on CNG and Domestic PNG prices across its widespread network, ensuring transparency and convenience."},
{name: 'description', content:"Get the latest updates from Mahanagar Gas Limited on CNG and Domestic PNG prices, ensuring transparency and convenience across its extensive network."},
{name: 'keywords', content: "Price of png, price of cng"},
// {name: 'description', content:"Mahanagar Gas Limited's vision and direction under the leadership of Managing Director, Mr. Ashu Shinghal. Explore our commitment to efficient energy solutions."},
{name: 'description', content:"Discover Mahanagar Gas Limited's vision under Managing Director Mr. Ashu Shinghal, showcasing our commitment to providing efficient and sustainable energy solutions."},
{name: 'keywords', content: "Managing Director of Mahanagar Gas Limited, Mr. Ashu Shinghal"},
// {name: 'description', content:"Stay updated on Mahanagar Gas Limited's latest news: reductions in CNG prices, changes in PNG rates, and current prices of CNG and PNG fuels. Get the newest updates here!"},
{name: 'description', content:"Stay updated on Mahanagar Gas Limited's latest news: CNG price reductions, PNG rate changes, and the latest CNG & PNG fuel prices. Get the newest updates here!"},
{name: 'keywords', content: "reduction in CNG price ,Price of png, price of cng"},
// {name: 'description', content:"Explore MGL's video gallery, the leading PNG and CNG gas distribution company. Discover our services and commitment to clean energy solutions."},
{name: 'description', content:"Explore MGL's video gallery to learn about our PNG and CNG gas distribution services and commitment to clean energy solutions as a leading provider in the industry."},
{name: 'keywords', content: "Leading Png, Cng Gas Distribution Company, Gas Distribution Company"},
// {name: 'description', content:"Reclaim your dividends with Mahanagar Gas. Explore the Unclaimed Dividend page to retrieve your earnings effortlessly. Secure your financial assets today."},
{name: 'description', content:"Reclaim your dividends with Mahanagar Gas. Visit the Unclaimed Dividend page to easily retrieve your earnings and secure your financial assets today."},
{name: 'keywords', content: "Mahanagar Gas, Dividends Page"},
// {name: 'description', content:"Explore Mahanagar Gas' impactful CSR initiatives in their blog. Stay updated on MGL's community efforts and corporate social responsibility initiatives"},
{name: 'description', content:"Explore Mahanagar Gas' impactful CSR initiatives through their blog. Stay informed on MGL's community efforts and corporate social responsibility projects."},
{name: 'keywords', content: "Mgl Blog, Mahanagar Gas, CSR Initiative"},
// {name: 'description', content:"About the Government of India's reduction in natural gas prices, impacting CNG and PNG rates. Explore the latest updates on CNG and PNG price adjustments by MGL"},
{name: 'description', content:"Learn about the Government of India's reduction in natural gas prices and its impact on CNG and PNG rates. Stay updated on MGL's latest CNG and PNG price adjustments."},
{name: 'keywords', content: "Natural Gas Price Reduction, reduction in CNG price ,Price of png, price of cng"},
// {name: 'description', content:"Stay informed about Mahanagar Gas Limited's gas cost increase and its impact on natural gas, CNG, and PNG prices. Learn about reductions in CNG rates."},
{name: 'description', content:"Stay informed on Mahanagar Gas Limited's gas cost increase and its impact on natural gas, CNG, and PNG prices. Get updates on reductions in CNG rates here."},
{name: 'keywords', content: "CNG price ,Price of png, price of cng, Natural Gas Price"},
{name: 'description', content:"Experience the ease of residential PNG connections with Mahanagar Gas. Access our registration form and terms & conditions effortlessly."},
{name: 'keywords', content: "mgl registration form ,mahanagar gas term & condition"},


]);


     }

       public setTitle( newTitle: string) {
  this.title.setTitle( newTitle );
 }

  ngOnInit(): void {
    this.FaqService.title = "All Data";
    this.rightClickDisable.disableRightClick();
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }

     this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
         let mysrt1 = event.url
        let finalsrt1 = mysrt1.split("/")
       // console.log(finalsrt1)
        this.headerFooter = (event.url !== '/cng/cng-offers-schemes/mobile-app/retrofitment'  && event.url !=='/cng/cng-offers-schemes/mobile-app/new-cng-vehicle-promotion' && event.url !=='/app-pay-success' && event.url !=='/app-pay-error'
          && event.url !=='/create-wallet' && event.url !=='/view-wallet'
          && event.url !=='/view-payu-transaction'
          && event.url !=='/apppayment-success/'+finalsrt1[2]  && event.url !=='/apppayment-error/'+finalsrt1[2]+"/"+finalsrt1[3]
           && event.url !=='/name-transfer/apply-for-name-app' && event.url !=='/name-transfer/'+finalsrt1[2]
            && event.url !=='/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/csc_Reg_Form')
      }
    });

     this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
      //  console.log('event',event)

        let mysrt = event.url
        let finalsrt = mysrt.split("/")
       // console.log(finalsrt)
        this.rightMenu = (event.url !=='/app-pay-success' && event.url !=='/app-pay-error' && event.url !=='/apppayment-success/'+finalsrt[2]  && event.url !=='/apppayment-error/'+finalsrt[2]+"/"+finalsrt[3]
          && event.url !=='/name-transfer/apply-for-name-app' && event.url !=='/name-transfer/'+finalsrt[2]
          && event.url !=='/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/csc_Reg_Form')
      }
    });


    this.setmySEO();

}

setmySEO(){
     this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationEnd) {
       // console.log(event.url)
      if(event.url =='/'){
        // this.setTitle("Mahanagar Gas: Reliable Gas Services Empowering Growth");
         this.setTitle("Mahanagar Gas: Trusted Gas Solutions Driving Growth");

       }else if(event.url =='/residential-png/customer-care/customer-care-overview'){
        this.setTitle("Customer Support for Piped Natural Gas (PNG) by Mahanagar Gas");

       }else if(event.url =='/cng/overview/overview-enviro-benifits'){
        this.setTitle("Advantages of Compressed Natural Gas (CNG) for Eco-Friendly Transportation");

       }else if(event.url =='/MGL-corporate/about-MGL'){
        this.setTitle("Mahanagar Gas: Premier Supplier of CNG and PNG Natural Gas");

       }else if(event.url =='/MGL-corporate/Contact-Us/registered-and-corporate-office-address'){
        this.setTitle("Contact Mahanagar Gas Limited (MGL) for PNG and CNG Services");

       }else if(event.url =='/MGL-corporate/awards'){
        this.setTitle("Mahanagar Gas Limited: Awards, Recognitions, and Key Achievements");

       }else if(event.url =='/industrial-and-commercial/conservation-tips-for-industrial-usage/clien-tele'){
        this.setTitle("MGL Services: Delivering Excellence in CNG, PNG, and Natural Gas Solutions");

       }else if(event.url =='/commercial-png-application'){
        this.setTitle("PNG Applications and Uses | Mahanagar Gas");

       }else if(event.url =='/residential-png/customer-zone/customer-login'){
        this.setTitle("Mahanagar Gas: Secure Customer Login and Service Access");

       }else if(event.url =='/MGL-corporate/vision'){
        this.setTitle("Register for Piped Natural Gas (PNG) & CNG Services | Mahanagar Gas");

       }else if(event.url =='/MGL-corporate/investors/annual-report'){
        this.setTitle("MGL Annual Report: Investor Resources | Mahanagar Gas Ltd");

       }else if(event.url =='/residential-png/customer-zone/name-transfer'){
        this.setTitle("How to Transfer Residential PNG Gas Connection with MGL");

       }else if(event.url =='/residential-png/customer-zone/name-transfer/apply-for-name'){
        this.setTitle("Residential PNG Name Transfer Process | MGL Guide");

       }else if(event.url =='/residential-png/customer-zone/name-transfer/name-transfer-status'){
        this.setTitle("Apply for Residential PNG Name Transfer: Application & Status | MGL");

       }else if(event.url =='/residential-png/customer-zone/consumer-safty-checklist'){
        this.setTitle("Residential PNG Safety Checklist & Service Details | MGL");

       }else if(event.url =='/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request'){
        this.setTitle("Request a Domestic PNG Gas Connection | MGL");

       }else if(event.url =='/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check'){
        this.setTitle("Track Your Residential PNG Connection Request Status | MGL");

       }else if(event.url =='/residential-png/domestic-connection-request/png-rate-card'){
        this.setTitle("Residential PNG Rate Card: Piped Natural Gas Prices | Mahanagar Gas");

       }else if(event.url =='/residential-png/domestic-connection-request/tech-guide-for-png-installation'){
        this.setTitle("PNG Installation Guidelines: Safety Instructions | Mahanagar Gas");

       }else if(event.url =='/residential-png/domestic-connection-request/png-network'){
        this.setTitle("Residential PNG Services Network | Mahanagar Gas Limited");

       }else if(event.url =='/residential-png/view-authorized-personnel'){
        this.setTitle("Authorized Personnel for PNG Services | Mahanagar Gas");

       }else if(event.url =='/residential-png/policies-and-procedures/important-policy'){
        this.setTitle("Key Policies & Guidelines | Mahanagar Gas");

       }else if(event.url =='/residential-png/policies-and-procedures/aftersales-services/png-service-charges'){
        this.setTitle("Residential PNG Service Charges | Mahanagar Gas");

       }else if(event.url =='/residential-png/policies-and-procedures/aftersales-services/service-provider'){
        this.setTitle("Piped Natural Gas Services for Homes | Mahanagar Gas");

       }else if(event.url =='/residential-png/if-you-smell-gas'){
        this.setTitle("Safety Guidelines for Residential PNG Usage | Mahanagar Gas");

       }else if(event.url =='/sitemap'){
        this.setTitle("Mahanagar Gas Sitemap: Explore Website Navigation");

       }else if(event.url =='/privacy-policy'){
        this.setTitle("Terms and conditions | Privacy Policy | Mahanagar Gas");

       }else if(event.url =='/blog/645e117faa47103642606283'){
        this.setTitle("Mahanagar Gas | Blogs | Unnati");

       }else if(event.url =='/blog/646d7ea243e2e031643f51db'){
        this.setTitle("Mahanagar Gas | Blogs | MoU Between MGL & Baidyanath LNG (B-LNG)");

       }else if(event.url =='/blog/6427e565e215531fbd0915f8'){
        this.setTitle("Mahanagar Gas | Blogs | Training for Mask & Sanitizer Making");

       }else if(event.url =='/blog/6427e518e215531fbd0915f7'){
        this.setTitle("MGL Arogya's: Healthcare Impact in Fighting the COVID-19");

       }else if(event.url =='/blog/645e107eaa47103642606248'){
        this.setTitle("MGL Aarogya: A Lifeline for Health & Humanity");

       }else if(event.url =='/user-registration'){
        this.setTitle("Mahanagar Gas | PNG Provider | User Registration online");

       }else if(event.url =='/MGL-corporate/leadership'){
        this.setTitle("Mahanagar Gas | Corporate Leadership | Leading PNG & CNG Service");

       }else if(event.url =='/MGL-corporate/media/newsletters'){
        this.setTitle("Natural Gas Distribution Company| MGL Newsletters");

       }else if(event.url =='/MGL-corporate/award/2022'){
        this.setTitle("Mahanagar Gas |  Awards 2022, Achievements and Recognitions");

       }else if(event.url =='/cng/customer-zone/saving-calculator'){
        this.setTitle("CNG Savings Calculator | Mahanagar Gas | CNG Gas Supplier");

       }else if(event.url =='/MGL-corporate/award/2023'){
        this.setTitle("Achievements and Recognitions | Mahanagar Gas| Awards 2023");

       }else if(event.url =='/cng/customer-zone/car-care'){
        this.setTitle("Comprehensive CNG Car Care Tips - Mahanagar Gas");

       }else if(event.url =='/residential-png/customer-care/dropbox'){
        this.setTitle("Customer Care | Mahanagar Gas | Residential PNG Connection");

       }else if(event.url =='/MGL-corporate/csr/csr-policy'){
        this.setTitle("Mahanagar Gas | Corporate CSR Policy | PNG & CNG Service");

       }else if(event.url =='/cng/dealer-zone/mobile-unit'){
        this.setTitle("MGL | Request for CNG Composite Dispensing Unit | CNG & PNG");

       }else if(event.url =='/MGL-corporate/media/news/647f110193ddf62e4901fd1f'){
        this.setTitle("MGL | Reduces CNG and Domestic PNG Price| News");

       }else if(event.url =='/MGL-corporate/media/news/641804a4f270bfcd0b874bb3'){
        this.setTitle("MGL Arogya : Free Eye check-up Camp for Auto rickshaw Drivers");

       }else if(event.url =='/MGL-corporate/career/current-opp'){
        this.setTitle("Mahanagar Gas | Career Opportunities");

       }else if(event.url =='/MGL-corporate/asset-integrity'){
        this.setTitle("Mahanagar Gas | Natural Gas Distribution Company | Asset-Integrity");

       }else if(event.url =='/industrial-and-commercial/ic-bill'){
        this.setTitle("MGL | CNG & PNG Services | IC Bill Online");

       }else if(event.url =='/MGL-corporate/media/news/647ef751bd5b92dcbc9a6bee'){
        this.setTitle("Mahanagar Gas | Conducts Cycle Rally in Thane and Navi Mumbai");

       }else if(event.url =='/MGL-corporate/media/news/647efc9a93ddf62e4901f70e'){
        this.setTitle("Mahanagar Gas | MGL Revises PNG and CNG price");

       }else if(event.url =='/MGL-corporate/media/news/647ef8b9bd5b92dcbc9a6ce2'){
        this.setTitle("Mahanagar Gas | MGL Celebrates its 25 years Journey");

       }else if(event.url =='/MGL-corporate/investors/corporate-governance'){
        this.setTitle("Mahanagar Gas | Corporate Governance | PNG & CNG Service");

       }else if(event.url =='/MGL-corporate/media/news/647ef6296733e3241f1c47fa'){
        this.setTitle("Mahanagar Gas | Price of CNG | Press Release");

       }else if(event.url =='/qr-pay'){
        this.setTitle("MGL | Bills Payment Online | Pay Gas Bills Online");

       }else if(event.url =='/forgot-password'){
        this.setTitle("Mahanagar Gas | Forgot Password | Change Password | Customer Login");

       }else if(event.url =='/blog/646d7e552f44e3e6b23a73c5'){
        this.setTitle("MGL TEZ | CNG Fuel Supply To B.E.S.T | CNG Facility");

       }else if(event.url =='/blog/645e0daceed5dca75bd4a094'){
        this.setTitle("Mahanagar Gas | MGL Saksham | Blog");

       }else if(event.url =='/blog/645e0d34352d3d11603845b6'){
        this.setTitle("Mahanagar Gas | MGL Vidya | Blog");

       }else if(event.url =='/faq-list'){
        this.setTitle("PNG CNG Services | FAQs List | Mahanagar Gas");

       }else if(event.url =='/MGL-corporate/investors/policies'){
        this.setTitle("Mahanagar Gas Limited | CNG,PNG Supplier |Inverter Policies");

       }else if(event.url =='/MGL-corporate/MGL-sahayogi'){
        this.setTitle("Mahanagar Gas Limited | MGL Sahayogi | Leading PNG Gas Supplier");

       }else if(event.url =='/MGL-corporate/media/news/647ef83d0e6dd0f9995922b5'){
        this.setTitle("Mahanagar Gas | MGL CNG Chalate Raho | News");

       }else if(event.url =='/cng/customer-zone/cng-kit'){
        this.setTitle("RTO Approved CNG Kit Distributors | Mahanagar Gas");

       }else if(event.url =='/MGL-corporate/media/news/647ef5db0e6dd0f9995921de'){
        this.setTitle("CNG and Domestic PNG in Mumbai | Price of CNG | Press Release");

       }else if(event.url =='/MGL-corporate/media/news/647efbcebc944f5ee92b898b'){
        this.setTitle("CNG and Domestic PNG Gas Line | MGL revises PNG and CNG price | MGL");

       }else if(event.url =='/MGL-corporate/media/news/648d580f9bd67db3519d242c'){
        this.setTitle("Mahanagar Gas Limited Begins PNG Supply at Raj Bhavan");

       }else if(event.url =='/blog/6427e48ce215531fbd0915f5'){
        this.setTitle("Mainstreaming Out of School Children: Challenges and Solutions ");

       }else if(event.url =='/blog/645f0056e1300f7505b39331'){
        this.setTitle("MGL Hunar: Empowering Women Through Key Interventions");

       }else if(event.url =='/MGL-corporate/media/news/647f0f1a69e5bb3e83ab5a39'){
        this.setTitle("MGL Announces Revision in PNG and CNG Prices");

       }else if(event.url =='/MGL-corporate/media/news/647efd82236384271725d31e'){
        this.setTitle("Mahanagar Gas (MGL) Press Release - July 14, 2021");

       }else if(event.url =='/cng/cng-offers-schemes/retrofitment'){
        this.setTitle("Mahanagar Gas Limited Launches 'MGL CNG Mahotsav' Event");

       }else if(event.url =='/cng/customer-zone/cng-locator'){
        this.setTitle("Mahanagar Gas Limited | CNG Station Locator & MGL CNG");

       }else if(event.url =='/MGL-corporate/media/news/647efb6593ddf62e4901f6b3'){
        this.setTitle("Mahanagar Gas Launches 'MGL Hat-trick Yojana' for CNG Vehicles");

       }else if(event.url =='/MGL-corporate/media/news/647f0f976733e3241f1c5029'){
        this.setTitle("MGL Opens 'Cardiology Ward' at MCGM's Nair Hospital for Public");

       }else if(event.url =='/MGL-corporate/media/news/648d5a2eb7929ab6b40ef528'){
        this.setTitle("Mahanagar Gas to Set Up CBG Plant in Mumbai with BMC Support");

       }else if(event.url =='/residential-png/customer-zone/domestic-connection'){
        this.setTitle("PNG Connection For Home | Domestic PNG Connection | MGL");

       }else if(event.url =='/BlogLists'){
        this.setTitle("Explore the Latest Blogs | Mahanagar Gas Ltd - Insights & Updates");

       }else if(event.url =='/MGL-corporate/media/news/647efe706733e3241f1c4ab0'){
        this.setTitle("Mahanagar Gas to Develop 6 Villages Under Project Vikas");

       }else if(event.url =='/MGL-corporate/media/news/646d9d5ddb075d826be0f50e'){
        this.setTitle("Mahanagar Gas Partners with Baidyanath LNG to Expand Network");

       }else if(event.url =='/MGL-corporate/media/news/641972bd9fe373041a80044b'){
        this.setTitle("Mahanagar Gas Opens City Gate Station at Savroli to Boost Network");

       }else if(event.url =='/MGL-corporate/investors/investor-guide'){
        this.setTitle("Investor Guide - Resources and Updates | Mahanagar Gas Limited");

       }else if(event.url =='/MGL-corporate/media/news/647f10100e6dd0f9995929b1'){
        this.setTitle("MGL Announces Revised PNG and CNG Prices");

       }else if(event.url =='/MGL-corporate/media/news/647f1162bd5b92dcbc9a743a'){
        this.setTitle("MGL Announces Revision in CNG Price | Jan 31, 2023");

       }else if(event.url =='/MGL-corporate/media/news/647f1050bc944f5ee92b8fa6'){
        this.setTitle("MGL Implements CNG Price Revision | Apr 1, 2022");

       }else if(event.url =='/MGL-corporate/media/news/647efdfc93ddf62e4901f761'){
        this.setTitle("Mahanagar Gas Launches India's First Mobile CNG Refueling Unit");

       }else if(event.url =='/MGL-corporate/expression-of-interest'){
        this.setTitle("MGL | Supply of Compressed Biogas | Expression of Interest");

       }else if(event.url =='/MGL-corporate/media/news/647f0be20e6dd0f999592873'){
        this.setTitle("MGL Revises PNG and CNG Prices on October 4, 2022");

       }else if(event.url =='/MGL-corporate/media/news/647f0cc40e6dd0f9995928ac'){
        this.setTitle("MGL Announces PNG and CNG Price Revision on August 17, 2022");

       }else if(event.url =='/MGL-corporate/media/news/647f0d8e0e6dd0f9995928e5'){
        this.setTitle("MGL Revises CNG and D-PNG Prices on July 13, 2022");

       }else if(event.url =='/MGL-corporate/media/news/64180ba9f270bfcd0b874d40'){
        this.setTitle("Ashu Shinghal Appointed Managing Director of Mahanagar Gas Limited");

       }else if(event.url =='/MGL-corporate/media/news/6519a69a033f13d26dadd071'){
        this.setTitle("MGL Reduces CNG and PNG Prices");

       }else if(event.url =='/MGL-corporate/media/gallery/video-gallary'){
        this.setTitle("Video Gallery | Mahanagar Gas Limited Media Center");

       }else if(event.url =='/MGL-corporate/investors/dividend/unclaimed-dividend'){
        this.setTitle("Unclaimed Dividend | Mahanagar Gas Limited Investors");

       }else if(event.url =='/MGL-corporate/csr/csr-initiative'){
        this.setTitle("CSR Initiatives | Mahanagar Gas Limited Corporate Social Responsibility");

       }else if(event.url =='/MGL-corporate/media/news/647ef966483322b4e4afd040'){
        this.setTitle("Press Release - April 5, 2020 | Mahanagar Gas Limited");

       }else if(event.url =='/MGL-corporate/media/news/647ef68a483322b4e4afcf07'){
        this.setTitle("Press Release - April 4, 2019 | Mahanagar Gas Limited");

       }else if(event.url =='/regitration-form-terms-and-condition_0d27e650ee.pdf'){
        this.setTitle("Registration Terms & Conditions | Mahanagar Gas");

       }else{
         this.setTitle("Mahanagar Gas: Innovating Natural Gas Solutions Across India");
       }
      }
    });
    
}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      let loader = this.renderer.selectRootElement('#loader');
      if (loader.style.display != "none") loader.style.display = "none"; //hide loader
     // console.log("test view init")
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    if (window.pageYOffset > 120) {
      let element = this.document.getElementById('navbar');
      element!.classList.add('sticky');
    } else {
      let element = this.document.getElementById('navbar');
      element!.classList.remove('sticky');
    }
  }
}
