import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { TenderService } from 'src/app/Service/tenders/tender.service';
import { TenderResp } from 'src/app/models/tender';
import { appliconttenderResp } from 'src/app/models/tender';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { appendFile } from 'fs';
@Component({
  selector: 'app-active-tender',
  templateUrl: './active-tender.component.html',
  styleUrls: ['./active-tender.component.css']
})
export class ActiveTenderComponent {
  tenderData: any= []
  applicon:appliconttenderResp[]=[]
  display:string='none'
  AllDataStatus:boolean=true
  NewDataStatus:boolean=false
  Popup:any=''
  captcha:any
  activetender!:FormGroup
  submitted=false
tender:any
alertType: any = ''
alertMsg: any = ''
alertColor: string = ''
alertTextColor: string = ''
alertBorder: string = ''
alertTextAlign: string = 'center'
tendercatArray:any;
tendcat_id:any;

  constructor(private pageLoaderService: PageLoaderService, private service: TenderService,private FormBuilder:FormBuilder) { }
  ngOnInit(): void {
    this.captchaGenerate()

    this.activetender=this.FormBuilder.group({
      NameOfPerson:['',[Validators.required]],
      MobileNumber:['',[Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
      Email:['',[Validators.required]], 
      Company:['',[Validators.required]],
      CategoryName:[''],
      ReferenceNo:[''],
      TenderName:[''],

	    Captcha: ['',[Validators.required]],
    })

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
	this.getTender();
  this.get_tender_cat_list();
  // this.get_tender_list_using_category();

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getTender() {
    this.service.getNewTenders().subscribe((res: any) => {
      this.tenderData = res
      //console.log(this.tenderData)
    })
  }
  openPopup(val:any){
    //console.log(val)
    this.tender = val
    this.Popup='show'
    this.display='block'
    
  }
  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result
  } 
   submit(){
    
    this.submitted=true
     if(this.activetender.get('Captcha')?.value != this.captcha){
      this.activetender.get('Captcha')?.setValue('')
    }this.activetender
    if(this.activetender.invalid){	
      return
    }
    let data = JSON.parse(JSON.stringify(this.tender))
    //console.log(data.CategoryName)
    this.activetender.get('CategoryName')?.setValue(data.CategoryName)
    this.activetender.get('ReferenceNo')?.setValue(data.ReferenceNo)
    this.activetender.get('TenderName')?.setValue(data.TenderName)
    var NameOfPerson = this.activetender.controls['NameOfPerson'].value
    var MobileNumber = this.activetender.controls['MobileNumber'].value
    var Email = this.activetender.controls['Email'].value
    var Company = this.activetender.controls['Company'].value
    this.service.insert(this.activetender.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      this.alertType='submit'
        this.alertMsg = "Submitted"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'                 
        setTimeout(() => {
          this.alertType=''
          this.activetender.reset()
          this.submitted = false
        }, 1500);
      
      if(data.response){
        
      }
    })
    
    
  }







reset(){
  this.activetender.reset()
  setTimeout(() => {

  }, 300);
 this.submitted=false

}

clearAlert() {
    this.alertType = ''
  }


 get_tender_list_using_category(){
    let obj = {'TenderCategoryId':this.tendcat_id}
     this.service.get_tender_list_using_category(obj).subscribe(response => {
    
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        
         this.tenderData = res.details;
      
        } else {
          this.tenderData =[]
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'getlist'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      
    })

  }


  get_tender_cat_list(){
  
     this.service.get_tender_cat_list().subscribe(response => {
    
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         this.tendercatArray = res.details;
         
      
        } else {
          this.tendercatArray =[];
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'getcatlist'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      
    })

  }

}
