<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
      <h1>Active-tender</h1>
      <div class="breadcrumbs">
        <ul class="">
          <li class=""><a [routerLink]="['/']">Home</a></li>
                  <li class=""><a >MGL Corporate</a></li>
                  <li class=""><a >Contracts & Procurements</a></li>
                  <li class=""><a >Tenders</a></li>
          <li class="active" aria-current="page"><a href="#">Active-tender</a></li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Services Section Start -->
  <!-- <app-service-section></app-service-section> -->
  <app-mgl-corporate-service-section></app-mgl-corporate-service-section>
  <!-- Services Section End -->
  
  <!-- Inner Page Content Section Start -->
  <div class="innerpage-content-section">
    <div class="container custom">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
          <app-recent-post></app-recent-post>
  
          <app-tips></app-tips>
           <app-insta-post></app-insta-post>
  
        <!--   <div class="mt-30">
            <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
              mahanagargas</a>
          </div> -->
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
          <div class="innerpage-right-content">
            <div class="row y-middle justify-content-center">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <label><b>Active Tenders</b> </label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="select-area">
                  <select  (change)="get_tender_list_using_category()" [(ngModel)]="tendcat_id">
                    <option selected="selected" value="">-Select Category-</option>
                    <option *ngFor="let item of tendercatArray" value="{{item.TenderCategoryId}}">{{item.CategoryName}}</option>
                  
                  </select>
                </div>
              </div>
            </div>
  
  
            <div class="light-blue-bg mt-30 customer-care-overview-icon">
                        <div *ngIf="alertType == 'getlist'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
              <div class="white-box mt-30 mb-30" *ngFor="let i of tenderData">
                <div class="tender-setion">
                  <div class="tender-head">
                    <div class="tendername">
                      <p>Tender Name</p>
                      <h6>{{i.TenderName}}</h6>
                    </div>
                    <a href="https://www.mahanagargas.com:3000/{{i.TenderDoc1_NewName}}" target="_blank" class="bluebtn2 d-block">View Eligibility Criteria</a>
                    <!-- <a (click)="openPopup(i)" class="bluebtn2 d-block">Apply Tender <i class="demo-icon uil-arrow-right"></i></a> -->
                  </div>
                  <div class="tender-body">
                    <div class="category-sect">
                      <p>Category</p>
                      <h6>{{i.CategoryName}}</h6>
                    </div>
                    <div class="category-sect">
                      <p>Tender Reference No</p>
                      <h6>{{i.ReferenceNo}}</h6>
                    </div>
                    <div class="category-sect">
                      <p>Name of Department Floating Tender</p>
                      <h6>{{i.DeptFloatingTender}}</h6>
                    </div>
                    <div class="category-sect">
                      <p>Date of pre bid conference:</p>
                      <h6>{{i.FloatedOnDate | date: 'dd/MM/yyyy'}} at {{i.at_time}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Date of opening technical bid:</p>
                      <h6>{{i.OpeningOnDate | date: 'dd/MM/yyyy'}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Last date of Submission:</p>
                      <h6>{{i.SubmissionDate | date: 'dd/MM/yyyy'}} upto {{i.ClosingDatetime}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Description:</p>
                      <h6>{{i.Description}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Address:</p>
                      <h6>{{i.PrintDocAddress}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Tender Date:</p>
                      <h6>{{i.tender_date}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Sale Start Date:</p>
                      <h6>{{i.FromDate | date: 'dd/MM/yyyy'}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Sale End Date:</p>
                      <h6>{{i.ToDate | date: 'dd/MM/yyyy'}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Sale Timing:</p>
                      <h6>{{i.FromTime}} to {{i.ToTime}}</h6>
                    </div>
                     <div class="category-sect">
                      <p>Tender Fee:</p>
                      <h6>Rs.{{i.tender_fee}}/-(Including GST)</h6>
                    </div>
                     <div class="category-sect">
                      <p>Earnest Money Deposit:</p>
                      <h6>Rs.{{i.Earnest_Money_Deposit}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  <!-- <div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Main content End -->
  </div>  
  
  
  <!-- <div class="modal fade directors-sd {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
         aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right':'20px'}" aria-modal="true"
       role="dialog">
      <div  class="modal-dialog modal-xl">
          <div class="modal-content">
              <button (click)="this.closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
              <div class="modal-body">
                  <div class="director-modal">
                    <div class="col-lg-12 col-md-9 col-sm-12">
                      <div class="light-blue-bg">
                          <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                                <h4>Tender Contact Form</h4>
  
                                  <div class="customer-login-page log-meter-reading">
                                      <form [formGroup]="activetender">
                                          <div class="row">
                                              <div class="col-sm-6 form-group">
                                                  <div class="form-floating">
                                                  <input type="text" class="form-control" formControlName="NameOfPerson"
                                                      placeholder="Enter full name here"
                                                      [ngClass]="{ 'is-invalid': submitted && activetender.controls['NameOfPerson'].errors }" id="NameOfPerson" />
                                                  <div *ngIf="activetender && activetender.controls['NameOfPerson'].errors"
                                                      class="invalid-feedback">
                                                      <div *ngIf="activetender.controls['NameOfPerson'].errors.required">Required Field</div>
                                                  </div>
                                                  <label for="NameOfPerson">Name of Contact Person<span style="color: crimson;">*</span></label>
                                                  </div>
                                              </div>
                                              <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Email"
                                                    placeholder="Enter full name here"
                                                    [ngClass]="{ 'is-invalid': submitted && activetender.controls['Email'].errors }" id="Email" />
                                                <div *ngIf="activetender && activetender.controls['Email'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="activetender.controls['Email'].errors.required">Required Field</div>
                                                </div>
                                                <label for="Email">Email<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 form-group">
                                              <div class="form-floating">
                                              <input type="text" class="form-control" formControlName="MobileNumber"
                                                  placeholder="Enter full name here"
                                                  [ngClass]="{ 'is-invalid': submitted && activetender.controls['MobileNumber'].errors }" id="MobileNumber" />
                                              <div *ngIf="activetender && activetender.controls['MobileNumber'].errors"
                                                  class="invalid-feedback">
                                                  <div *ngIf="activetender.controls['MobileNumber'].errors.required">Required Field</div>
                                                  <div *ngIf="activetender.controls['MobileNumber'].errors.pattern">invalid Mobile Number</div>
  
                                              </div>
                                              <label for="MobileNumber">Mobile Number<span style="color: crimson;">*</span></label>
                                              </div>
                                          </div>
                                          
                                          <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Company"
                                                placeholder="Enter full name here"
                                                [ngClass]="{ 'is-invalid': submitted && activetender.controls['Company'].errors }" id="Company" />
                                            <div *ngIf="activetender && activetender.controls['Company'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="activetender.controls['Company'].errors.required">Required Field</div>
                                            </div>
                                            <label for="Company">Company Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                              <div class="col-sm-6 form-group">
                                                  <div class="row">
                                                  <div class="col-lg-5 col-md-5 col-sm-5">
                                                    <div class="mt-10">
                                                     <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i> </div>
                                                  </div>  
                                                   </div>
                                                  <div class="col-lg-7 col-md-7 col-sm-7">
                                                  <div class="form-floating">
                                                  <input type="text" class="form-control" formControlName="Captcha"
                                                      placeholder="Captcha"
                                                      [ngClass]="{ 'is-invalid': submitted && activetender.controls['Captcha'].errors }" id="Captcha" />
                                                  <div *ngIf="activetender && activetender.controls['Captcha'].errors"
                                                      class="invalid-feedback">
                                                      <div *ngIf="activetender.controls['Captcha'].errors.required">Required Field
                                                      </div>
                                                    </div>
                                                  <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                                                  </div>
                                                  </div>
                                                     
                                                  </div>
                                                </div>
                                          </div>
                                          <div class="mt-20">
                                          <div class="row">
                                              <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                  <button (click)="submit()" class="greenbtn2">Submit <i class="demo-icon uil-arrow-right"></i></button>
                                              </div>
                                              <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                  <div class="text-right md-text-left">
                                                      <button (click)="reset()" class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></button>
                                                  </div>
                                              </div>
  
                                              <div *ngIf="alertType == 'submit'"
                                              [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                              class="card card-inverse-info" id="context-menu-simple">
                                              <div class="card-body">
                                                  <p class="card-text">
                                                      {{alertMsg}}
                                              </div>
                                           </div>
                                          </div>
                                          </div>
                                      </form>
                                      <div class="text-right mt-20">
                                          <p class="mb-0"><b><i>Please note all fields marked <span class="text-danger">*</span> are compulsory.</i></b></p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div> -->