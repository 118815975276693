import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UpiautopaymandateService } from 'src/app/Service/upiautopaymandate.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
declare var Digio:any
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-upiautopay',
  templateUrl: './upiautopay.component.html',
  styleUrls: ['./upiautopay.component.css']
})
export class UpiautopayComponent {

  UserForm!:FormGroup
  submited = false

  status:boolean=false
  captcha:any

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center' 
  mandate_id:any
  customer_identifier:any
  checktodayDate:any

  constructor(private FormBuilder : FormBuilder,public Service : UserRegistrationService, private pageLoaderService: PageLoaderService,private upiService : UpiautopaymandateService,
    public router: Router,) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.Service.checkLogin();

    this.captchaGenerate()

    this.UserForm = this.FormBuilder.group({
      Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),Validators.required]],
      Name:['',[Validators.required]],
      Dates:['',[Validators.required]],
      FDates:['',[Validators.required]],
      Captcha:['',[Validators.required]],
      CAno:[''],
      Amt:['',[Validators.required]],
     // upi:['',[Validators.required]],
      upi: ['', [Validators.pattern('[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}'),Validators.required]],
    })

    
  
    // let data = sessionStorage.getItem('UserCredential');
    let data = ''
     let newData = sessionStorage.getItem('UserCredential_New');
  if (newData !== null) { 
  try {
    let data1 = crypto.AES.decrypt(newData, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
  
    let decryptedString = data1.toString(crypto.enc.Utf8);
    //console.log("Decrypted Data:", JSON.parse(decryptedString));
    data=decryptedString
  } catch (error) {
   // console.error("Decryption Error:", error); 
  }
} else {
 // console.error("User credentials not found in session storage.");
 
}
    //console.log(data)
    if(!data){
      this.status=true
    }else{
      this.UserForm.get('CAno')?.setValue(JSON.parse(data).CaNo)
      this.UserForm.get('Name')?.setValue(JSON.parse(data).FirstName)
       this.UserForm.get('Email')?.setValue(JSON.parse(data).Email)
         var FromDate = new Date();
      var Frommonth = (FromDate.getMonth() + 1);
      var Fromday = (FromDate.getDate());
      var Fromcomate = new Date(FromDate.getFullYear(), Frommonth-1, Fromday);
       var Fromresult = Fromcomate.toLocaleDateString("en-GB", { // you can use undefined as first argument
       year: "numeric",
       month: "2-digit",
       day: "2-digit",
        });
        var Fromsplit = Fromresult.split('/');
        var Fromformatted= Fromsplit[0]+"-"+Fromsplit[1]+"-"+Fromsplit[2];
        //console.log(Fromformatted)
         this.UserForm.get('Dates')?.setValue(Fromformatted)
         this.checktodayDate = Fromsplit[2]+"-"+Fromsplit[1]+"-"+Fromsplit[0];
      //console.log(this.UserForm.value)
    }
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  submit(){
    this.submited=true

    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
    this.captchaGenerate();
    if(this.UserForm.invalid){
      return
    }

    if(this.UserForm.get('Amt')?.value < 500 || this.UserForm.get('Amt')?.value > 5000){
    return alert("Minimum amount should be 500 and maximum amount should be 5000");
    }

    var todayDate =   new Date(this.checktodayDate)
    var selectDate =   new Date(this.UserForm.get('FDates')?.value)
   if (todayDate<=selectDate){
       //console.log('true')

    }else{
    return alert("Final collection date cannot be lesser than collection start date");
    }

    

             let obj1={CANo:this.UserForm.get('CAno')?.value,Email:this.UserForm.get('Email')?.value,
             Name:this.UserForm.get('Name')?.value,
        FirstCollectionDate:this.UserForm.get('Dates')?.value,
         FinalCollectionDate:this.UserForm.get('FDates')?.value,
        Amount:this.UserForm.get('Amt')?.value,
         UPI:this.UserForm.get('upi')?.value,
           UMN:'123456',
          Status:'New',Frequency:'ASPRESENTE'}

 this.upiService.RegUpiautoMandate(obj1).subscribe(response=>{
  //console.log(response)
  let data = JSON.parse(JSON.stringify(response))
     //console.log(data)
     if (data.response) {
    alert("register successful");
     this.UserForm.reset();
     }else{
   alert("register not successful");
     }
//       if (data.response) {

//         alert('NACH mandate information is submitted partially. Please fill the bank details from NACH popup')

//          this.mandate_id = data.details.mandate_id;
//          console.log('this.mandate_id',this.mandate_id);
         
//          //this.UserForm.get('Email')?.value
//          this.customer_identifier = this.UserForm.get('Email')?.value;

//                   var options = {
//           //default environment staging (sandbox)
//            "environment" : "production",
//           //"environment" : "sandbox",
//           // "environment" : "https://api.digio.in/v3/client/mandate/",// here
//            "callback":(t:any)=>{
//         if(t.error_code!=undefined){
//         alert("failed to register");
//          this.checkStatus()
       
//         }else{ 
//         alert("register successful for "+t.digio_doc_id);
//         this.checkStatus()
        
//       }
//          },                                                    
//          "logo":" https://mahanagargas.com/assets/images/logo.png", //Pass Logo URL here
//           "is_iframe": true ,//open in iframe,
//          "dg_preferred_auth_type": "debit"  // debit or net_banking
//        };

// var digio:any = new Digio(options);
// digio.init();  // Call init on user action (eg.button press and proceed to asynchronous upload)
// digio.submit(this.mandate_id,this.customer_identifier); 
// // Get the documentId by calling Digio upload APIs. Email/Mobile is signer’s identifier
// //In case of failure to retrieve DocumentId call digio.cancel();

//       }else{

//         alert('NACH mandate form is not submitted due to some problem, try later!')

//       }

    
 

  })
    




  }

// checkStatus(){
//     let obj = {Mandate_Id:this.mandate_id}
//      this.Service.get_NachMandate_status(obj).subscribe(response=>{
//      console.log(response)
//      let data = JSON.parse(JSON.stringify(response))
//      if (data.Response) {
//        console.log(data)

//         let obj1={CAno:this.UserForm.get('CAno')?.value,Email:this.UserForm.get('Email')?.value,Name:this.UserForm.get('Name')?.value,
//         Dates:this.UserForm.get('Dates')?.value,Amt:this.UserForm.get('Amt')?.value,UMRN:data.details.umrn,
//           Mandate_Id:data.details.mandate_id,Mandate_Status:data.details.state}
//         this.dashboardService.postNachOnline(obj1).subscribe(res=>{
//       let data1 = JSON.parse(JSON.stringify(res))

//       if(data1.Response){
//          console.log('in response',data)
//          if(data.details.state=='partial'){
//           this.router.navigate(['/pay-error/'+data.details.mandate_id+'/You have cancelled the Payment'])

//          }else if(data.details.state=='auth_failed'){
//           this.router.navigate(['/pay-error/'+data.details.mandate_id+'/Your Payment is Failed,Please try again'])

//          }else{
//            this.router.navigate(['/pay-success/'+data.details.mandate_id])
//          }
//          }else{
//         this.alertType='nach'
//         this.alertMsg = "Something Went Wrong"
//         this.alertColor = 'rgba(255, 94, 109, 0.2)'
//         this.alertTextColor = '#c24753'
//         this.alertBorder = '1px solid #eb5664;'
//         setTimeout(() => {
//           this.alertType=''
//         }, 1000);
//       }

        
//     })

//       }else{

//          this.alertType='nach'
//         this.alertMsg = "Something Went Wrong"
//         this.alertColor = 'rgba(255, 94, 109, 0.2)'
//         this.alertTextColor = '#c24753'
//         this.alertBorder = '1px solid #eb5664;'
//         setTimeout(() => {
//           this.alertType=''
//         }, 1000);

//       }
//   })
//   }



}