


<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>{{'Customer Login' | translate}} </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">{{'Home' | translate}}</a></li>
                <li class=""><a href="#">{{'Residential PNG ' | translate}}</a></li>
                <li class=""><a href="#">{{'Customer Zone' | translate}}</a></li>
                <li class="active" aria-current="page">{{'Customer Login' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
               <app-customer-awareness></app-customer-awareness>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1"> 
                <div class="light-blue-bg mb-lg-0 mb-30 ">
                    <form [formGroup]="UserForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
                                 <div class="customer-login-page">
                                    <div class="log-meter-text">
                                        <ul>
                                         <li>* Log your Meter Reading without registering, <a [routerLink]="['/residential-png/customer-zone/without-registering-log-meter-reading']"
                                                >Click here</a>
                                            </li>
                                            <li> * Kindly enter <a href="#">BP</a> or <a href="#">CA</a> number as
                                                appearing on your bill.</li>
                                        </ul>
                                    </div>
                                    <div *ngIf="alertType == 'login'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info mb-3" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                            </p>
                                        </div>
                                    </div>
                                     <div *ngIf="alertMsgNotfoundFlag == true">
                                     <div *ngIf="alertType == 'login1'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info mb-3" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                <li>BP/CA not registered in MGL website. Please click here <a style="color:blue;" [routerLink]="['/user-registration']"
                                                ><u>Register Now</u></a>
                                            </li>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div class="">
                                        <div class="form-floating mb-3">
                                         <input [ngClass]="{'is-invalid':submitted && UserForm.controls['BPnoCAno'].errors}" formControlName="BPnoCAno" class="form-control" type="number" onKeyPress="if(this.value.length==12) return false;" id="uname" name="name" placeholder="Enter your BP No./ CA No.">
                                          <label for="uname">Enter your BP No/ CA No</label>
										  <div *ngIf="submitted && UserForm.controls['BPnoCAno'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['BPnoCAno'].errors">Required Field</div>
                                              </div>
                                        </div>
                                        <div class="form-floating mb-3">
                                          <input [ngClass]="{'is-invalid':submitted && UserForm.controls['mobileNo'].errors}" type="number" class="form-control" id="floatingPassword" placeholder="Password" formControlName="mobileNo"  onKeyPress="if(this.value.length==10) return false;">
                                          <label for="floatingPassword">Mobile Number</label>
                                          <div *ngIf="submitted && UserForm.controls['mobileNo'].errors"
                                                class="invalid-feedback">
                                                <div 
                                                    *ngIf="UserForm.controls['mobileNo'].errors">Required Field</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                        <div class="col-lg-5 col-md-5 col-sm-12">
                                            <div class="pt-20 md-pt-0">
                                               <div (copy)="false" class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                aria-hidden="true"></i> </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-7 col-sm-12">
                                           <div class="form-floating">
                                                <input [ngClass]="{'is-invalid':submitted && UserForm.controls['Captcha'].errors}" type="text" class="form-control" id="Captcha" placeholder="Captcha" formControlName="Captcha">
                                                <label for="Captcha">Captcha</label>
                                                <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                      class="invalid-feedback">
                                                      <div
                                                          *ngIf="UserForm.controls['Captcha'].errors">Required Field</div>
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-20">
                                           <button [disabled]="loading" class="greenbtn2" (click)="submit()">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                Login
                                                <i class="demo-icon uil-arrow-right"></i>
                                            </button>		
                                        </div>
                                        <div class="mb-20 text-center">
                                            If you don’t have an account,<a [routerLink]="['/user-registration']"
                                                class="register-now"> Register Now! </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </form>


                </div>
            </div>
        </div>
    </div>


    <div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            * Kindly enter OTP as appearing on your Mobile.
                        </div>
                        <form [formGroup]="checkOtpForm">
                            <div class="row">



                                <div class="col-sm-12 form-group">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Otp" placeholder="Otp"
                                            [ngClass]="{ 'is-invalid': OtpSubmitted && checkOtpForm.controls['Otp'].errors }"
                                            id="Otp" />
                                        <div *ngIf="OtpSubmitted && checkOtpForm.controls['Otp'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="checkOtpForm.controls['Otp'].errors.required">Required Field
                                            </div>
                                            <!-- <div *ngIf="checkOtpForm.controls['Otp'].errors.pattern">MobileNo should be in 12 digits.</div> -->
                                        </div>
                                        <label for="Captcha">Enter OTP<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button [disabled]="submitStatus" (click)="verifyOtp()" class="greenbtn2">Submit
                                        OTP<i class="demo-icon uil-arrow-right"></i></button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button [disabled]="OtpButtonActive" greybtn (click)="OtpGenerate()"
                                        class="{{OtpButtonActive ? 'greybtn' :'greenbtn2' }}">{{OtpButtonActive ?
                                        this.Minute+":"+this.Sec:
                                        "Resend OTP"}}<i class="demo-icon uil-arrow-right"></i></button>
                                </div>
                            </div>


                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>


    <!-- modernizr js -->
    <script src="../../../assets/js/modernizr-2.8.3.min.js"></script>
    <!-- jquery latest version -->
    <script src="../../../assets/js/jquery.min.js"></script>
    <!-- Bootstrap v4.4.1 js -->
    <script src="../../../assets/js/bootstrap.min.js"></script>
    <!-- op nav js -->
    <script src="../../../assets/js/jquery.nav.js"></script>
    <!-- isotope.pkgd.min js -->
    <script src="../../../assets/js/isotope.pkgd.min.js"></script>
    <!-- owl.carousel js -->
    <script src="../../../assets/js/owl.carousel.min.js"></script>
    <!-- wow js -->
    <script src="../../../assets/js/wow.min.js"></script>
    <!-- Skill bar js -->
    <script src="../../../assets/js/skill.bars.jquery.js"></script>
    <!-- imagesloaded js -->
    <script src="../../../assets/js/imagesloaded.pkgd.min.js"></script>
    <!-- waypoints.min js -->
    <script src="../../../assets/js/waypoints.min.js"></script>
    <!-- counterup.min js -->
    <script src="../../../assets/js/jquery.counterup.min.js"></script>
    <!-- magnific popup js -->
    <script src="../../../assets/js/jquery.magnific-popup.min.js"></script>
    <!-- Nivo slider js -->
    <script src="../../../assets/inc/custom-slider/js/jquery.nivo.slider.js"></script>
    <!-- contact form js -->
    <script src="../../../assets/js/contact.form.js"></script>
    <!-- main js -->
    <script src="../../../assets/js/main.js"></script>
    <script type="text/javascript">
        $('.search-toggle').addClass('closed');

        $('.search-toggle .search-icon').click(function (e) {
            if ($('.search-toggle').hasClass('closed')) {
                $('.search-toggle').removeClass('closed').addClass('opened');
                $('.search-toggle, .search-container').addClass('opened');
                $('#search-terms').focus();
            } else {
                $('.search-toggle').removeClass('opened').addClass('closed');
                $('.search-toggle, .search-container').removeClass('opened');
            }
        });

    </script>